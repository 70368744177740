import DeletedProducts from 'Components/DeletedProducts'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { binProductTableHeading } from '../../Constants'
import { getProductBin, restoreProducts } from '../../Api'
import SweetAlert from 'react-bootstrap-sweetalert'

const ProductsBin = () => {
  const [products, setProducts] = useState([])
  const [showRestoreAlert, setShowRestoreAlert] = useState(false)
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false)
  const [restoreProductID, setRestoreProductID] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  })
  const { currentPage, totalSize } = paginationValue

  const navigate = useNavigate()

  const getProductBinData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getProductBin(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setProducts(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getProductBinData({ currentPage: 1, totalSize: 10 })
    // eslint-disable-next-line
  }, [])

  const handleViewProductClick = (productID) => {
    navigate('/dashboard/Products/view/' + productID)
  }

  const handleRestoreProductClick = (productID) => {
    setRestoreProductID(productID)
    setShowRestoreAlert(true)
  }

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getProductBinData({ page, totalSize: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const restoreProductAction = async() => {
    await restoreProducts(restoreProductID)
    const filteredProdutcs = products.filter(({ _id }) => _id !== restoreProductID)
    setProducts(filteredProdutcs)
    setRestoreProductID('')
    setShowRestoreAlert(false)
    setShowRestoreConfirm(true)
  }

  return (
    <div>
      <DeletedProducts
        tableData={products}
        tableHeading={binProductTableHeading}
        handleViewProduct={handleViewProductClick}
        handleRestoreProduct={handleRestoreProductClick}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore Product!"
          reverseButtons={true}
          onConfirm={restoreProductAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Product Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false)
            setShowRestoreConfirm(false)
          }}
        >
          Product has been Restored.
        </SweetAlert>
      ) : null}
    </div>
  )
}

export default ProductsBin
