import { CreateTeacherTableHeading } from '../../Constants/tableConstants'
import { Tabs, Tab } from 'react-bootstrap'
import TabData from './TabData'


const AllUser = () => {
  return (
    <div style={{ backgroundColor: 'white', padding: '1rem' }}>
      <Tabs defaultActiveKey="teacher" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="teacher" title="Teacher">
          <TabData columns={CreateTeacherTableHeading} role="TEACHER" />
        </Tab>
        <Tab eventKey="Co-ordinator" title="Coordinator">
          <TabData columns={CreateTeacherTableHeading} role="COORDINATOR" />
        </Tab>
      </Tabs>
      
    </div>
  )
}

export default AllUser
