const ecomBaseDomain = 'https://ecom-prod-be.theyogainstitute.org/v1'
import axios from 'axios'
// import { categoryApi } from 'Constants/apiRoutes'

export const uploadBannerImg = (payload) =>{
  return axios.post(`${ecomBaseDomain}/bannerimage`,payload)
}


export const getBannerImg = ({ page, limit }) =>{
  return axios.get(`${ecomBaseDomain}/bannerimage?page=${page}&limit=${limit}`)
}

export const editBannerImgByID = (payload, bannerID) =>{
  return axios.patch(`${ecomBaseDomain}/bannerimage/${bannerID}`,payload)
}

export const getBannerImgByID = (bannerID) =>{
  return axios.get(`${ecomBaseDomain}/bannerimage/${bannerID}`)
}