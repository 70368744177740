import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'
import ImageModal from 'Components/ImageModal'
import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../assets/scss/custom/components/_editor.scss'
import draftToHtml from 'draftjs-to-html'
import TagsInput from 'Components/TagsInput'
import AllImages from 'Views/Dashboard/Views/Images/Views/All images'
import AllImagesModal from 'Components/AllImagesModal'
// import { Editor,EditorState } from 'draft-js'
// import 'draft-js/dist/Draft.css'
const CreateBlog = ({
  pageHeading = 'Create Blogs',
  handelInput,
  title,
  metaDescription,
  tags,
  slug,
  category,
  content,
  setContent,
  handleSubmit,
  isDisabledBtn = false,
  createBtnText = 'Create Blog',
  coverImage,
  setCoverImage,
  setSelectedCategory,
  setTags,
  setMeta,
  metaTitle,
  setMetaTitle,
  keywords,
  setKeyword,
  tagsId,
  setTagsId,
  altTag,
}) => {
  const [currentSearch, setCurrentSearch] = useState([])
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showAllImagesModal, setShowAllImagesModal] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const rawContentState = convertToRaw(editorState.getCurrentContent())

  const markup = draftToHtml(
    rawContentState,
    {
      trigger: '#',
      separator: ' ',
    },
    true
  )

  setContent(markup)
  setMeta(`
  <title>${metaTitle}</title>
  <meta name="description" content="${metaDescription}" />
  <meta name="keywords" content="${keywords}"/>
  <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
  <link rel="canonical" href="https://theyogainstitute.org/${slug}/" />
  <meta property="og:locale" content="en_US" />
  <meta property="og:type" content="article" />
  <meta property="og:title" content="${metaTitle}" />
  <meta property="og:description" content="${metaDescription} " />
  <meta property="og:url" content="https://theyogainstitute.org/${slug}/" />
  <meta property="og:site_name" content="The Yoga Institute" />
  <meta property="article:publisher" content="https://www.facebook.com/theyogainstitutemumbai/" />
  <meta property="article:published_time" content="2022-07-04T04:58:25+00:00" />
  <meta property="article:modified_time" content="2022-07-04T05:08:04+00:00" />
  <meta property="og:image" content=${coverImage} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="628" />
  <meta name="twitter:label1" content="Est. reading time">
    <meta name="twitter:data1" content="10 minutes">
  `)

  const getSearchData = async(text) => {
    try {
      let data = {} //temporary-data
      //const { data } = await searchCategory(text)
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([])
    } catch (error) {
      setCurrentSearch([])
    }
  }
  const handleChange = (e) => {
    setSearch(e.target.value)
    setSelectedCategory({
      name: '',
      _id: '',
    })

    let searchBar = e.target.value
    getSearchData(searchBar)
  }

  useEffect(() => {
    search !== '' ? setShow(true) : setShow(false)
  }, [search])

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false)
    }, 1000)
  }

  const handleTagInfo = (item) => {
    setSearch(item.name)
    setSelectedCategory({
      name: item.name,
      _id: item._id,
    })
    setShow(false)
  }

  const selectTag = {
    marginTop: '10px',
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px',
    width: '100%',
    height: '240px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    zIndex: '9',
  }

  const selectSearchItem = {
    fontSize: '0.9rem',
    fontWeight: '500',
    lineHeight: '1.23',
    textAlign: 'left',
    color: '#505050',
    padding: '1rem 0',
    borderBottom: '1px solid #d3d3d3',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  }
  const handelShowModal = () => {
    setShowModal(true)
  }
  const handelAllImagesModal = () => {
    setShowAllImagesModal(true)
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Blog Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Blog Slug</Label>
                    <AvField
                      name="slug"
                      placeholder="Enter Slug"
                      value={slug}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Slug is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="slug"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Blog metaTitle</Label>
                    <AvField
                      name="metaTitle"
                      placeholder="Enter metaTitle"
                      value={metaTitle}
                      onChange={(e) => {
                        setMetaTitle(e.target.value)
                      }}
                      type="text"
                      errorMessage="metaTitle is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="metaTitle"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="description">Blog metaDescription</Label>
                    <AvField
                      name="metaDescription"
                      placeholder="Enter metaDescription"
                      value={metaDescription}
                      onChange={handelInput}
                      type="text"
                      errorMessage="metaDescription is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="metaDescription"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="description">Blog metaKeywords</Label>
                    <AvField
                      name="metaKeywords"
                      placeholder="Enter metaKeywords"
                      value={keywords}
                      onChange={(e) => {
                        setKeyword(e.target.value)
                      }}
                      type="text"
                      errorMessage="metaKeywords is required"
                      className="form-control"
                      // validate={{ required: { value: true } }}
                      id="metaKeywords"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Blog Tags</Label>
                    {/* <AvField
                      name='tags'
                      placeholder='Enter tags'
                      value={tags}
                      onChange={handelInput}
                      type='text'
                      errorMessage='Tags are required'
                      className='form-control'
                      validate={{ required: { value: false } }}
                      id='slug'
                    /> */}
                    <TagsInput
                      tags={tags}
                      setTags={setTags}
                      tagsId={tagsId}
                      setTagsId={setTagsId}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={coverImage}
                    setFile={setCoverImage}
                    dragText="Drop Cover Image here"
                    heading="Select Cover Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='alt-tag'>Alt Tag</Label>
                    <AvField
                      name="altTag"
                      placeholder="Enter alt tags"
                      value={altTag}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Alt Tags are required"
                      className="form-control"
                      // validate={{ required: { value: true } }}
                      id="alt-tag"
                    ></AvField>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Write Blog</Label>
                  <Button style={{ float:'right' , padding:'5px',marginTop:'-5px' }} 
                    color="primary" onClick={() => handelShowModal()}>
                  Add Image
                  </Button>
                  <Button style={{ float:'right' , padding:'5px',marginTop:'-5px', marginRight:'5px' }} 
                    color="primary" onClick={() => handelAllImagesModal()}>
                  All images
                  </Button>
                  
                  
                  <ImageModal 
                    showModal={showModal} 
                    setShowModal={setShowModal}               
                  />

                  <AllImagesModal
                    showAllImagesModal = { showAllImagesModal }
                    setShowAllImagesModal = { setShowAllImagesModal }
                  />
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClass"
                    wrapperClassName="wrapperClass"
                    editorClassName="editorClass"
                    onEditorStateChange={setEditorState}
                  />
                </Col>
              </Row>
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CreateBlog
