import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from '../Table'

const TagsCard = ({
  heading = '',
  openModel,
  modelBtnText = '',
  tableData = [],
  tableHeading = [],
  handleEditTag,
  handleDeleteTag,
  handleRestoreTag,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  allTags,
}) => {
  const navigate = useNavigate()
  const [rowData, setRowData] = useState([])

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'right' }} key={row._id}>
        {/* <i
          className='ri-file-edit-fill text-info h4 cursor-pointer mr-2'
          onClick={() => handleEditTag(row._id)}
        /> */}
        <i
          className='ri-delete-bin-2-fill text-danger h4 cursor-pointer'
          onClick={() => handleDeleteTag(row._id)}
        />
      </div>
    ) 
  } 

  const restoreFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color='success'
          className='waves-effect waves-light'
          style={{ width: '100%' }}
          onClick={() => handleRestoreTag(row._id)}
        >
          Restore
        </Button>
      </div>
    ) 
  } 

  useEffect(() => {
    if (allTags === true) {
      tableHeading.push({
        text: 'Actions',
        dataField: 'actions',
        isDummyField: true,
        headerStyle: { textAlign: 'right', width: '5%' },
        formatter: actionsFormatter,
      }) 
    } else {
      tableHeading.push({
        text: 'Restore Tags',
        dataField: 'restore',
        isDummyField: true,
        headerStyle: { textAlign: 'center', width: '15%' },
        formatter: restoreFormatter,
      }) 
    }
    return () => {
      if (allTags === true) {
        tableHeading.pop() 
      } else {
        tableHeading.pop() 
      }
    } 
    // eslint-disable-next-line
  }, []) 

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el) 
        o.sNo = i + 1 + page * 10 - 10 
        return o 
      }) 
      setRowData(result) 
    }
    // eslint-disable-next-line
  }, [tableData]) 

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{heading}</h3>
                  {allTags === true && (
                    <div
                      className='page-title-right'
                      style={{ display: 'flex', width: '30%', gap: '10px' }}
                    >
                      <Button
                        color='danger'
                        className='waves-effect waves-light'
                        style={{ width: '100%' }}
                        onClick={() => navigate('/dashboard/Tags/bin')}
                      >
                        Tags and Categories Bin
                      </Button>
                      <Button
                        color='primary'
                        className='waves-effect waves-light'
                        style={{ width: '100%' }}
                        onClick={openModel}
                      >
                        {modelBtnText}
                      </Button>
                    </div>
                  )}
                  {allTags === false && (
                    <div className='page-title-right'>
                      <Button
                        color='primary'
                        className='waves-effect waves-light'
                        style={{ width: '100%' }}
                        onClick={() => navigate('/dashboard/tag/all')}
                      >
                        All Tags and Categories
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  ) 
} 

export default TagsCard 
