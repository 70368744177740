import axios from 'axios'
import { ecomBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

const ecomBaseDomainn = `${ecomBaseDomain}/v1`


export const fetchAllOrders = ({ page, limit }) => {
  return axios.get(`${ecomBaseDomainn}/order/allorders/?page=${page}&limit=${limit}`)
}

export const fetchProductById = (productId) => {
  return axios.get(`${ecomBaseDomainn}/product/${productId}`)
}

export const fetchCartData = (cartId) => {
  return axios.get(`${ecomBaseDomainn}/cart/${cartId}`)
}

export const getOerderbyId = (orderID) => {
  return axios.get(`${ecomBaseDomainn}/order/${orderID}`)
}

export const updateOrderStatus = (orderId, productId, payload) => {
  return axios.put(`${ecomBaseDomainn}/order/status/${orderId}/${productId}`, payload)
}
