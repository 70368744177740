import { useEffect, useState } from 'react'
import Table from 'Components/Table'
import DatePicker from 'react-date-picker'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { allocateRoom } from 'Views/Dashboard/Views/AdCourses/Api'
import { CSVLink } from 'react-csv'

const AdCoursesCard = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  handleAction,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick,
  selectedLimit,
  selectUserData,
  handleSelectUsersListChange, contactNo, handleContactNoChange,
  courseList,
  relatedCourses,
  handleRelatedCoursesChange,
  batchList,
  relatedBatches, relatedTimings, timingList,
  handleRelatedBatchChange,
  handleRelatedBatchTiming,
  showModal,
  closeModal,
  roomOptions, selectedRoom, handleRomChange
}) => {
  const handelCutModal = () => {
    closeModal(false)
  }

  const checkInRoom = async () => {
    let payload = { room: null, status: 3 }
    allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
      closeModal(true)
    }).catch(err => {
      console.log(err);
    })
    // closeModal(true)
  }

  const checkOutRoom = async () => {
    let payload = { room: null, status: 5 }
    allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
      closeModal(true)
    }).catch(err => {
      console.log(err);
    })
    // closeModal(true)
  }

  const cancelRoom = async () => {
    let payload = { room: null, status: 4 }
    allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
      closeModal(true)
    }).catch(err => {
      console.log(err);
    })
    // closeModal(true)
  }

  const confirmRoom = async () => {
    if (selectedRoom?.value) {
      let payload = { room: selectedRoom?.value, status: 2 }
      allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
        closeModal(true)
      }).catch(err => {
        console.log(err);
      })
      // closeModal(true)
    }
  }

  const [rowData, setRowData] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [isCancel, setIsCancel] = useState(false)
  // RoomBookingStatus {
  //     Pending = 0,
  //     AllocationInProgress = 1,
  //     Allocated = 2,
  //     CheckIn = 3,
  //     Cancelled = 4,
  //     CheckedOut = 5
  // }
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center', gap: '4px', display: 'flex', flexDirection: 'column' }} key={row?._id}>
        {row?.roomBooking?.status == 1 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Allocate Room
        </Button>}
        {row?.roomBooking?.status == 2 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(true); setSelectedUser(row); handleAction(row) }}
        >
          Cancel
        </Button>}
        {row?.roomBooking?.status == 2 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Check In
        </Button>}
        {row?.roomBooking?.status == 3 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Check Out
        </Button>}

      </div>
    )
  }
  const getTime = (date) => {
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();
    return `${dateString} ${timeString}`;
  }

  const getPaymentMode = (value) => {
    // return value == '1' ? 'On-Campus' : 'Online'
    switch (value) {
      case 1:
        return 'On-Campus';
      case 2:
        return 'Online';
      case 3:
        return 'Residential';
      case 4:
        return 'Non-Residential';
      // You can have as many case statements as needed
      default:
        return '';
      // Code to execute if no case matches
    }
  }
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const getPaymentStatus = (value) => {
    return value == '1' ? 'Pending' : 'Success'
  }

  const getRoomStatus = (value) => {
    console.log(value == 1);

    if (value == 1) {
      return 'In Progress'
    }
    else if (value == 2) {
      return 'Allocated'
    }
    else if (value == 3) {
      return 'Check-In'
    }
    else if (value == 4) {
      return 'Cancelled'
    }
    else if (value == 5) {
      return 'Check-Out'
    }
    else {
      return '-'
    }
    // switch (value) {
    //   case value?.roomBooking?.status == 1:
    //     return 'In Progress'
    //   case value?.roomBooking?.status == 2:
    //     return 'Allocated'
    //   case value?.roomBooking?.status == 3:
    //     return 'Check-In'
    //   case value?.roomBooking?.status == 4:
    //     return 'Cancelled'
    //   case value?.roomBooking?.status == 5:
    //     return 'Check-Out'
    //   default:
    //     return ''
    // }
  }
  const handleAllExportToCSV = () => {
    const csvDataAll = rowData.map((item) => ({
      Name: item?.customer?.name,
      Contact: `+${item?.customer?.dialCode} ${item?.customer?.contactNo}`,
      Email: item?.customer?.email,
      City: item?.customer?.city,
      Course: item?.courseName,
      'Course Date': item?.courseDate,
      'Course Timing': item?.courseTimings,
      'Mode': getPaymentMode(item.courseSubscriptions?.mode),
      'Amount': item?.amount,
      'Payment': item.status,
      'Created At': item?.courseSubscriptions?.createdAt,
      'Room': getRoomStatus(item.roomBooking?.status)
    })
    )

    return csvDataAll
  }
  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        const fDate = new Date(el.createdAt)
        // console.log(fDate)
        var o = {
          ...el, createdAt: getTime(fDate), status: getPaymentStatus(el.status), 'courseSubscriptions.mode': getPaymentMode(el.courseSubscriptions?.mode), 'roomBooking.status': getRoomStatus(el.roomBooking?.status),
          'customer.contactNo': `+${el?.customer?.dialCode} ${el?.customer?.contactNo}`
        }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  useEffect(() => {
    tableHeading.pop()//remove room details form, using for data handling
    tableHeading.push({
      text: 'Actions',
      // dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewContentFormatter,
    })
    return () => {
      // tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row>
      <Modal
        isOpen={showModal}
        size={'lg'}
        className='ImageUpoader-modal'
      >
        <ModalBody>
          <div className="closeButton">
            <button className='closemodal' onClick={handelCutModal}>X</button>
            {/* <AllImages showAllImagesModal={ showAllImagesModal } /> */}
            <h4>Customer Info</h4>
          </div>
          {/* <div>Available rooms</div> */}
          <Row>
            <Col lg={6}><b>Name: </b> {selectedUser?.customer?.name}</Col>
            <Col lg={6}><b>Contact No.: </b> +{selectedUser?.customer?.dialCode} {selectedUser?.customer?.contactNo}</Col>
          </Row>
          <Row>
            <Col lg={6}><b>Course: </b> {selectedUser?.courseName}</Col>
            <Col lg={6}><b>Course Date: </b> {selectedUser?.courseDate}</Col>
          </Row>
          <Row>
            <Col lg={6}><b>Amount: </b> {selectedUser?.amount}</Col>
            <Col lg={6}><b>Sharing type: </b> {selectedUser?.bookingDetails?.sharingType}</Col>
          </Row>
          {/* <Row>
            <Col lg={6}><b>Check-In: </b> {selectedUser?.roomBooking?.checkInDate.slice(0, 10)}</Col>
            <Col lg={6}><b>Check-Out: </b> {selectedUser?.roomBooking?.checkOutDate.slice(0, 10)}</Col>
          </Row>  */}
          <br />
          {selectedUser?.roomBooking?.status == 1 &&
            <Row>
              <Col lg={6}>
                <Select
                  isMulti={false}
                  value={selectedRoom}
                  options={roomOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Search Room"
                  onChange={handleRomChange}
                />
              </Col>
              <Col lg='auto'>
                <button className='export-btn' onClick={confirmRoom}>Allocate Room</button></Col>
            </Row>}

          {/* Check In Action */}
          {(selectedUser?.roomBooking?.status == 2 && !isCancel) && <button className='export-btn' onClick={checkInRoom}>Confirm Check In</button>}

          {/* Cancel room */}
          {(selectedUser?.roomBooking?.status == 2 && isCancel) && <button className='export-btn' onClick={cancelRoom}>Cancel Room</button>}

          {/* Checkout room */}
          {selectedUser?.roomBooking?.status == 3 && <button className='export-btn' onClick={checkOutRoom}>Confirm Check Out</button>}
        </ModalBody>
      </Modal>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>Campaign Enrolled Users</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <div>
                  <DatePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    clearIcon={null}
                  /><DatePicker
                    onChange={handleEndDateChange}
                    value={endDate}
                    clearIcon={null}
                  />
                  <br />
                  {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}</div>
              </Col>
              <Col xs="auto">
                {/* <Label htmlFor="orderNumber">Banner Image Order Number</Label> */}
                <Input
                  name="orderNumber"
                  placeholder="Enter Contact No."
                  type="number"
                  className="form-control"
                  value={contactNo}
                  onChange={handleContactNoChange}
                  // onChange={(e) => {
                  //   setFormData({ ...formData, orderNumber: e.target.value })
                  // }}
                  // validate={{ required: { value: true } }}
                  errorMessage="order num is required"
                  id="orderNumber"
                />
              </Col>

              <Col xs={2} className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedCourses}
                    options={courseList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Courses"
                    onChange={handleRelatedCoursesChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={2} className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedBatches}
                    options={batchList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Batch"
                    onChange={handleRelatedBatchChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="auto" className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedTimings}
                    options={timingList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Timing"
                    onChange={handleRelatedBatchTiming}
                  />
                </FormGroup>
              </Col>
              <Col xs={3} className='users-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={selectedLimit}
                    options={selectUserData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select No. of Users"
                    onChange={handleSelectUsersListChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => handleConfirmClick()} disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}>Apply Filters</button>
                </FormGroup>
              </Col>
              {rowData.length ? <Col xs="auto" className='users-col'>
                <FormGroup>
                  <CSVLink
                    data={handleAllExportToCSV()}
                    filename={'campaign_enrolled_users.csv'}
                    className="export-csv-btn"
                  >
                    <button className='export-btn'>Export to CSV</button>
                  </CSVLink>
                </FormGroup>
              </Col> : <></>}

            </Row>
            {/* <br />
            <Row>
            </Row> */}
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  {/* <h3 className='m-0'>Published Content</h3> */}
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={10}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdCoursesCard
