import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'
import './style.scss'


const TotalHealthForm = ({
  pageHeading = 'Edit Yoga Total Health',
  title,
  description,
  TotalHealthImg,
  setTotalHealthImg,
  isDataUpdated,
  handleInput,
  handleSubmit,
  BtnText = 'Edit Yoga Total Health'
}) => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal">
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handleInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FileUploader
                file={TotalHealthImg}
                setFile={setTotalHealthImg}
                dragText="Drop Image here"
                heading="Select Image"
                accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
              />
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="title">Description</Label>
                    <AvField
                      name="description"
                      placeholder="Enter description"
                      value={description}
                      onChange={handleInput}
                      type="textarea"
                      errorMessage="Description is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="description"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color="primary"
                className="disabled-btn"
                type="submit"
                disabled={!isDataUpdated ? true : false}
                onClick={handleSubmit}
              >
                {BtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TotalHealthForm
