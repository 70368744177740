import { useEffect, useState } from 'react'
import Table from 'Components/Table'
import { deleteuserById, getAllUsersByRole, enableuserById } from '../../User.Api'
import SweetAlert from 'react-bootstrap-sweetalert'
import EditUser from '../EditUser'

const TabData = ({ columns, role }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [updatedColumns, setUpdatedColumns] = useState(columns)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [createdPaginationValue, setCreatedPaginationValue] = useState({
    createdCurrentPage: 1,
  })
  const { createdCurrentPage, createdTotalSize } = createdPaginationValue

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleUserEdit = (row) => {
    setSelectedRow(row)
    setIsOpen(true)
  }

  const fetchData = async(queryParams) => {
    try{
      setIsLoading(true)
      const res = await getAllUsersByRole({ page: queryParams.page, limit: queryParams.limit, role:role })
      if (res.status==200) {
        console.log(res,'working')
        setPaginationValue({
          currentPage: queryParams.page,
          totalSize: res.data.count,
        })
        setRows(res.data.data)
      }
      setIsLoading(false)
    }catch(err){
      console.log(err)
    }
  }
  const handleUserDelete = async() => {
    if(!selectedRow.isDisabled){
      await deleteuserById(selectedRow.userId)
      setShowDeleteAlert(false)
    }
    else{
      await enableuserById(selectedRow.userId)
    }
    // await fetchData()
  }
  const handleOnClick = (row, setter) => {
    setter(true)
    setSelectedRow(row)
  }

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center', display: 'flex' }} key={row._id}>
        <i
          color="primary"
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          style={{ width: '100%', marginLeft: '1rem', padding: '0.5rem 1rem' }}
          onClick={() => handleUserEdit(row)}
        >
        </i>
        <i
          color="danger"
          className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
          style={{ width: '100%', marginLeft: '1rem', padding: '0.5rem 1rem' }}
          onClick={() => handleOnClick(row, setShowDeleteAlert)}
        >
        </i>
      </div>
    )
  }
  console.log(selectedRow,'SR')

  useEffect(() => {
    setUpdatedColumns([...columns, {
      text: 'Action',
      dataField: 'delete',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: actionsFormatter,
    }])
    fetchData({ page: 1, limit: 10 })
  }, [])

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    fetchData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }
  

  return (
    <>
      <Table
        rows={rows}
        columns={updatedColumns}
        onTableChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />
      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={handleUserDelete}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}
      { isOpen && <EditUser isOpen={isOpen} setIsOpen={setIsOpen} initialValues={selectedRow} fetchData={fetchData} />}
    </>
  )
}

export default TabData
