import Login from '../Components/Login'

export const AuthRoutes = [
  {
    Component: Login,
    path: '/login',
    exact: true,
    id: 'authLogin',
    routePath: '/auth/login',
  },
]
