export const courseList = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'one-month-ttc',
    label: '200-Hour Yoga Teacher Training Course - 1 Month Yoga TTC Online & On Campus - English - Batch 1',
  },
  {
    value: '200-hrs-part-time-ttc-on-campus-english',
    label: '200 Hours Yoga Teacher Training Course - 2 Months Yoga TTC Online and On Campus - English - Batch 2',
  },
  {
    value: '200-hrs-part-time-ttc-online-english',
    label: '200-Hour Yoga Teacher Training Online Course - 2 Months TTC Online - English - Batch 3'
  },
  {
    value: '200-hrs-part-time-ttc-online',
    label: '200 Hours Hindi Teacher Training Course - 2 Months TTC Online - Hindi - Batch 4'
  },
  {
    value: 'weekend-teacher-training-course',
    label: '200 Hours Yoga TTC  - 3 Months Weekend Yoga Teacher Training Course Online - English - Batch 5'
  },
  {
    value: '200-hrs-part-time-ttc-online-english-batch-6',
    label: '200 Hours Yoga TTC - 2 Months Online Yoga Teacher Training Course - English - Batch 6',
  },


  {
    value: '21-days-better-living-course',
    label: '21-Days Better Living Yoga Course - Morning Online and On Campus – English - Batch 1',
  },
  {
    value: '21-days-better-living-course-batch-2',
    label: '21-Days Better Living Yoga Course - Evening - Online & On Campus – English - Batch 2',
  },
  {
    value: '21-days-better-living-course-batch-3',
    label: '21-Days Better Living Yoga Course - Evening - Online & On Campus – Hindi - Batch 3',
  },


  {
    value: '500-hrs-online-yoga-teacher-training-course-intermediate-level',
    label: '500-Hour Yoga Teacher Training Online Course - 3 Months Yoga TTC - English',
  },


  {
    value: '7-days-camp',
    label: '7 Days Health Camp - On Campus - Yoga Camp - Hindi',
  },
  { value: '7-days-camp-english', label: '7 Days Health Camp - On Campus - Yoga Camp - English' },


  {
    value: '3-months-advanced-teacher-training-course',
    label: '900 Hours Yoga TTC - 3 Months Teacher Training Course On Campus - English',
  },
  {
    value: 'one-year-ttc',
    label: '900 Hours Yoga TTC - 1 Year Advanced Yoga Teacher Training Course Online & On-Campus - Hindi',
  },
  {
    value: 'two-year-ttc',
    label: '900 Hours YTTC - 2 Years Advanced Yoga Teacher Training Certificate Course Online & On Campus - English',
  },
  {
    value: '900-hours-advanced-teacher-training-course',
    label: '900 Hours Yoga TTC - 4 Months Online Yoga Teacher Training Course - English',
  },
  {
    value: '900-hours-advanced-teacher-training-course',
    label: '900 Hours Yoga TTC - 4 Months Online Yoga Teacher Training Course - English',
  },
  {
    value: 'seven-month-ttc',
    label: '900 Hours Yoga TTC - 7 Months Yoga Teacher Training Course Online & On Campus - English',
  },

  {
    value: 'cardiac-hypertension-workshop', label: 'Cardiac & Hypertension Yoga Workshop - On Campus',
  },

  {
    value: 'diabetes-camp', label: 'Diabetes Yoga Camp - On Campus',
  },
  {
    value: 'life-management-camp', label: 'Life Management Yoga Camp - On Campus',
  },
  {
    value: 'pranayama-workshop', label: 'Pranayama Workshop - On Campus & Online',
  },
  {
    value: 'pregnancy-camp-for-ante-post-natal', label: 'Pregnancy Yoga Camp For Ante & Post Natal - On Campus',
  },
  {
    value: 'respiratory-workshop', label: 'Respiratory Yoga Workshop - On Campus',
  },
  {
    value: 'senior-citizen-workshop', label: 'Senior Citizen Yoga Workshop - On Campus',
  },
  {
    value: 'stress-management-camp', label: 'Stress Management Yoga Camp - On Campus',
  },
  {
    value: 'weight-management-workshop', label: 'Weight Management Yoga Workshop',
  },
  {
    value: 'womens-camp', label: 'Women’s Yoga Camp',
  },
  {
    value: 'back-joint-disorder-workshop', label: 'Back & Joint Disorder Yoga Workshop',
  },
  {
    value: 'advanced-regular-yoga-classes', label: 'Advanced Yoga Asana Regular Class - Online (Only for TYI Teachers)',
  },
  {
    value: 'healing-movement-and-rhythm', label: 'Healing Yoga Movement & Rhythm - Online',
  },
  {
    value: 'asana-regular-classes-online', label: 'Yoga Asana Regular Classes - (Men & Women) - Online Yoga Classes',
  },
  {
    value: 'asana-regular-classes-on-campus', label: 'Yoga Classes for Men (Regular Asana) - On Campus',
  },
  {
    value: 'asana-regular-classes-on-campus-women', label: 'Yoga Classes for Women (Regular Asana) - On Campus',
  },
  {
    value: 'childrens-regular-classes', label: 'Children\'s Yoga Classes (Regular) - On Campus',
  },
  {
    value: 'childrens-weekend-classes-on-campus', label: 'Children\'s Weekend Yoga Class - On Campus',
  },
  {
    value: 'meditation-foundation-course-online', label: 'Online Meditation Course  (Foundation Course)',
  },
  {
    value: 'regular-meditation-classes-online', label: 'Regular Online Meditation Classes',
  },
  {
    value: 'satsang', label: 'Satsang',
  },
  {
    value: 'weekend-classes', label: 'Weekend Yoga Asana Classes - (Men & Women) - On Campus',
  },
  {
    value: 'yog-prayas-online', label: 'Yog Prayas - Online',
  },
  {
    value: 'samattvam', label: 'Samattvam (Health Checkup) - On Campus',
  },
  {
    value: 'couples-classes', label: 'Couples’ Yoga Classes  - Online',
  },
  {
    value: 'weekend-classes-online', label: 'Weekend Yoga Asana Classes - (Men & Women) - Online',
  },
  {
    value: 'regular-pregnacy-classes', label: 'Regular Pregnancy Yoga Classes - Online & On Campus',
  },

  {
    value: 'ma-yoga-shastra', label: 'MA Yoga Shastra',
  },

  {
    value: 'nutri-diet', label: 'Nutri Diet Clinic',
  },

  {
    value: 'certificate-course-on-advanced-pranayama-techniques', label: 'Certificate Course on Advanced Pranayama Techniques ( Pranayama Online Course)',
  },

  {
    value: 'certificate-program-on-yoga-for-cancer', label: 'Certificate Program on Yoga for Cancer - Online & On Campus',
  },

  {
    value: 'certification-program-on-yoga-for-lung-cancer-online', label: 'Certificate Program on Yoga for Lung Cancer - Online',
  },
  {
    value: 'certificate-yoga-therapy-course-online', label: 'Certificate Yoga Therapy Course - Online & On Campus',
  },
  {
    value: 'restorative-yoga-for-cancer-survivors', label: 'Restorative Yoga for Cancer Survivors - Online Program',
  },
  //   {
  //   value: 'advanced-regular-yoga-classes',
  //   label: 'Advance Asana Regular Class - Online (Only for TYI Teachers) ',
  // },
  // { value: 'healing-movement-and-rhythm', label: 'Healing Yoga Movement & Rhythm - Online & On Campus' },
  // {
  //   value: 'asana-regular-classes-online',
  //   label: 'Asana Regular Classes - (Men & Women) - Online',
  // },
  // {
  //   value: 'asana-regular-classes-on-campus',
  //   label: 'Asana Regular Classes for Men - On Campus  ',
  // },
  // {
  //   value: 'asana-regular-classes-on-campus-women',
  //   label: 'Asana Regular Classes for Women - On Campus',
  // },
  // {
  //   value: 'back-joint-disorder-workshop',
  //   label: 'Back & Joint Disorder Workshop',
  // },
  // {
  //   value: 'cardiac-hypertension-workshop',
  //   label: 'Cardiac & Hypertension Workshop',
  // },
  // {
  //   value: 'certificate-course-on-advanced-pranayama-techniques',
  //   label: 'Certificate Course on Advanced Pranayama Techniques - Online',
  // },
  
  // {
  //   value: 'certificate-program-on-yoga-for-cancer',
  //   label: 'Certificate Program on Yoga for Cancer - Online & On Campus',
  // },
  // {
  //   value: 'certification-program-on-yoga-for-lung-cancer-online',
  //   label: 'Certificate Program on Yoga for Lung Cancer - Online',
  // },
  // {
  //   value: 'certificate-yoga-therapy-course-online',
  //   label: 'Certificate Yoga Therapy Course - Online & On Campus',
  // },
  // {
  //   value: 'childrens-regular-classes',
  //   label: 'Children\'s Regular Class - On Campus',
  // },
  // {
  //   value: 'childrens-weekend-classes-on-campus',
  //   label: 'Children\'s Weekend Class - On Campus',
  // },
  // { value: 'couples-classes', label: 'Couples" Classes  - Online' },
  // { value: 'diabetes-camp', label: 'Diabetes Camp ' },
  // {
  //   value: 'meditation-foundation-course-online',
  //   label: 'Meditation Foundation Course - Online ',
  // },
  // { value: 'nutri-diet', label: 'Nutri Diet Clinic' },
  // { value: 'pranayama-workshop', label: 'Pranayama Workshop' },
  // {
  //   value: 'pregnancy-camp-for-ante-post-natal',
  //   label: 'Pregnancy Camp For Ante & Post Natal',
  // },
  // {
  //   value: 'regular-meditation-classes-online',
  //   label: 'Regular Meditation Classes - Online ',
  // },
  // { value: 'samattvam', label: 'Samattvam (Health Checkup) - On Campus' },
  // { value: 'satsang', label: 'Satsang ' },
  // { value: 'senior-citizen-workshop', label: 'Senior Citizen Workshop' },
  // {
  //   value: 'stress-management-camp',
  //   label: 'Stress Management Camp - Online and On Campus',
  // },
  // {
  //   value: 'weekend-classes',
  //   label: 'Weekend Asana Classes - (Men & Women) - On Campus',
  // },
  // {
  //   value: 'weekend-classes-online',
  //   label: 'Weekend Asana Classes - (Men & Women) - Online',
  // },
  // { value: 'weight-management-workshop', label: 'Weight Management Workshop' },
  // { value: 'womens-camp', label: 'Women`s Camp' },
  // { value: 'yog-prayas-online', label: 'Yog Prayas - Online' },
  // { value: 'corporate-workshops', label: 'Corporate Workshops' },
  // { value: 'kids-camp', label: 'Kids Summer Camp' },
  // { value: 'IBY-course', label: 'IBY Course' },
  // { value: 'life-management-camp', label: 'Life Management Camp' },
  // { value: 'respiratory-workshop', label: 'Respiratory Workshop' },
  // { value: 'home-tuitions', label: 'Home Tuitions' },
  // { value: 'restorative-yoga-for-cancer-survivors', label: 'Restorative Yoga for Cancer Survivors' },
  // { value: 'regular-pregnacy-classes', label: 'Regular Pregnancy Yoga Classes - Online & On Campus' }
]

export const MetaHeading = [
  {
    text: 'S.No',
    dataField: 'sNo',
  },
  {
    text: 'Course',
    dataField: 'pagePath',
  },
]

export const PageMetaHeading = [
  {
    text: 'S.No',
    dataField: 'sNo',
  },
  {
    text: 'Page',
    dataField: 'pagePath',
  },
]

export const pageList = [
  { value: '', label: 'home' },
  { value: 'social-initiatives', label: 'Social-initiatives' },
  { value: 'know-us-better', label: 'Know-us-better' },
  { value: 'our-legacy', label: 'Our-legacy' },
  { value: 'blessings', label: 'Blessings' },
  { value: 'hostel', label: 'Hostel' },
  { value: 'facilities', label: 'Facilities' },
  { value: 'conference-room', label: 'Conference-room' },
  { value: 'yoga-library', label: 'Yoga-library' },
  { value: 'book-store', label: 'Book-store' },
  { value: 'yoga-halls-rooftop-space', label: 'Yoga-halls-rooftop-space' },
  { value: 'yoga-halls', label: 'Yoga-halls' },
  { value: 'rooftop-space', label: 'Rooftop-space' },
  { value: 'dining-hall', label: 'Dining-hall' },
  { value: 'nature-trail', label: 'Nature-trail' },
  { value: 'gazebo', label: 'Gazebo' },
  { value: 'museum-of-classical-yoga', label: 'Museum-of-classical-yoga' },
  { value: 'image-gallery', label: 'Image-gallery' },
  { value: 'video-gallery', label: 'Video-gallery' },
  { value: 'yoga-and-total-health', label: 'Yoga-and-total-health' },
  { value: 'yogasattva', label: 'Yogasattva' },
  { value: 'blog', label: 'Blog' },
  { value: 'terms-and-conditions', label: 'Terms-and-conditions' },
  { value: 'privacy-policy', label: 'Privacy-policy' },
  { value: 'affiliations', label: 'Affiliations' },
  { value: 'wellness', label: 'Wellness' },
  { value: 'testimonials', label: 'Testimonials' },
  { value: 'Rules-and-Regulations', label: 'Rules-and-regulations' },
  { value: 'our-facts', label: 'Our-facts' },
  { value: 'alumni-gallery', label: 'Alumni-gallery' },
  { value: 'volunteer', label: 'Volunteer' },
  { value: 'values', label: 'Values' },
  { value: 'kailaspati-tree', label: 'Kailaspati-tree' },
  // { value: 'one-month-ttc', label: 'One-month-ttc' },
  // {
  //   value: '200-hrs-part-time-ttc-on-campus-english',
  //   label: '200-hrs-part-time-ttc-on-campus-english',
  // },
  // {
  //   value: '200-hrs-parttime-ttc-online-english',
  //   label: '200-hrs-parttime-ttc-online-english',
  // },
  // {
  //   value: '200-hrs-part-time-ttc-online-english-batch-5',
  //   label: '200-hrs-part-time-ttc-online-english-batch-5',
  // },
  // {
  //   value: '200-hrs-part-time-ttc-online',
  //   label: '200-hrs-part-time-ttc-online',
  // },
  // {
  //   value: 'weekend-teacher-training-course',
  //   label: 'Weekend-teacher-training-course',
  // },
  // {
  //   value: '500-hrs-online-yoga-teacher-training-course-intermediate-level',
  //   label: '500-hrs-online-yoga-teacher-training-course-intermediate-level',
  // },
  // {
  //   value: '3-months-advanced-teacher-training-course',
  //   label: '3-months-advanced-teacher-training-course',
  // },
  // {
  //   value: '900-hours-advanced-teacher-training-course',
  //   label: '900-hours-advanced-teacher-training-course',
  // },
  // { value: 'seven-month-ttc', label: 'Seven-month-ttc' },
  // { value: 'one-year-ttc', label: 'One-year-ttc' },
  // { value: 'two-year-ttc', label: 'Two-year-ttc' },
  // {
  //   value: 'asana-regular-classes-online',
  //   label: 'Asana-regular-classes-online',
  // },
  // {
  //   value: 'asana-regular-classes-on-campus',
  //   label: 'Asana-regular-classes-on-campus',
  // },
  // {
  //   value: 'asana-regular-classes-on-campus-women',
  //   label: 'Asana-regular-classes-on-campus-women',
  // },
  // {
  //   value: 'advanced-regular-yoga-classes',
  //   label: 'Advanced-regular-yoga-classes',
  // },
  // { value: 'weekend-classes', label: 'Weekend-classes' },
  // { value: 'weekend-classes-online', label: 'Weekend-classes-online' },
  // { value: 'childrens-regular-classes', label: 'Childrens-regular-classes' },
  // {
  //   value: 'childrens-weekend-classes-on-campus',
  //   label: 'Childrens-weekend-classes-on-campus',
  // },
  // { value: 'yoga-by-the-bay', label: 'Yoga-by-the-bay' },
  // { value: 'full-moon-meditation', label: 'Full-moon-meditation' },
  // { value: 'free-yoga-camps', label: 'Free-yoga-camps' },
  // { value: 'meditation-camp', label: 'Meditation-camp' },
  { value: 'counselling-services', label: 'Counselling-services' },
  { value: 'parisamvad', label: 'Parisamvad' },
  { value: 'satsang', label: 'Satsang' },
  { value: 'book-club', label: 'Book-club' },
  { value: 'good-food-fest', label: 'Good-food-fest' },
  { value: 'samattvam', label: 'Samattvam' },
  { value: 'shop', label: 'Shop' },
  { value: 'library', label: 'Library' },
  // { value: '7-days-camp-english', label: '7-days-camp-english' },
  // {
  //   value: 'certification-course-on-yoga-sutra-online',
  //   label: 'Certification-course-on-yoga-sutra-online',
  // },
  // {
  //   value: '21-days-better-living-course',
  //   label: '21-days-better-living-course',
  // },
  // {
  //   value: '21-days-better-living-course-batch-4',
  //   label: '21-days-better-living-course-batch-4',
  // },
  // { value: 'corporate-workshops', label: 'Corporate-workshops' },
  // { value: 'home-tuitions', label: 'Home-tuitions' },
  // { value: 'couples-classes', label: 'Couples-classes' },
  // { value: '7-days-camp', label: '7-days-camp' },
  // {
  //   value: '21-days-better-living-course-batch-2',
  //   label: '21-days-better-living-course-batch-2',
  // },
  // {
  //   value: '21-days-better-living-course-batch-3',
  //   label: '21-days-better-living-course-batch-3',
  // },
  // {
  //   value: 'certificate-yoga-therapy-course-online',
  //   label: 'Certificate-yoga-therapy-course-online',
  // },
  {
    value: 'certification-program-on-yoga-for-lung-cancer-online',
    label: 'Certification-program-on-yoga-for-lung-cancer-online',
  },
  // {
  //   value: 'certificate-course-on-advanced-pranayama-techniques',
  //   label: 'Certificate-course-on-advanced-pranayama-techniques',
  // },
  // { value: 'stress-management-camp', label: 'Stress-management-camp' },
  // { value: 'weight-management-workshop', label: 'Weight-management-workshop' },
  // {
  //   value: 'pregnancy-camp-for-ante-post-natal',
  //   label: 'Pregnancy-camp-for-ante-post-natal',
  // },
  // {
  //   value: 'cardiac-hypertension-workshop',
  //   label: 'Cardiac-hypertension-workshop',
  // },
  // {
  //   value: 'back-joint-disorder-workshop',
  //   label: 'Back-joint-disorder-workshop',
  // },
  // { value: 'pranayama-workshop', label: 'Pranayama-workshop' },
  // { value: 'womens-camp', label: 'Womens-camp' },
  // { value: 'respiratory-workshop', label: 'Respiratory-workshop' },
  // { value: 'life-management-camp', label: 'Life-management-camp' },
  // { value: 'senior-citizen-workshop', label: 'Senior-citizen-workshop' },
  // { value: 'diet-workshop', label: 'Diet-workshop' },
  // {
  //   value: 'meditation-foundation-course-online',
  //   label: 'Meditation-foundation-course-online',
  // },
  // { value: 'yog-prayas-online', label: 'Yog-prayas-online' },
  // { value: 'anand-sadhna', label: 'Anand-sadhna' },
  // {
  //   value: 'regular-meditation-classes-online',
  //   label: 'Regular-meditation-classes-online',
  // },
  { value: 'media', label: 'Media' },
  { value: 'blogs', label: 'Blogs' },
  { value: 'our-branches', label: 'Our-branches' },
  { value: 'matunga', label: 'Matunga' },
  // { value: 'nutri-diet', label: 'Nutri-diet' },
]
