import axios from 'axios'

import baseDomain, {
  pageApi,
  // searchContentAPI
} from '../../../../Constants/apiRoutes'

const subDomain = 'https://ecom-prod-be.theyogainstitute.org/v1'

export const creatPageReq = (data) => {
  return axios.post(`${subDomain}${pageApi}`, data)
}

export const getPublishedPageReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${pageApi}/publishedpage?page=${page}&limit=${limit}`)
}

export const getUnpublishedPageReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${pageApi}/unpublishedpage?page=${page}&limit=${limit}`)
}

export const UnpublishPage = (pageID) => {
  return axios.put(`${subDomain}${pageApi}/unpublish/${pageID}`)
}

export const publishPage = (pageID) => {
  return axios.put(`${subDomain}${pageApi}/publish/${pageID}`)
}

export const getPageReq = (pageID) => {
  return axios.get(`${subDomain}${pageApi}/${pageID}`)
}

export const editPageReq = (data, pageID) => {
  return axios.patch(`${subDomain}${pageApi}/update/${pageID}`, data)
}