import axios from 'axios'
import { cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'
const cmsBaseDomainn = `${cmsBaseDomain}v1`

export const getAllImages = () => {
  return axios.get(`${cmsBaseDomainn}/image`)
}

export const uploadImages = (data) => {
  return axios.post(`${cmsBaseDomainn}/image`,data)
}

export const deleteImages = (imageId) => {
  return axios.delete(`${cmsBaseDomainn}/image/delete/${imageId}`)
}


