import AllBannerImg from '../View/AllBanner'
import CreateBannerImg from '../View/CreateBanner'
import EditBannerImg from '../View/EditBanner'

const BannerRoutes = [
  {
    Component: AllBannerImg,
    path: '/all',
    exact: true,
    id: 'dashboardBannerAll',
    routePath: '/dashboard/banner/all',
    name: 'All Banner Image',
  },
  {
    Component: CreateBannerImg,
    path: '/create',
    exact: true,
    id: 'dashboardBannerCreate',
    routePath: '/dashboard/banner/create',
    name: 'Create Banner Image',
  },
  {
    Component: EditBannerImg,
    path: '/edit/:bannerID',
    exact: true,
    id: 'dashboardBannerEdit',
    routePath: '/dashboard/banner/edit/:bannerID',
    // name: 'Create Banner Image',
  },
]

export default BannerRoutes