import {
  Routes,
  Route,
} from 'react-router-dom'

import { MainRoutes } from 'Constants/routes'

import DecisionComponent from 'Components/DecisionComponent'

import './theme.scss'
import './style.scss'


const App = () => {
  return (
    <Routes>
      {MainRoutes.map(({ Component, path }) => (
        <Route element={<Component />} path={path} key={path} />
      ))}
      <Route element={<DecisionComponent />} path="*" />
      {/* <Route path="*" element={<Navigate to="/auth" replace />} /> */}
    </Routes>
  )
}

export default App
