import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Progress, Button } from 'reactstrap'

const UploadStatusBar = ({
  currentStep,
  thumbnailUploadPercentage,
  contentUploadPercentage,
  reset,
  showSelectAudio = true,
  showThumbnailUpload = true,
  processingWord = 'Creating',
  processedWord = 'Created',

}) => {
  const naviagte = useNavigate()
  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <h3>
              {currentStep === 4
                ? `Product ${processedWord}`
                : `Creating ${processingWord}`}
            </h3>
            {showThumbnailUpload && (
              <div className='d-flex align-items-center my-3 '>
                <div>
                  {currentStep !== 1 && (
                    <i
                      className='ri-checkbox-line text-secondary'
                      style={{ fontSize: '20px' }}
                    />
                  )}
                </div>
                <p className='card-title-desc my-0 mx-2 text-secondary'>
                  {currentStep !== 1
                    ? 'Images Uploaded'
                    : 'Uploading Images...'}
                </p>
              </div>
            )}
            {showSelectAudio && (
              <div className='d-flex align-items-center mb-3'>
                <div>
                  {currentStep > 2 && (
                    <i
                      className='ri-checkbox-line text-success'
                      style={{ fontSize: '20px' }}
                    />
                  )}
                </div>
                <p
                  className={
                    'card-title-desc my-0 mx-2 ' +
                    (currentStep >= 2 && 'text-success')
                  }
                >
                  {currentStep > 2 ? 'Product Uploaded' : 'Uploading Product...'}
                </p>
              </div>
            )}

            <div className='d-flex align-items-center mb-4'>
              <div>
                {currentStep > 3 && (
                  <i
                    className='ri-checkbox-line text-info'
                    style={{ fontSize: '20px' }}
                  />
                )}
              </div>
              <p
                className={
                  'card-title-desc my-0 mx-2 ' +
                  (currentStep >= 3 && 'text-info')
                }
              >
                {currentStep > 3
                  ? `Product ${processedWord}`
                  : `Creating ${processingWord}...`}
              </p>
            </div>

            <div>
              <Progress multi style={{ height: '24px' }}>
                <Progress
                  bar
                  color='secondary'
                  value={thumbnailUploadPercentage}
                ></Progress>
                {showSelectAudio && (
                  <Progress
                    bar
                    color='success'
                    value={contentUploadPercentage}
                  ></Progress>
                )}
                <Progress
                  bar
                  color='info'
                  value={currentStep > 3 ? (showThumbnailUpload ? 20 : 100) : 0}
                ></Progress>
              </Progress>
            </div>
            {currentStep === 4 && (
              <Row className='mt-4'>
                {showSelectAudio && (
                  <Col xl={6}>
                    <Button
                      color='primary'
                      className='waves-effect waves-light w-100'
                      onClick={reset}
                    >
                      Add More Product
                    </Button>
                  </Col>
                )}
                <Col xl={showSelectAudio ? 6 : 12}>
                  <Button
                    color='dark'
                    className='waves-effect waves-light w-100'
                    onClick={() => naviagte('/dashboard/Products/all')}
                  >
                    Got to all Product
                  </Button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default UploadStatusBar
