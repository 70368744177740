import PageCard from 'Components/PageCard'
import UnpublishedPage from 'Components/UnpublishedPage'
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useNavigate } from 'react-router-dom'
import {
  PublishedPageTableHeading,
  UnPublishedPageTableHeading,
} from '../../Contants'
import { getPublishedPageReq, getUnpublishedPageReq, publishPage, UnpublishPage } from '../../Page.Apis'

const AllPage = () => {
  const [publishedPage, setPublishedPage] = useState([])
  const [unpublishedPage, setUnpublishedPage] = useState([])
  const [showPublishAlert, setShowPublishAlert] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [showUnpublishAlert, setShowUnpublishAlert] = useState(false)
  const [showUnpublishConfirm, setShowUnpublishConfirm] = useState(false)
  const [publishID, setPublishID] = useState('')
  const [unpublishID, setUnpublishID] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  })
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue

  const navigate = useNavigate()

  const handlePublishPageData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getPublishedPageReq(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setPublishedPage(data.data)

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleUnpublishPageData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUnpublishedPageReq(queryParams)
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      })
      setUnpublishedPage(data.data)

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handlePublishPageData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleUnpublishPageData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  const handleViewPage = (pageID) => {
    navigate('/dashboard/Page/view/' + pageID)
  }

  const handleEditPage = (pageID) => {
    console.log('/dashboard/Page/edit/' + pageID)
    navigate('/dashboard/Page/edit/' + pageID)
  }

  const handleUnpublishPageClick = async(pageID) => {
    setUnpublishID(pageID)
    setShowUnpublishAlert(true)
  }

  const handlePublishPageClick = async(pageID) => {
    setPublishID(pageID)
    setShowPublishAlert(true)
  }

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishPageData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleunpublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishPageData({ page, limit: sizePerPage })
    setPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    })
  }

  const UnpublishPageAction = async() => {
    await UnpublishPage(unpublishID)
    const filteredProducts = publishedPage.filter(
      ({ _id }) => _id !== publishID
    )
    setPublishedPage(filteredProducts)
    setUnpublishID('')
    setShowUnpublishAlert(false)
    setShowUnpublishConfirm(true)
    // await getUnpublishedCouponReq({ page: 1, limit: 10 })
    // await getPublishedCouponReq({ page: 1, limit: 10 })
  }

  const publishPageAction = async() => {
    await publishPage(publishID)
    const filteredProducts = unpublishedPage.filter(
      ({ _id }) => _id !== publishID
    )
    setUnpublishedPage(filteredProducts)
    setPublishID('')
    setShowPublishAlert(false)
    setShowPublishConfirm(true)
    // await getPublishedCouponReq({ page: 1, limit: 10 })
    // await getUnpublishedCouponReq({ page: 1, limit: 10 })
  }
  return (
    <>
      <PageCard
        tableData={publishedPage}
        tableHeading={PublishedPageTableHeading}
        handleViewPage={handleViewPage}
        handleEditPage={handleEditPage}
        handleTablePageChange={handlePublishedTablePageChange}
        handleUnpublishPage={handleUnpublishPageClick}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      <UnpublishedPage
        tableData={unpublishedPage}
        tableHeading={UnPublishedPageTableHeading}
        handleViewPage={handleViewPage}
        handleEditPage={handleEditPage}
        handleTablePageChange={handleunpublishedTablePageChange}
        handlePublishPage={handlePublishPageClick}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        totalSize={unpublishedTotalSize}
        isLoading={isLoading}
      />


      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={publishPageAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}
      {showPublishConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowPublishAlert(false)
            setShowPublishConfirm(false)
            // getPublishedCouponReq({ page: 1, limit: 10 })
            window.location.reload()
          }}
        >
          Page has been Published
        </SweetAlert>
      ) : null}
      `
      {showUnpublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={UnpublishPageAction}
          onCancel={() => setShowUnpublishAlert(false)}
        ></SweetAlert>
      ) : null}
      {showUnpublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnpublishAlert(false)
            setShowUnpublishConfirm(false)
            // getUnpublishedCouponReq({ page: 1, limit: 10 })
            window.location.reload()
          }}
        >
          Page has been Unpublished
        </SweetAlert>
      ) : null}
    </>
  )
}

export default AllPage
