import AllTags from '../Views/AllTags'
import TagsBin from '../Views/TagsBin'

const TagsRoutes = [
  {
    Component: AllTags,
    path: '/all',
    exact: true,
    id: 'dashboardTagsAll',
    routePath: '/dashboard/Tags/all',
    name: 'All Category',
  },
  {
    Component: TagsBin,
    path: '/bin',
    exact: true,
    id: 'dashboardTagsCreate',
    routePath: '/dashboard/Tags/bin',
    name: 'Category Bin',
  }
]

export default TagsRoutes
