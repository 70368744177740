import AllMetadata from '../views/AllMetaData'
import CreateMetaData from '../views/CreateMetaData'
import EditMetaData from '../views/EditMetaData'
import EditPageMetaData from '../views/EditPageMetaData'
import PageMetaData from '../views/PageMetaData'

export const MetaDataRoutes = [
  {
    Component: AllMetadata,
    path: '/all',
    exact: true,
    id: 'dashboardAllMeta',
    routePath: '/dashboard/meta-data/all',
    name: 'All Meta Data',
  },
  {
    Component: CreateMetaData,
    path: '/create',
    exact: true,
    id: 'dashboardCreateMeta',
    routePath: '/dashboard/meta-data/create',
    name: 'Create Course Meta Data',
  },
  {
    Component: EditMetaData,
    path: '/edit/:id',
    exact: true,
    id: 'dashboardEditmeta',
  },
  {
    Component: PageMetaData,
    path: '/page/create',
    exact: true,
    id: 'dashboardPagemeta',
    routePath: '/dashboard/meta-data/page/create',
    name: 'Create Page Meta Data',
  },
  {
    Component: EditPageMetaData,
    path: '/page/edit/:id',
    exact: true,
    id: 'dashboardPagemeta',
  }
]