import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { configureStore } from 'Redux/store'
import App from 'App'
import 'Utils/interceptors'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

export const store = configureStore({})

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <Router>
        <App />
      </Router>
    </StrictMode>
  </Provider>,
  document.getElementById('root')
)
