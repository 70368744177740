import AllPages from '../views/AllPages'
import CreatePages from '../views/CreatePages'

const PagesRoutes = [
  {
    Component: AllPages,
    path: '/all',
    exact: true,
    id: 'dashboardPagesAll',
    routePath: '/dashboard/Pages/all',
    name: 'All Pages',
  },
  {
    Component: CreatePages,
    path: '/create',
    exact: true,
    id: 'dashboardPagesCreate',
    routePath: '/dashboard/Pages/create',
    name: 'Add Pages',
  },
]

export default PagesRoutes