import axios from 'axios'

import baseDomain, {
  productApi,searchProductApi
  // searchContentAPI
} from '../../../../Constants/apiRoutes'

const subDomain = 'https://ecom-prod-be.theyogainstitute.org/v1'


export const getAllProductReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${productApi}?page=${page}&limit=${limit}`)
}

export const editProductReq = (data, productID) => {
  return axios.patch(`${subDomain}${productApi}/update/${productID}`, data)
}

export const getProductReq = (productID) => {
  return axios.get(`${subDomain}${productApi}/${productID}`)
}

export const getPublishedProduct = ({ page, limit }) => {
  return axios.get(`${subDomain}${productApi}/publishedproduct?page=${page}&limit=${limit}`)
}

export const publishProduct = (productID) => {
  return axios.put(`${subDomain}${productApi}/unpublish/${productID}`)
}

export const unPublishProduct = (productID) => {
  return axios.put(`${subDomain}${productApi}/publish/${productID}`)
}

export const getUnpublishedProduct = ({ page, limit }) => {
  return axios.get(`${subDomain}${productApi}/unpublishedproduct?page=${page}&limit=${limit}`)
}


export const getProductBin = ({ page, limit }) => {
  return axios.get(`${subDomain}${productApi}/bin?page=${page}&limit=${limit}`)
}

export const creatProductReq = (data) => {
  return axios.post(`${subDomain}${productApi}`, data)
}

export const deleteProductReq = (productID) => {
  return axios.delete(`${subDomain}${productApi}/delete/${productID}`)
}


export const restoreProducts = (restoreProductID) => {
  return axios.put(`${subDomain}${productApi}/restore/${restoreProductID}`)
}

export const searchProduct = (text) => {
  return axios.get(`${subDomain}${searchProductApi}/${text}`)
}

export const searchCategory = (text) => {
  return axios.get(`${subDomain}/category/searchcategory/${text}`)
}

export const getShipping = () =>{
  return axios.get(`${subDomain}/shipping`)
}

export const createshipping = (weight, price,currency) =>{
  return axios.post(`${subDomain}/shipping`,{ weight:weight,price:price,currency:currency })
}

export const updateShipping =(shippingID,weight, price, currency)=>{
  console.log(currency,'kite')
  return axios.patch(`${subDomain}/shipping/update/${shippingID}`,{ weight:weight,price:price,currency:currency })
}


