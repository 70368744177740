import CouponForm from 'Components/CouponForm'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { creatCouponReq } from '../../Coupons.Apis'

const CreateCoupon = () => {
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error

  const [pro, setPro] =useState(false)

  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    couponType: '',
    couponCode: '',
    discountType: '',
    couponDiscount: '',
    numberOfUsers: '',
    expiryTime: '',
  })

  const {
    couponType,
    couponCode,
    discountType,
    couponDiscount,
    numberOfUsers,
    expiryTime,
  } = formData

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  // Create Coupons
  const handleSubmit = async(e) => {
    e.preventDefault()

    await creatCouponReq({
      couponType,
      couponCode,
      discountType,
      couponDiscount,
      numberOfUsers,
      expiryTime,
    })

    navigate('/dashboard/coupon/all')

  }

  return (
    <div>
      <CouponForm
        handelInput={handelInput}
        // category={category}
        couponType={couponType}
        couponCode={couponCode}
        discountType={discountType}
        couponDiscount={couponDiscount}
        numberOfUsers={numberOfUsers}
        expiryTime={expiryTime}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default CreateCoupon
