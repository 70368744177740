import { Route, Routes, Navigate } from 'react-router-dom'

import ImageRoutes from './constants/routes'

const Images = () => {
  return (
    <>
      <Routes>
        {ImageRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
        <Route
          path="*"
          element={
            <Navigate to={ImageRoutes[0]?.routePath || '/dashboard'} replace />
          }
        />
      </Routes>
    </>
      




  )


}

export default Images




