// import './style.css'
// const TagsInput =({ tags,setTags })=>{
//   // const [tags, setTags] = useState([])
//   function handleKeyDown(e){
//     if(e.key !== 'Enter') return
//     const value = e.target.value
//     if(!value.trim()) return
//     setTags([...tags, value])
//     e.target.value = ''
//   }

//   const removeTag = (index)=>{
//     setTags(tags?.filter((el, i) => i !== index))
//   }

//   return (
//     <div className="tags-input-container">
//       { tags?.map((tag, index) => (
//         <div className="tag-item" key={index}>
//           <span className="text">{tag.name || tag}</span>
//           <span className="close" onClick={() => removeTag(index)}>&times;</span>
//         </div>
//       )) }
//       <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Type somthing" />
//     </div>
//   )
// }

// export default TagsInput

import { useEffect, useState } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { createTag, getAllTags } from 'Views/Dashboard/Views/Blogs/Api'
import './style.css'

const TagsInput = ( { tags,tagsId, setTagsId } ) => {
  const [tag, setTag] = useState([])
  const [userTag, setUserTag] = useState(tags)

  const getTags = async() =>{
    const { data } = await getAllTags()
    setTag(
      data.data.map((item) => {
      
        return { ...item, text: item?.name, id: item?._id }
      })
    )
  }
  useEffect(() => {
    getTags()
  }, [])

  const handleDeleteTags = (i) => {
    setUserTag(userTag.filter((tags, index) => index != i))
    setTagsId(tagsId.filter((tags, index) => index != i))
  }

  const handleTagsAddition = async(tags) => {
    if (tags.objectType) {
      setUserTag([...userTag, tags])
      setTagsId([...tagsId,tags.id])
    } else {
      const { data } = await createTag({
        name: tags.text,
        objectType: 'TAG',
        description: '',
      })
      setUserTag([...userTag, { text: data.data.name, id: data.data._id }])
      setTagsId([...tagsId,data.data._id])
    }
  }

  return (
    <div>
      <ReactTags
        handleAddition={handleTagsAddition}
        suggestions={tag}
        tags={userTag}
        handleDelete={handleDeleteTags}
      />
    </div>
  )
}

export default TagsInput
