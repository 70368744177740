export const PublishedCourseTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Course Name',
    dataField: 'title',
  },
  {
    text: 'Is Free',
    dataField: 'isFree',
  },
  {
    text: 'Course Details',
    dataField: 'description',
  }
]

export const UnPublishedCourseTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Course Name',
    dataField: 'title',
  },
  {
    text: 'Is Free',
    dataField: 'isFree',
  },
  {
    text: 'Course Details',
    dataField: 'description',
  }
]

export const binCourseTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Course Name',
    dataField: 'title',
  },
  {
    text: 'Course Details',
    dataField: 'description',
  },
]
