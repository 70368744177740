
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Label, FormGroup, Alert } from 'reactstrap'
import { getImgRatioHvW } from './MultipleImageUploader'
import formatBytes from 'Utils/formatBytes'

const MultiFileUploader = ({
  file,
  setFile,
  accept = '',
  isMultiple = true,
  dragText = 'Drop files here or click to upload.',
  heading,
  setImageColor,
  imageColor,
  isDisabled = false,
  isImage,
  imgRatioRangeLow,
  imgRatioRangeHigh,
  errMsg,
  maxFiles
}) => {
  const [imgSizeError, setImgSizeError] = useState({
    isImgSizeError: false,
    imgSizeErrorMsg: '',
  })
  const { isImgSizeError, imgSizeErrorMsg } = imgSizeError

  const handleAcceptedFiles = async(acceptedFile) => {
    console.log(acceptedFile,'sdfgh')
    try {
    

      const fileTemps = acceptedFile
      const fileUrls =  fileTemps.map((item)=>{
        console.log(item,'dff')
        console.log(URL.createObjectURL(item),'url-url')
        return (
          URL.createObjectURL(item)
        )
      })
      // const fileUrl = URL.createObjectURL(fileTemp)
      const files = fileUrls.map((item,idx)=>{
        console.log(item,'fileurl')
        let obj = Object.assign(fileTemps[idx], {
          preview: item,
          formattedSize: formatBytes(fileTemps[idx].size),
        })
        console.log(obj,'obj')
        return obj
      })
      // const file = Object.assign(fileTemp, {
      //   preview: fileUrl,
      //   formattedSize: formatBytes(fileTemp.size),
      // })
      
      if (isImgSizeError)
        setImgSizeError({
          isImgSizeError: false,
          imgSizeErrorMsg: '',
        })
      if (isImage) {
        console.log(files,'shh')
        const dim = await getImgRatioHvW(files[0].preview)
        if (dim > imgRatioRangeLow && dim < imgRatioRangeHigh) {
          // if (setImageColor) {
          //   const colors = await getColors(fileUrl)
          //   setImageColor(colors[0].hex())
          // }
          return setFile(files)
        }else
          return setImgSizeError({
            isImgSizeError: true,
            imgSizeErrorMsg: errMsg,
          })
      }
      setFile(files)
    } catch (error) {
      console.error(error)
    }
    
  }

  return (
    <FormGroup className='select2-container mt-3'>
      <Label className='control-label'>{heading}</Label>
      <Dropzone
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        multiple={isMultiple}
        accept={accept}
        disabled={isDisabled}
        maxFiles={maxFiles}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={`dropzone ${isImgSizeError && 'border-danger'}`}
            style={imageColor && { background: imageColor }}
          >
            <div className='needsclick' {...getRootProps()}>
              <input {...getInputProps()} />
              <div className='dropzone-previews' id='file-previews'>
                {file[0] ? (
                  <div className='d-flex justify-content-center align-items-center mt-4'>
                    {typeof file[0] === 'string' &&
                      /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(file[0]) && (
                      <img
                        data-dz-thumbnail=''
                        height='180'
                        className='bg-light'
                        alt={file[0]}
                        src={file[0]}
                        style={{
                          background:
                              'linear-gradient(to bottom, #eee, #ddd)',
                          borderRadius: '20px',
                          height:'90%',
                          width:'90%'
                         
                        }}
                      />
                    )}

                    {typeof file[0] === 'string' &&
                      !/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(file[0]) && (
                      <div
                        className='d-flex py-4 align-items-center flex-column'
                        style={{
                          height: '180px',
                          minWidth: '180px',
                          background:
                              'linear-gradient(to bottom, #eee, #ddd)',
                          borderRadius: '20px',
                          padding: '0 10px',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: 'rgba(255,255,255,0.4)',
                            borderRadius: '3px',
                            marginTop: '1rem',
                            padding: '2px 6px',
                          }}
                        >
                            Preview Content below
                        </div>
                        <div
                          style={{
                            backgroundColor: 'rgba(255,255,255,0.4)',
                            borderRadius: '3px',
                            marginTop: '1rem',
                            padding: '2px 6px',
                          }}
                        >
                            Or Upload image by tap or DragDrop
                        </div>
                      </div>
                    )}

                    {typeof file[0] !== 'string' && (
                      <>
                        {file[0].type.includes('image') ? (
                          <img
                            data-dz-thumbnail=''
                            height='180'
                            className='bg-light'
                            alt={file[0].name}
                            src={file[0].preview}
                            style={{
                              background:
                                'linear-gradient(to bottom, #eee, #ddd)',
                              borderRadius: '20px',
                              height:'90%',
                              width:'90%'
                            }}
                          />
                        ) : (
                          <div
                            className='d-flex py-4 align-items-center flex-column'
                            style={{
                              height: '180px',
                              minWidth: '180px',
                              background:
                                'linear-gradient(to bottom, #eee, #ddd)',
                              borderRadius: '20px',
                              padding: '0 10px',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: 'rgba(255,255,255,0.4)',
                                borderRadius: '3px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                marginTop: '1.2rem',
                                padding: '2px 6px',
                              }}
                            >
                              {file[0].formattedSize}
                            </div>
                            <div
                              style={{
                                backgroundColor: 'rgba(255,255,255,0.4)',
                                borderRadius: '3px',
                                marginTop: '1rem',
                                padding: '2px 6px',
                              }}
                            >
                              {file[0].name}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className='d-flex justify-content-center align-items-center flex-column mt-5'>
                    <i className='display-4 text-muted ri-upload-cloud-2-line mt-4'></i>
                    <h4>{dragText}</h4>
                    {isImgSizeError && (
                      <Alert color='danger'>{imgSizeErrorMsg}</Alert>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </FormGroup>
  )
}

export default MultiFileUploader
