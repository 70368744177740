import { useEffect, } from 'react'
import { useNavigate } from 'react-router'
import { isAuthorized } from 'Utils/localStorage'

const DecisionComponent = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if(isAuthorized()) navigate('/dashboard')
    else navigate('/auth/login')
    // eslint-disable-next-line
  }, [])

  return null
}

export default DecisionComponent