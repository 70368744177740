import ImageUploads from '../views/Image'
import AllContent from '../views/AllContent'
import EditContent from '../views/EditContent'
import ContentView from '../views/ViewContent'

export const UploadRoutes = [
  {
    Component: AllContent,
    path: '/all',
    exact: true,
    id:'dashboardUploadContent',
    routePath: '/dashboard/upload/all',
    name: 'All Content',
  },  
  {
    Component: ImageUploads,
    path: '/image',
    exact: true,
    id:'dashboardUploadImage',
    routePath: '/dashboard/upload/image',
    name: 'Create',
  },  
  {
    Component: EditContent,
    path: '/edit/:contentID',
    exact: true,
    id: 'dashboardContentEdit',
  },
  {
    Component: ContentView,
    path: '/view/:contentID',
    exact: true,
    id: 'dashboardContentView',
  },

]