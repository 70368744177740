import { useEffect, useState } from 'react'
import Table from 'Components/Table'
import DatePicker from 'react-date-picker'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { allocateRoom, saveRoom } from 'Views/Dashboard/Views/AdCourses/Api'

const AvailableRoomCard = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  handleAction,
  handleConfirmClick,
  selectedLimit,
  selectUserData,
  handleSelectUsersListChange, getAllRooms, startDate, endDate, handleStartDateChange, handleEndDateChange
}) => {


  const [rowData, setRowData] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [roomForm, setRoomForm] = useState({ roomNo: '', numberOfBeds: '' })
  const [isCancel, setIsCancel] = useState(false)
  const [createRoomModal, setCreateRoomModal] = useState(false)
  // RoomBookingStatus {
  //     Pending = 0,
  //     AllocationInProgress = 1,
  //     Allocated = 2,
  //     CheckIn = 3,
  //     Cancelled = 4,
  //     CheckedOut = 5
  // }
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center', gap: '4px', display: 'flex', flexDirection: 'column' }} key={row._id}>
        {row?.roomBooking?.status == 1 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Allocate Room
        </Button>}
        {row?.roomBooking?.status == 2 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(true); setSelectedUser(row); handleAction(row) }}
        >
          Cancel
        </Button>}
        {row?.roomBooking?.status == 2 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Check In
        </Button>}
        {row?.roomBooking?.status == 3 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Check Out
        </Button>}

      </div>
    )
  }
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const createRoom = async (roomForm) => {
    await saveRoom(roomForm).then(res => {
      getAllRooms()
      setRoomForm({ roomNo: '', numberOfBeds: '' })
      setCreateRoomModal(false)
    }).catch(err => {
      alert(err?.data?.message);
    })

  }

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
   
        // const fDate = new Date(el.createdAt)
        // console.log(fDate)
        var o = { ...el }
        // , createdAt: getTime(fDate), status: getPaymentStatus(el.status), 'courseSubscriptions.mode': getPaymentMode(el.courseSubscriptions?.mode), 'roomBooking': getRoomStatus(el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  useEffect(() => {
    // tableHeading.push({
    //   text: 'Actions',
    //   // dataField: 'view',
    //   isDummyField: true,
    //   headerStyle: { textAlign: 'center', width: '10%' },
    //   formatter: viewContentFormatter,
    // })
    return () => {
      // tableHeading.pop()
      // tableHeading.pop()
    }
  }, [])

  return (
    <Row>
      {/* <Modal
        isOpen={createRoomModal}
        size={'lg'}
        className='ImageUpoader-modal'
      >
        <ModalBody>
          <div className="closeButton">
            <button className='closemodal' onClick={() => setCreateRoomModal(false)}>X</button>
            <h4>Create Room</h4>
          </div>
          <br />
          <Row>
            <Col lg={6}>
              <FormGroup>
                <Input
                  // isMulti={false}
                  value={roomForm.roomNo}
                  type='number'
                  // options={courseList}
                  // className="basic-multi-select"
                  // classNamePrefix="select"
                  placeholder="Enter Room No."
                  onChange={(e) => {
                    setRoomForm(prevData => ({
                      ...prevData,
                      roomNo: e.target.value
                    }));
                  }}
                // onChange={handleRelatedCoursesChange}
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Input
                  type='number'
                  value={roomForm.numberOfBeds}
                  placeholder="Enter Total Beds"
                  onChange={(e) => {
                    setRoomForm(prevData => ({
                      ...prevData,
                      numberOfBeds: e.target.value
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Col lg={12} className='users-col' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <FormGroup>
                <button className='export-btn' onClick={() => createRoom(roomForm)}>Create</button>
              </FormGroup>
            </div>
          </Col>
        </ModalBody>
      </Modal> */}

      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>Available Rooms</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
            <Col xs='auto'> <div>
                <DatePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                  clearIcon={null}
                // className={styles}
                />
                <DatePicker
                  onChange={handleEndDateChange}
                  value={endDate}
                  clearIcon={null}
                // className={styles}
                />
                <br />
                {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}

                {/* <button onClick={handleDateFilter} className='date-btn'>
                      Apply Date Filter
                    </button> */}
              </div>
              </Col>
              <Col xs={2} className='users-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={selectedLimit}
                    options={selectUserData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="No. of Rows"
                    onChange={handleSelectUsersListChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => handleConfirmClick()} disabled={isDateRangeValid(startDate, endDate)?'':'true'}>Apply Filters</button>
                </FormGroup>
              </Col>
              {/* <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => setCreateRoomModal(true)}>Create Room</button>
                </FormGroup>
              </Col> */}
            </Row>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={10}
            />
          </CardBody>
        </Card>
      </Col>
    </Row >
  )
}

export default AvailableRoomCard
