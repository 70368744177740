import { useState, useEffect } from 'react'
import EditYogasattvaComp from 'Components/EditYogsattvaComp'
import { getYogasattvaDataById, updateYogsattvaAPI } from '../../Api'
import { useParams, useNavigate } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/Helper'
import { creatingStates } from '../../constants'

const EditUpdateYogsattva = () => {

  const [formData, setFormData] = useState({
    title: '',
    month: '',
    year: '',
  })

  const { title, month, year } = formData
  const [yogsattvaImg, setYogsattvaImg] = useState(null)
  const [yogsattvaPDF, setYogsattvaPDF] = useState(null)
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [previousData, setPreviousData] = useState({})
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)



  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })

  const { isCreating, creatingStage } = creating
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()
  const isFormValid = title !== title
  

  const isFieldsChanged = (curr) => {
    let prev = JSON.stringify(previousData)
    let current = JSON.stringify(curr)
    if (prev !== current ) {
      setIsDataUpdated(true)
    } else {
      setIsDataUpdated(false)
    }
  }

  let debounce
  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value
    })
    clearTimeout(debounce)
    debounce = setTimeout(
      isFieldsChanged({
        ...formData,
        ...editedData,
        [e.target.name]: e.target.value
      }),
      500
    )
  }
  

  const getDataByID = async() => {
    try {
      setIsLoading(true)
      const { data } = await getYogasattvaDataById(id)
      setFormData(data?.data)
      setYogsattvaImg(data?.data?.imageUrl)
      setPreviousData({
        ...data?.data,
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateData = () => {
    let typeOfImg = typeof yogsattvaImg
    setIsLoading(true)
    if (typeOfImg !== 'string') {
      uploadFile(
        yogsattvaImg,
        yogsattvaImg?.type,
        setThumbnailUploadPercentage,
        30
      ).then((imgUrl) => {

        setEditedData({ ...editedData, imageUrl: imgUrl })
        imgUrl && setIsLoading(false)
        imgUrl && setIsDataUpdated(true)
      })
    }
  }

  const handleUpdateDataPdf = () => {
    let typeOfPdf = typeof yogsattvaPDF
    try {
      setIsLoading(true)
      if (typeOfPdf !== 'string') {
        uploadFile(
          yogsattvaPDF,
          yogsattvaPDF?.type,
          setThumbnailUploadPercentage,
          30
        ).then((pdfUpload) => {
          setEditedData({ ...editedData, pdfUrl: pdfUpload })
          pdfUpload && setIsLoading(false)
          pdfUpload && setIsDataUpdated(true)
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    handleUpdateData()
    handleUpdateDataPdf()
  }, [yogsattvaImg, yogsattvaPDF])

  const getUpdateYogsattva = async() => {
    try {
      if (!yogsattvaImg)
        return setError({
          isError: true,
          errorMessage: 'Please select a cover image',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,

      })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })

      await updateYogsattvaAPI(
        id, editedData
      )
      setShowUnPublishAlert(false)
      setShowUnPublishConfirm(true)
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }
    setShowUnPublishAlert(false)
    setShowUnPublishConfirm(true)

  }

  const handleSubmit = async() => {
    setShowUnPublishAlert(true)
    setShowUnPublishConfirm(false)
  }

  useEffect(() => {
    getDataByID()
  }, [])

  return (
    <div>
      <div>
        <EditYogasattvaComp
          title={title}
          month={month}
          year={year}
          yogsattvaImg={yogsattvaImg}
          yogsattvaPDF={yogsattvaPDF}
          setYogsattvaImg={setYogsattvaImg}
          setYogsattvaPDF={setYogsattvaPDF}
          formData={formData}
          isLoading={isLoading}
          editedData={editedData}
          isDisabled={isDisabled}
          isDataUpdated={isDataUpdated}
          isFormValid={isFormValid}
          setFormData={setFormData}
          handleInput={handleInput}
          handleSubmit={handleSubmit}
        />
      </div>
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={getUpdateYogsattva}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            navigate('/dashboard/yogsattva/all')
          }}
        >
          Yogsattva has been Edited
        </SweetAlert>
      ) : null}
    </div>
  )
}

export default EditUpdateYogsattva
