import CreateProduct from 'Components/ProductForm'
import UploadStatusBar from 'Components/UploadStatusBar'
import { useEffect, useState } from 'react'
import { creatingStates } from 'Views/Dashboard/Views/Uploads/Constants'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/upload.Helper'
import { creatProductReq, getAllProductReq } from 'Views/Dashboard/Views/Products/Api.js'

const CreateProducts = () => {
  const [productThumbnail, setProductThumbnail] = useState(null)
  const [bannerImage, setBannerImage] = useState(null)
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })
  const [selectedCategory, setSelectedCategory] = useState({
    name: '',
    _id: '',
  })
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const { isCreating, creatingStage } = creating

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)
  const [similarProductsData, setSimilarProductsData] = useState([])
  const [productImage1, setProductImage1] = useState('')
  const [productImage2, setProductImage2] = useState('')
  const [productImage3, setProductImage3] = useState('')


  const [formData, setFormData] = useState({
    name: '',
    skuId:'',
    description: '',
    price: '',
    priceInternational: '',
    weight:'',
    categoryId:'',
    similarProducts:[]
  })

  const { name, skuId, description, price, priceInternational,weight, similarProducts } = formData

  const getProductList = async()=>{
    let arr=[]
    try{
      const { data } = await getAllProductReq(1,10)
      for await (let item of data){
        arr.push({ name: item.name,id:item._id })
      }
    }catch(err){
      console.log(err)
    }
  }


  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSimilarProducts = (products) => setSimilarProductsData(products)
 
  // Create Product
  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      if (!productThumbnail || !(productImage1||productImage2||productImage3) || !bannerImage)
        return setError({
          isError: true,
          errorMessage: 'Please select thumbnail and Audio',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
  
      const bannerUrl = await uploadFile(
        bannerImage,
        'BANNER',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
        
      })
      
      const thumbnailUrl = await uploadFile(
        productThumbnail,
        'THUMBNAIL',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
        
      })

      const productUrl1 =productImage1 && await uploadFile(
        productImage1,
        'IMAGE',
        setContentUploadPercentage,
        50
      )
      

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      const productUrl2 = productImage2 && await uploadFile(
        productImage2,
        'IMAGE',
        setContentUploadPercentage,
        50
      )
      

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      const productUrl3 = productImage3 && await uploadFile(
        productImage3,
        'IMAGE',
        setContentUploadPercentage,
        50
      )
      

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
 
      await creatProductReq(
        {
          name,
          skuId,
          description,
          price,
          priceInternational,
          weight,
          similarProducts,
          categoryId: selectedCategory._id,
          productImage: [ productUrl1,productUrl2,productUrl3 ],
          bannerImage: bannerUrl,
          productThumbnail: thumbnailUrl,
        }
      )
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }
  }


  const reset = () => {
    setProductThumbnail(null)
    setProductImage1(null)
    setProductImage2(null)
    setProductImage3(null)
    setBannerImage(null)
    setError({
      isError: false,
      errorMessage: '',
      errorType: 'warning',
    })
    setFormData({
      title: '',
      description: '',
    })
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    })
  }


  useEffect(()=>{
    getProductList()
  },[ ])


  return (
    <div>
      {isCreating ? (
        <UploadStatusBar
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={reset}
        />
      ) : (
        <CreateProduct
          handelInput={handelInput}
          name={name}
          skuId={skuId}
          price={price}
          priceInternational={priceInternational}
          weight={weight}
          description={description}
          bannerImage={bannerImage}
          setBannerImage={setBannerImage}
          productThumbnail={productThumbnail}
          setProductThumbnail={setProductThumbnail}
          productImage1={productImage1}
          setProductImage1={setProductImage1}
          productImage2={productImage2}
          setProductImage2={setProductImage2}
          productImage3={productImage3}
          setProductImage3={setProductImage3}
          handleSubmit={handleSubmit}
          isError={isError}
          errorMessage={errorMessage}
          errorType={errorType}
          setSelectedCategory={setSelectedCategory}
          similarProducts={similarProducts}
          handleSimilarProducts={handleSimilarProducts}
          similarProductsData={similarProductsData}
          
        />
      )}
    </div>
  )
}

export default CreateProducts
