import AllTotalHealth from '../Views/AllTotalHealth'
import EditTotalHealth from '../Views/EditTotalHealth'

const TotalHealthRoutes = [
  {
    Component:AllTotalHealth,
    path:  '/all',
    exact: true,
    id: 'dashboardTotalHealthAll',
    routePath: '/dashboard/totalHealth/all',
    name:'Edit Total Health'
  },
  {
    Component:EditTotalHealth,
    path:  '/edit/:id',
    exact: true,
    id: 'dashboardTotalHealthEdit',
    routePath: '/dashboard/totalHealth/edit',
  },
]

export default TotalHealthRoutes