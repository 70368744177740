import AllCoupons from '../Views/AllCoupons'
import EditCoupon from '../Views/EditCoupon'
import CreateCoupon from '../Views/CreateCoupon'


export const CouponsRoutes = [
  {
    Component: AllCoupons,
    path: '/all',
    exact: true,
    id: 'dashboardCouponsAll',
    routePath: '/dashboard/coupon/all',
    name: 'All Coupons',
  },
  {
    Component: CreateCoupon,
    path: '/create',
    exact: true,
    id: 'dashboardCouponCreate',
    routePath: '/dashboard/coupon/create',
    name: 'Add Coupon',
  },
  {
    Component: EditCoupon,
    path: '/edit/:couponID',
    exact: true,
    id: 'dashboardCouponEdit',
  },
]
