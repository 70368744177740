import { useState } from 'react'
import { Row, Col, Card, CardBody,Button } from 'reactstrap'
import Table from 'Components/Table'
import { PageMetaHeading } from 'Views/Dashboard/Views/Meta/constants/utils'
import { getAllMetaData } from 'Views/Dashboard/Views/Meta/api'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


const MetaDataPage = () => {
  const { id } = useParams()
  const [metaRowData, setMetaRowData] = useState([])
  const [metaCount, setMetaCount] = useState()
  const [pagination, setPagination] = useState({
    metaPage:1,
    metaLimit:10
  })
  const navigate= useNavigate()
  const { metaPage, metaLimit } = pagination

  const handleEditTablePageChange = (type, { page, sizePerPage }) => {
    setPagination({ metaPage:page, metaLimit:sizePerPage })
  }

  const handleEditPageMetaData=(id)=>{
    navigate('/dashboard/meta-data/page/edit/' + id)
  }

  const handleEditPageMetaDataClick =(cell, row)=>{
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handleEditPageMetaData(row.pagePath)}
        >
          Edit
        </Button>
      </div>
    )
  }

  useEffect(()=>{
    PageMetaHeading.push({
      text:'Edit Meta Data',
      dataField: 'edit',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: handleEditPageMetaDataClick,
    })
    return () =>{
      PageMetaHeading.pop()
    }
  },[])

  const handleMetaTableDataCourse = async() => {
    try {
      const { data } = await getAllMetaData({ page:metaPage, limit:metaLimit , objectType :'PAGE' })
      setMetaCount(data.count)
      if (data.data) {
        var result = data.data.map(function(el, i) {
          var o = Object.assign({}, el)
          o.sNo = i + 1 + metaPage * 10 - 10
          return o
        })
        setMetaRowData(result)
        console.log(metaRowData,'helllooooo')
      }
    } catch (error) {
      console.log(error)
    }
   
  }

  useEffect(() => {
    handleMetaTableDataCourse()
  }, [pagination])

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className='m-0'>Page Meta Data</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Table
                  rows={metaRowData}
                  columns={PageMetaHeading}
                  onTableChange={handleEditTablePageChange}
                  page={metaPage}
                  sizePerPage={metaLimit}
                  totalSize={metaCount}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MetaDataPage