import ImageUploads from '../Views/Uploads'
import AllImages from '../Views/All images'

const ImageRoutes =[

  {
    Component:AllImages,
    path: '/all',
    exact: true,
    id : 'dashboardImagesAll',
    routePath: '/dashboard/Images/all',
    name: 'All Images',
  },

  {
    Component: ImageUploads,
    path: '/uploads',
    exact: true,
    id: 'dashboardImagesUploads',
    routePath: '/dashboard/Images/uploads',
    name: 'Uploads',
  },


]

export default ImageRoutes

