import { Fragment, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Header from './Components/Header'
import Sidebar from './Components/Sidebar'
import { logoutUserAction } from 'Views/Authentication/Auth.actions'
import { useNavigate } from 'react-router'
import { clearLocal } from 'Utils/localStorage'
import Footer from './Components/Footer'

// import AuthContext from '../../context/auth/authContext'

const DashboardLayout = ({ children }) => {
  const { auth }  = useSelector((state ) => state)
  const dispatch = useDispatch()
  const navigator = useNavigate()
  
  const handleLogout = () =>{
    const payload = {
      navigator
    }
    dispatch(logoutUserAction(payload))
  }

  const handleAdmin = () =>{
    // handleLogout()
    clearLocal()
    navigator('/auth/admin/login')

  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Fragment>
      {auth.isLoading && (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      )}

      <div id="layout-wrapper">
        <Header 
          userName={auth.user?.firstName || ''}
          email={auth.user.email || ''}
          handleLogout={handleLogout}
          handleAdmin={handleAdmin}
        />
        <Sidebar />
        <div className="main-content">
          {children}
          <Footer />
        </div>
      </div>
      {/* <Rightbar /> */}
    </Fragment>
  )
}

export default DashboardLayout
