import { Fragment } from 'react'

// import {
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   InputGroup,
//   InputGroupAddon,
//   Input,
//   Button,
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
// } from "reactstrap";

import { Link } from 'react-router-dom'

import { ProfileMenu } from 'Components'

import LmsLogo from '../../../../assets/images/LmsLogo.png'

const Header = ({ userName, email, handleLogout, handleAdmin }) => {
  const style = {
    width: '100px',
  }
  return (
    <Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img className="lms-logo" style={style} src={LmsLogo} alt="LMS" />
                </span>
                <span className="logo-lg">
                  <img className="lms-logo" style={style} src={LmsLogo} alt="LMS" />
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">Nispand</span>
                <span className="logo-lg">Nispand</span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <ProfileMenu
              userName={userName}
              email={email}
              handleLogout={handleLogout}
              handleAdmin={handleAdmin}
            />
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default Header
