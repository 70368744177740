import { useEffect, useState } from 'react'
import Table from 'Components/Table'
import DatePicker from 'react-date-picker'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { allocateRoom, getIntercatedCourses, saveRoom } from 'Views/Dashboard/Views/AdCourses/Api'
import { CSVLink } from 'react-csv'

const AdUsers = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick,
  selectedLimit,
  selectUserData,
  handleSelectUsersListChange,
  roomList,
  relatedCourse,
  handleCourseChange,
}) => {

  const [rowData, setRowData] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [availableCourses, setAvailableCourses] = useState()

  // RoomBookingStatus {
  //     Pending = 0,
  //     AllocationInProgress = 1,
  //     Allocated = 2,
  //     CheckIn = 3,
  //     Cancelled = 4,
  //     CheckedOut = 5
  // }
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const getTime = (date) => {
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();
    return `${dateString} ${timeString}`;
  }
  const handleAllExportToCSV = () => {
    const csvDataAll = rowData.map((item) => ({
      Name: item?.name,
      Contact: `${item?.contactNo}`,
      Email: item?.email,
      City: item?.city,
      Course: item?.courseName,
    })
    )

    return csvDataAll
  }

  const getAvailableCourses = async () => {
    const response = await getIntercatedCourses()
    let courseArry = [{ label: 'All', value: '' }]
    response?.data?.forEach(ele => {
      courseArry.push({ label: ele, value: ele })
    })
    setAvailableCourses(courseArry)
  }

  useEffect(() => {
    getAvailableCourses()
    if (tableData) {
      var result = tableData.map(function (el, i) {
        const fDate = new Date(el.createdAt)
        var o = { ...el, createdAt: getTime(fDate), contactNo: `+${el.dialCode} ${el.contactNo}` }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  useEffect(() => {
    // tableHeading.push({
    //   text: 'Actions',
    //   // dataField: 'view',
    //   isDummyField: true,
    //   headerStyle: { textAlign: 'center', width: '10%' },
    //   formatter: viewContentFormatter,
    // })
    return () => {
      // tableHeading.pop()
      // tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>Interacted Users</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <div>
                  <DatePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    clearIcon={null}
                  /><DatePicker
                    onChange={handleEndDateChange}
                    value={endDate}
                    clearIcon={null}
                  /><br />
                  {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}</div>
              </Col>
              <Col xs={2} className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedCourse}
                    options={availableCourses}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Course"
                    onChange={handleCourseChange}
                  />
                </FormGroup>
              </Col>

              <Col xs={3} className='users-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={selectedLimit}
                    options={selectUserData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select No. of Users"
                    onChange={handleSelectUsersListChange}
                  />
                </FormGroup>
              </Col>

              <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => handleConfirmClick()} disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}>Apply Filters</button>
                </FormGroup>
              </Col>
              {rowData.length ? <Col xs="auto" className='users-col'>
                <FormGroup>
                  <CSVLink
                    data={handleAllExportToCSV()}
                    filename={'interacted_users.csv'}
                    className="export-csv-btn"
                  >
                    <button className='export-btn'>Export to CSV</button>
                  </CSVLink>
                </FormGroup>
              </Col> : <></>}
            </Row>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={10}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdUsers
