import { useState } from 'react'

import ContentForm from 'Components/ContentForm'
import UploadStatusBar from 'Components/UploadStatusBar'

import { uploadFile } from '../../Helper'

import { creatContentReq } from '../../Api'

import { creatingStates } from '../../Constants'

const CreateContent = () => {
  const [thumbnail, setThumbnail] = useState(null)
  const [audio, setAudio] = useState(null)
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const { isCreating, creatingStage } = creating

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)


  const [formData, setFormData] = useState({
    title: '',
    description: '',
    contentType: ''
  })

  const {
    title,
    description,
    contentType,
  } = formData

  // Handle form IP
  const handelInput = (e) => {
    if (e.target.name === 'isFree')
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      })
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  // Create Music
  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      if (!thumbnail || !audio)
        return setError({
          isError: true,
          errorMessage: 'Please select thumbnail and Audio',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
  
      
      const thumbnailUrl = await uploadFile(
        thumbnail,
        'THUMBNAIL',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
        
      })

      const contentUrl = await uploadFile(
        audio,
        contentType,
        setContentUploadPercentage,
        50
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      })
  
      
      await creatContentReq(
        {
          title,
          description,
          type: contentType,
          contentUrl: contentUrl,
          contentThumbnail: thumbnailUrl,
        }
      )
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Music please try again later',
        errorType: 'danger',
      })
    }

  }

  const reset = () => {
    setThumbnail(null)
    setAudio(null)
    setError({
      isError: false,
      errorMessage: '',
      errorType: 'warning',
    })
    setFormData({
      title: '',
      description: '',
    })
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    })
  }
  return (
    <div>
      {isCreating ? (
        <UploadStatusBar 
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={reset}
        />
      ) : (

        <ContentForm
          handelInput={handelInput}
          title={title}
          description={description}
          contentType={contentType}
          setThumbnail={setThumbnail}
          thumbnail={thumbnail}
          audio={audio}
          setAudio={setAudio}
          handleSubmit={handleSubmit}
          isError={isError}
          errorMessage={errorMessage}
          errorType={errorType}
        />
      )}
    </div>
  )
}

export default CreateContent