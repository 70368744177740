import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Loader } from 'Components'
import EditContentComp from 'Components/EditContentComp'
import UploadStatusBar from 'Components/UploadStatusBar'

import { uploadFile } from '../../Helper'
import { creatingStates } from '../../Constants'
import { getContentReq, editContentReq } from '../../Api'

const EditContent = () => {
  const { contentID } = useParams()
  const navigate = useNavigate()
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })
  const { isCreating, creatingStage } = creating

  const [thumbnailUploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnail, setThumbnail] = useState(null)
  const [content, setContent] = useState(null)
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [formData, setFormData] = useState({
    description: '',
    title: '',
    type: '',
    _id: '',
  })

  const handelInput = (e) => {
    setIsDisabledBtn(false)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const { description, title, _id, type } = formData

  const getContent = async() => {
    try {
      setIsLoading(true)
      const { data } = await getContentReq(contentID)
      setFormData(data.data)
      if (data.data.contentThumbnail) setThumbnail(data.data.contentThumbnail)
      if (data.data.contentUrl) setContent(data.data.contentUrl)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getContent()
    // eslint-disable-next-line
  }, [])

  const handleContentSelect = (cnt) => {
    setIsDisabledBtn(false)
    setContent(cnt)
  }

  const handleThumbnailSelect = (img) => {
    setIsDisabledBtn(false)
    setThumbnail(img)
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      let thumbnailUrl = null
      let contentUrl = null

      if (thumbnail && typeof thumbnail !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        })
        thumbnailUrl = await uploadFile(
          thumbnail,
          'IMAGE',
          setThumbnailUploadPercentage,
          30
        )
      } else setThumbnailUploadPercentage(30)

      if (content && typeof content !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_MUSIC,
        })
        contentUrl = await uploadFile(
          content,
          type,
          setContentUploadPercentage,
          50
        )
      } else setContentUploadPercentage(50)

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      })

      await editContentReq(
        {
          title,
          description,
          ...(thumbnailUrl && { contentThumbnail: thumbnailUrl }),
          ...(contentUrl && { contentUrl: contentUrl }),
        },
        contentID
      )
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewContent = () => {
    navigate('/dashboard/upload/view/' + contentID)
  }

  return (
    <>
      {isLoading ? (
        <>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h3 className='m-0'>Edit Content</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          {isCreating ? (
            <UploadStatusBar
              currentStep={creatingStage}
              contentUploadPercentage={contentUploadPercentage}
              thumbnailUploadPercentage={thumbnailUploadPercentage}
              processingWord='Updating'
              processedWord='Updated'
              reset={handleViewContent}
            />
          ) : (
            <EditContentComp
              pageHeading='Edit Content'
              title={title}
              description={description}
              thumbnail={thumbnail}
              setThumbnail={handleThumbnailSelect}
              content={content}
              handelInput={handelInput}
              setContent={handleContentSelect}
              type={type}
              assetType={type}
              showSelectAudio={false}
              editBtnText={'Edit Content'}
              isDisabledBtn={isDisabledBtn}
              _id={_id}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  )
}

export default EditContent
