import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
  
} from 'reactstrap'
import AllImages from 'Views/Dashboard/Views/Images/Views/All images'
const AllImagesModal = ({ showAllImagesModal,setShowAllImagesModal }) => {
  const handelCutModal = () =>{
    setShowAllImagesModal(false)
  }
  return (
    <>
      <Modal
        isOpen={showAllImagesModal}
        size={'xl'}
        className='ImageUpoader-modal'
      >
        <ModalBody>
          <div className="closeButton">
            <button className='closemodal' onClick={handelCutModal}>X</button>
            <AllImages showAllImagesModal={ showAllImagesModal } />
          </div>
        </ModalBody>
      </Modal> 
    
    </>
  )
}

export default AllImagesModal
