import axios from 'axios'

import baseDomain, {
  CouponApi,
  // searchContentAPI
} from '../../../../Constants/apiRoutes'

const subDomain = 'https://ecom-prod-be.theyogainstitute.org/v1'

export const creatCouponReq = (data) => {
  return axios.post(`${subDomain}${CouponApi}`, data)
}

export const getPublishedCouponReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${CouponApi}/publishedcoupon?page=${page}&limit=${limit}`)
}

export const getUnpublishedCouponReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${CouponApi}/unpublishedcoupon?page=${page}&limit=${limit}`)
}

export const UnpublishCoupon = (couponID) => {
  return axios.put(`${subDomain}${CouponApi}/unpublish/${couponID}`)
}

export const publishCoupon = (couponID) => {
  return axios.put(`${subDomain}${CouponApi}/publish/${couponID}`)
}

export const getCouponReq = (couponID) => {
  return axios.get(`${subDomain}${CouponApi}/${couponID}`)
}

export const editCouponReq = (data, couponID) => {
  return axios.patch(`${subDomain}${CouponApi}/update/${couponID}`, data)
}