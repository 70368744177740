import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Progress, Button } from 'reactstrap'

const UploadBannerStatusBar = ({
  currentStep,
  thumbnailUploadPercentage,
  contentUploadPercentage,
  reset,
  showSelectAudio = true,
  showThumbnailUpload = true,
  processingWord = 'Creating',
  processedWord = 'Created',

}) => {
  const naviagte = useNavigate()
  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>

            {showThumbnailUpload && (
              <div className='d-flex align-items-center my-3 '>
                <div>
                  {currentStep !== 1 && (
                    <i
                      className='ri-checkbox-line text-secondary'
                      style={{ fontSize: '20px' }}
                    />
                  )}
                </div>
                <p className='card-title-desc my-0 mx-2 text-secondary'>
                  {currentStep !== 1
                    ? 'Banner Created'
                    : 'Creating Banner...'}
                </p>
              </div>
            )}
            <div>
              <Progress multi style={{ height: '24px' }}>
                <Progress
                  bar
                  color='secondary'
                  value={thumbnailUploadPercentage}
                ></Progress>
              </Progress>
            </div>
            {currentStep === 2 && (
              <Row className='mt-4 d-flex  gap-2'>
                {showThumbnailUpload && (
                  <Col xl={12}>
                    <Button
                      color='primary'
                      className='waves-effect waves-light w-100'
                      onClick={reset}
                    >
                      Add More Banner Image
                    </Button>
                  </Col>
                )}
                <Col xl={12}>
                  <Button
                    color='dark'
                    className='waves-effect waves-light w-100'
                    onClick={() => naviagte('/dashboard/banner/all')}
                  >
                    Got to all Banner Image
                  </Button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default UploadBannerStatusBar
