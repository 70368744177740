const cmsBaseDomain = 'https://cms-prod-be.theyogainstitute.org/v1'
const subDomain = 'seometatags'
import axios from 'axios'

export const createMetaData = (payload)=>{
  return axios.post(`${cmsBaseDomain}/${subDomain}`,payload)
}

export const getMetaData = (page)=>{
  return axios.get(`${cmsBaseDomain}/${subDomain}?pagePath=${page}`)
}

export const editMetaData = (page,payload)=>{
  return axios.put(`${cmsBaseDomain}/${subDomain}/${page}`,payload)
}

export const getAllMetaData = ({ page, limit, objectType }) =>{
  return axios.get(`${cmsBaseDomain}/${subDomain}/paginated/?page=${page}&limit=${limit}&objectType=${objectType}`)
}
