import AllUser from '../Views/AllUser/index'
import CreateUser from '../Views/CreateUser/index'
import EnrolledUsers from '../Views/EnrolledUsers'


const UsersRoutes = [
  {
    Component: AllUser,
    path: '/all',
    exact: true,
    id: 'dashboardUsersAll',
    routePath: '/dashboard/users/all',
    name: 'All Users',
  },
  {
    Component: CreateUser,
    path: '/create',
    exact: true,
    id: 'dashboardProductsCreate',
    routePath: '/dashboard/users/create',
    name: 'Create User',
  },
  {
    Component: EnrolledUsers,
    path: '/enrolled-users',
    exact: true,
    id: 'dashboardusersEnrolledUsers',
    routePath: '/dashboard/users/enrolled-users',
    name: 'Enrolled Users',
  },
]

export default UsersRoutes