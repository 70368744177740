import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import CourseCard from 'Components/courseCard'
import UnpublishedCourses from 'Components/unpublishedCourses'
import { PublishedCourseTableHeading, UnPublishedCourseTableHeading } from '../Constants'
import { deleteCourseReq, getPublishedCourse, getUnpublishedCourse, publishCourse, unPublishCourse } from '../../Courses.Api'

const AllCourse = () => {
  const [publishedCourses, setPublishedCourses] = useState([])
  const [unPublishedCourses, setUnPublishedCourses] = useState([])
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPublishAlert, setShowPublishAlert] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [publishID, setPublishID] = useState('')
  const [unPublishID, setUnPublishID] = useState('')
  const [deleteID, setDeleteID] = useState('')
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue


  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  })
  const { unpublishedCurrentPage, unpublishedTotalSize } = unpublishedPaginationValue

  const navigate = useNavigate()

  const handlePublishCourseData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getPublishedCourse(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setPublishedCourses(data.data)
      
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleUnPublishCourseData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUnpublishedCourse(queryParams)
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      })
      setUnPublishedCourses(data.data)
      
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlePublishCourseData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleUnPublishCourseData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])


  const handleViewCourse = (courseID) => {
    navigate('/dashboard/course/view/' + courseID)
  }

  const handleDeleteCourse = (courseID) => {
    console.log(courseID)
    setDeleteID(courseID)
    setShowDeleteAlert(true)
  }

  const handleEditCourse = (courseID) => {
    console.log('/dashboard/course/edit/' + courseID)
    navigate('/dashboard/course/edit/' + courseID)
  }

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishCourseData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }


  const handleUnPublishCourseClick = async(courseID) => {
    setUnPublishID(courseID)
    setShowUnPublishAlert(true)
  }

  const handlePublishCourseClick = async(courseID) => {
    setPublishID(courseID)
    setShowPublishAlert(true)
  }

  const publishCourseAction = async() => {
    await publishCourse(publishID)
    const filteredCourses = publishedCourses.filter(({ _id }) => _id !== publishID)
    setPublishedCourses(filteredCourses)
    setPublishID('')
    setShowPublishAlert(false)
    setShowPublishConfirm(true)
  }

  const unPublishCourseAction = async() => {
    await unPublishCourse(unPublishID)
    const filteredCoursess = unPublishedCourses.filter(({ _id }) => _id !== publishID)
    setUnPublishedCourses(filteredCoursess)
    setUnPublishID('')
    setShowUnPublishAlert(false)
    setShowUnPublishConfirm(true)
  }

  const deleteCourseAction = async() => {
    await deleteCourseReq(deleteID)
    const filteredCoursess = unPublishedCourses.filter(({ _id }) => _id !== publishID)
    setUnPublishedCourses(filteredCoursess)
    setDeleteID('')
    setShowDeleteAlert(false)
    setShowDeleteConfirm(true)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <h2 style={{ margin: '0px' }}>All Courses</h2>
      </div>
      <CourseCard
        tableData={publishedCourses}
        tableHeading={PublishedCourseTableHeading}
        handleViewCourse={handleViewCourse}
        handleEditCourse={handleEditCourse}
        handlePublishCourse={handlePublishCourseClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />


      <UnpublishedCourses 
        tableData={unPublishedCourses}
        tableHeading={UnPublishedCourseTableHeading}
        handleViewCourse={handleViewCourse}
        handleEditCourse={handleEditCourse}
        handleDeleteCourse={handleDeleteCourse}
        handleUnPublishCourse={handleUnPublishCourseClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        totalSize={unpublishedTotalSize}
        isLoading={isLoading}
      />


      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishCourseAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowPublishAlert(false)
            setShowPublishConfirm(false)
            getPublishedCourse({ page: 1, limit: 10 })
            window.location.reload()
          }}
        >
          Course has been Unpublished
        </SweetAlert>
      ) : null}


      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={unPublishCourseAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            window.location.reload()
            // getCreatedMusicData({ page: 1, limit: 10 })
          }}
        >
          Course has been published
        </SweetAlert>
      ) : null}


      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteCourseAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false)
            setShowDeleteConfirm(false)
            window.location.reload()
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
    </>
  )
}

export default AllCourse
