import { useEffect, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { createTagReq, createCategoryReq, getTagByIdReq } from './Api'

const CreateTag = ({ isOpen, toggleModal }) => {
  const [categoryId, setCategoryId] = useState('')
  const [tagId, setTagId] = useState('')
  const [dataTags, setDataTags] = useState([])
  const [tagForm, setTagForm] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    gstPercentage: '',
  })
  const { name, description, gstPercentage } = formData

  const [tagFormData, setTagFormData] = useState({
    tagName: '',
    tagDescription: '',
  })
  const { tagName, tagDescription } = tagFormData

  const [isCreating, setIsCreating] = useState(false)

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: 'success',
    alertText: 'Created Sucessfully',
  })
  const { isAlert, alertType, alertText } = alert

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handelInputTag = (e) => {
    setTagFormData({
      ...tagFormData,
      [e.target.name]: e.target.value,
    })
  }

  const handleClose = () => {
    setFormData({
      name: '',
      description: '',
      gstPercentage: '',
    })
    setTagFormData({
      tagName: '',
      tagDescription: '',
    })
    setTagForm(false)
    setAlert({
      isAlert: false,
      alertType: 'success',
      alertText: 'Created Sucessfully',
    })
    toggleModal()
  }

  const handelCreateCategory = async() => {
    setIsCreating(true)
    try {
      await createCategoryReq({
        name,
        description,
        gstPercentage,
      }).then((response) => {
        setCategoryId(response.data.data._id)
      })
      setAlert({
        isAlert: true,
        alertType: 'success',
        alertText: 'Created Sucessfully',
      })

      setTimeout(() => {
        setAlert({ isAlert: false })
        setTagForm(true)
        // setFormData({
        //   name: '',
        //   description: '',
        // })
        // setIsCreating(false)
        // toggleModal()
      }, 1000)
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: 'danger',
        alertText:
          (error && error.response.data && error.response.data.error) ||
          'Unable to Create',
      })
      setIsCreating(false)
    }
  }

  const getTagsData = async(categoryID) => {
    try {
      const { data } = await getTagByIdReq(categoryID)
      setDataTags(data.data.tags)
    } catch (error) {
      console.error(error)
    }
  }

  const handelCreateTag = async() => {
    try {
      await createTagReq({
        name: tagName,
        description: tagDescription,
        category: categoryId,
      }).then((response) => {
        setTagId(response.data.data._id)
      })
      getTagsData(categoryId)
      setAlert({
        isAlert: true,
        alertType: 'success',
        alertText: 'Created Sucessfully',
      })
      setTimeout(() => {
        setAlert({ isAlert: false })
        setTagFormData({
          tagName: '',
          tagDescription: '',
        })
        setAlert({
          isAlert: false,
          alertType: 'success',
          alertText: 'Created Sucessfully',
        })
      }, 1000)
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: 'danger',
        alertText:
          (error && error.response.data && error.response.data.error) ||
          'Unable to Create',
      })
      setIsCreating(false)
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Create Tags & Category</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">Category Name</Label>
                <AvField
                  name="name"
                  placeholder="Enter Category Name"
                  type="text"
                  errorMessage="Enter Category Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={name}
                  onChange={handelInput}
                  id="name"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">Category Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter Category Description"
                  type="text"
                  errorMessage="Enter Category Description"
                  className="form-control"
                  value={description}
                  onChange={handelInput}
                  validate={{ required: { value: true } }}
                  id="description"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="gstPercentage">Category GST (In %)</Label>
                <AvField
                  name="gstPercentage"
                  placeholder="Enter Category gst"
                  type="text"
                  // errorMessage="Enter Category gst"
                  className="form-control"
                  value={gstPercentage}
                  onChange={handelInput}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Enter Category GST',
                    },
                    max: {
                      value: 100,
                      errorMessage: 'GST must be between 0 and 100 characters',
                    },
                  }}
                  id="gstPercentage"
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <Button
                type="button"
                onClick={handelCreateCategory}
                color="success"
                className="waves-effect"
                hidden={tagForm}
              >
                Create Category
              </Button>
            </Col>
          </Row>

          {tagForm && (
            <Row className="mt-4">
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="tagName">Tag Name</Label>
                  <AvField
                    name="tagName"
                    placeholder="Enter Tag Name"
                    type="text"
                    errorMessage="Enter Tag Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    value={tagName}
                    onChange={handelInputTag}
                    id="tagName"
                    // disabled={isCreating}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="tagDescription">Tag Description</Label>
                  <AvField
                    name="tagDescription"
                    placeholder="Enter Tag Description"
                    type="text"
                    errorMessage="Enter Tag Description"
                    className="form-control"
                    value={tagDescription}
                    onChange={handelInputTag}
                    validate={{ required: { value: true } }}
                    id="tagDescription"
                    // disabled={isCreating}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <Button
                  type="button"
                  onClick={handelCreateTag}
                  color="success"
                  className="waves-effect"
                >
                  Create Tag
                </Button>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Tags Created:</Label>
                  {dataTags.map((data, index) => (
                    <p key={index}>
                      {index + 1}. {data.name}
                    </p>
                  ))}
                </FormGroup>
              </Col>
            </Row>
          )}

          {isAlert && (
            <Alert className="mt-2" color={alertType} role="alert">
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

export default CreateTag
