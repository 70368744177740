import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getBlogReq } from '../../Api'
import './style.scss'
const ViewBlog = () => {
  const [blog, setBlog] = useState({})
  const { blogId } = useParams()
  const getBlog = async() => {
    try {
      const { data } = await getBlogReq(blogId)
      setBlog(data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getBlog()
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {Object.keys(blog).length === 0 ? (
        <div className="global-loader">Loading...</div>
      ) : (
        <div className="blog-container" id="blog-container">
          <h2 dangerouslySetInnerHTML={{ __html: `${blog?.title}` }}></h2>
          <br />
          {blog?.coverImage && (
            <div className="blog_img">
              <img src={`${blog?.coverImage}`} alt={`${blog?.atlTag}`} />
            </div>
          )}
          <div
            className="blog-grid"
            dangerouslySetInnerHTML={{ __html: `${blog?.content}` }}
          ></div>
        </div>
      )}
    </>
  )
}
export default ViewBlog