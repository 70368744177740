import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import SectionForm from './SectionForm'

const CreateCourse = ({
  pageHeading = 'Create Course',
  handelInput,
  title,
  description,
  handleSubmit,
  isFree,
  isDisabledBtn = false,
  createBtnText = 'Create Course',
}) => {

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                 
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
                <Col className="d-flex align-items-center mt-3" xs={2}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="isFree"
                      checked={isFree}
                      onClick={handelInput}
                      id="free"
                    />
                    <Label className="custom-control-label" htmlFor="free">
                      Is Free
                    </Label>
                  </div>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handelInput}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="description"
                />
              </FormGroup>
              <Row>
                <SectionForm />
              </Row>
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CreateCourse
