export const PublishedContentTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Music Name',
    dataField: 'title',
  },
  {
    text: 'Description',
    dataField: 'description',
  },
]

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
}