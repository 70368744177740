import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
  
} from 'reactstrap'
import { useState } from 'react'
import ImageUploads from 'Views/Dashboard/Views/Images/Views/Uploads'
import './index.scss'

const ImageModal = ({ showModal,setShowModal }) => {
  
  const handelCloseModal = () =>{
    setShowModal(false)
  }
  return (
    <>
      <Modal
        isOpen={showModal}
        size={'xl'}
        className='ImageUpoader-modal'
      >
        <ModalBody>
          <div className="closeButton">
            <button className='closemodal' onClick={handelCloseModal}>X</button>
            <ImageUploads showModal={ showModal } />
          </div>
        </ModalBody>
      </Modal>
      
    </>
   


  )
}

export default ImageModal
