import DeletedCourse from 'Components/DeleteCourse'
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useNavigate } from 'react-router-dom'
import { getAllCourseBinReq, restoreCourseReq } from '../../Courses.Api'
import { binCourseTableHeading } from '../Constants'

const CourseBin = () => {
  const [course, setCourse] = useState([])
  const [showRestoreAlert, setShowRestoreAlert] = useState(false)
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false)
  const [restoreCourseID, setRestoreCourseID] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  })
  const { currentPage, totalSize } = paginationValue

  const navigate = useNavigate()

  const getCourseBinData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getAllCourseBinReq(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setCourse(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getCourseBinData({ currentPage: 1, totalSize: 10 })
    // eslint-disable-next-line
  }, [])

  const handleViewCourseClick = (courseID) => {
    navigate('/dashboard/Tags/view/' + courseID)
  }

  const handleRestoreCourseClick = (courseID) => {
    setRestoreCourseID(courseID)
    setShowRestoreAlert(true)
  }

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getCourseBinData({ page, totalSize: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const restoreCourseAction = async() => {
    await restoreCourseReq(restoreCourseID)
    const filteredCourse = course.filter(({ _id }) => _id !== restoreCourseID)
    setCourse(filteredCourse)
    setRestoreCourseID('')
    setShowRestoreAlert(false)
    setShowRestoreConfirm(true)
  }
  return (
    <>
      <DeletedCourse
        tableData={course}
        tableHeading={binCourseTableHeading}
        handleViewCourse={handleViewCourseClick}
        handleRestoreCourse={handleRestoreCourseClick}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore Course!"
          reverseButtons={true}
          onConfirm={restoreCourseAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Course Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false)
            setShowRestoreConfirm(false)
          }}
        >
          Course has been Restored.
        </SweetAlert>
      ) : null}
    </>
  )
}

export default CourseBin
