export const CreateTeacherTableHeading = [
  {
    text: 'Name',
    dataField: 'firstName',
  },
  {
    text: 'Email',
    dataField: 'email',
  }
]

