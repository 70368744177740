import CreatedPage from 'Components/PageForm'
import UploadStatusBar from 'Components/UploadStatusBar'
import { useState } from 'react'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/Helper'
import { creatingStates } from '../../Contants'
import { creatPageReq } from '../../Page.Apis'

const CreatePage = () => {

  const [pageImage, setPageImage] = useState(null)


  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: '',
    menuName: '',
    pageBaseUrl : '',
    pageElement:'',
    pageCategory:''
  })

  const { name, description, type, menuName, pageBaseUrl, pageElement, pageCategory } = formData



  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  // Create Page
  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      if (!pageImage)
        return setError({
          isError: true,
          errorMessage: 'Please select thumbnail and Audio',
          errorType: 'warning',
        })

      const pageImageUrl = await uploadFile(
        pageImage,
        'IMAGE',
        50
      )
  
      
      await creatPageReq(
        {
          name,
          description,
          type,
          menuName,
          pageBaseUrl,
          pageElement,
          pageCategory,
          productImage: pageImageUrl,
        }
      )
    
    } catch (error) {
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }

  }

  const reset = () => {
    setPageImage(null)
    setError({
      isError: false,
      errorMessage: '',
      errorType: 'warning',
    })
    setFormData({
      name: '',
      description: '',
      type: '',
      menuName: '',
      pageBaseUrl : '',
      pageElement:'',
      pageCategory:''
    })
  }

  return (
    <>
      <CreatedPage
        handelInput={handelInput}
        name={name}
        description={description}
        menuName={menuName}
        pageBaseUrl={pageBaseUrl}
        pageCategory={pageCategory}
        pageElement={pageElement}
        pageImage={pageImage}
        setPageImage={setPageImage}
        handleSubmit={handleSubmit}
        isError={isError}
        errorMessage={errorMessage}
        errorType={errorType}
      />
    </>
  )
}

export default CreatePage
