import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import FileUploader from '../FileUploader'

const ContentForm = ({
  pageHeading = 'Create Content',
  handelInput,
  title,
  description,
  contentType,
  audio,
  setAudio,
  handleSubmit,
  isError,
  errorMessage,
  errorType,
  isDisabledBtn = false,
  createBtnText = 'Create Content',
  thumbnail,
  setThumbnail
}) => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                  {' '}
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handelInput}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="description"
                />
              </FormGroup>

              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="contentTypeSelect">Content Type</Label>
                    <Input
                      name="contentType"
                      placeholder="Select Content Type"
                      type="select"
                      className="form-control"
                      value={contentType}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="contentTypeSelect"
                    >
                      <option value="" disabled={true}>Select Content Type</option>
                      <option value="PDF">PDF</option>
                      <option value="AUDIO">Audio</option>
                      <option value="IMAGE">Image</option>
                      <option value="VIDEO">Video</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                <Row>
                  {contentType === 'AUDIO' && (
                    <Col lg={6}>
                      <FileUploader
                        file={audio}
                        setFile={setAudio}
                        dragText="Drop track audio here"
                        heading="Select Track Audio"
                        accept=".mp3,audio/*"
                      />
                    </Col>
                  )}
                  {contentType === 'VIDEO' && (
                    <Col lg={6}>
                      <FileUploader
                        file={audio}
                        setFile={setAudio}
                        dragText="Drop track video here"
                        heading="Select Track Video"
                        accept=".mp4,video/*"
                      />
                    </Col>
                  )}
                  {contentType === 'PDF' && (
                    <Col lg={6}>
                      <FileUploader
                        file={audio}
                        setFile={setAudio}
                        dragText="Drop PDF here"
                        heading="Select PDF"
                        accept=".pdf,pdf/*"
                      />
                    </Col>
                  )}
                  {contentType === 'IMAGE' && (
                    <Col lg={6}>
                      <FileUploader
                        file={audio}
                        setFile={setAudio}
                        dragText="Drop Image here"
                        heading="Select Image"
                        accept=".jpeg,jpg/*"
                      />
                    </Col>
                  )}
                  {contentType !== '' && <Col lg={6}>
                    <FileUploader
                      file={thumbnail}
                      setFile={setThumbnail}
                      dragText="Drop Thumbnail here"
                      heading="Select Thumbnail Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    />
                  </Col>}
                </Row>

                {isError && (
                  <Alert color={errorType} role="alert">
                    {errorMessage}
                  </Alert>
                )}

              </AvForm>

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ContentForm
