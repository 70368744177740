import AllEnrollForms from '../Views/All-enroll-form'
// import EditForm from '../Views/EditForm'
// import react from 'react'

export const EnrollFormRoutes = [

  {
    Component : AllEnrollForms,
    path: '/all',
    exact: true,
    id: 'dashboardEnrollFormAll',
    routePath: '/dashboard/enroll-form/all',
    name: 'All enrolled Users'
  },

  // {
  //   Component : EditForm,
  //   path: '/edit/:formId',
  //   exact: true,
  //   id: 'dashboardFormEdit'
  // }
  
]
