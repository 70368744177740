import { Row, Col, Card, CardBody, Label, FormGroup, Button, Input } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

import FileUploader from '../FileUploader'

const ViewProductComp = ({
  pageHeading = 'Product Info',
  name,
  description,
  category,
  price,
  currency,
  discount,
  stockCount,
  productImage1,
  productImage2,
  productImage3,
  productThumbnail,
  bannerImage,
  handleEditProduct,
  _id,
}) => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                  <div style={{ textAlign: 'right', width: '50%' }}>
                    <Button
                      color="primary"
                      className="waves-effect waves-light mb-2"
                      onClick={() => handleEditProduct(_id)}
                    >
                      Edit Product
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row> */}
            <AvForm className="form-horizontal">
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">Prouct Name</Label>
                    <AvField
                      name="name"
                      placeholder="Enter name"
                      disabled
                      value={name}
                      type="text"
                      errorMessage="Name is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="name"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="category">Category</Label>
                    <AvField
                      name="category"
                      placeholder="Enter Category"
                      disabled
                      value={category}
                      type="text"
                      errorMessage="category is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="category"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="stockCount">stock Count</Label>
                    <AvField
                      name="stockCount"
                      placeholder="Enter stockCount"
                      disabled
                      value={stockCount}
                      type="number"
                      errorMessage="stockCount is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="stockCount"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="description">Product Details</Label>
                    <AvField
                      name="description"
                      placeholder="Enter description"
                      disabled
                      value={description}
                      type="text"
                      errorMessage="Description is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="description"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="currencyTypeSelect">Currency</Label>
                    <Input
                      name="currency"
                      disabled
                      placeholder="Select Currency Type"
                      type="select"
                      className="form-control"
                      value={currency}
                      validate={{ required: { value: true } }}
                      id="currencyTypeSelect"
                    >
                      <option value="" disabled={true}>
                        Select Currency Type
                      </option>
                      <option value="USD">USD</option>
                      <option value="INR">INR</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="price">Price</Label>
                    <AvField
                      name="price"
                      placeholder="Enter price"
                      disabled
                      value={price}
                      type="number"
                      errorMessage="price is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="price"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="discount">Discount (In %)</Label>
                    <AvField
                      name="discount"
                      placeholder="Enter discount"
                      disabled
                      value={discount}
                      type="text"
                      errorMessage="discount is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="discount"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FileUploader
                    file={productThumbnail}
                    dragText="Drop Thumbnail here"
                    heading="Thumbnail Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
                <Col>
                  <FileUploader
                    file={bannerImage}
                    dragText="Drop Banner here"
                    heading="Banner Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={productImage1}
                    dragText="Drop Image here"
                    heading="Product Image 1"
                    accept=".jpeg,jpg,png,webp/*"
                  />
                </Col>
                <Col>
                  <FileUploader
                    file={productImage2}
                    dragText="Drop Image here"
                    heading="Product Image 1"
                    accept=".jpeg,jpg,png,webp/*"
                  />
                </Col>
                <Col>
                  <FileUploader
                    file={productImage3}
                    dragText="Drop Image here"
                    heading="Product Image 1"
                    accept=".jpeg,jpg,png,webp/*"
                  />
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ViewProductComp
