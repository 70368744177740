import { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from 'Components/Table'
import CreateShipping from 'Components/CreateShipping'

const IntProductShipping = ({
  handleUpdateShipping,
  intTableHeading,
  intTableData,
  totalSize,
  sizePerPage,
  page,
  handleTablePageChange,
  handleCreateShipping,
}) => {
  const updateShippingFormatter = (cell, row) => {
    return (
      <Button color="primary" onClick={() => handleUpdateShipping(row)}>
        Edit
      </Button>
    )
  }

  const getRange = (weight) => {
    switch (weight) {
    case 500:
      return '0.0 - 0.5 kg'
    case 1000:
      return '0.5 - 1.0 kg'
    case 1500:
      return '1.0 - 1.5 kg'
    case 2000:
      return '1.5 - 2.0 kg'
    case 2500:
      return '2.0 - 2.5 kg'
    case 3000:
      return '2.5 - 3.0 kg'
    case 3500:
      return '3.0 - 3.5 kg'
    case 4000:
      return '3.5 - 4.0 kg'
    case 4500:
      return '4.0 - 4.5 kg'
    case 5000:
      return '4.5 - 5.0 kg'
    case 5500:
      return '5.0 - 5.5 kg'
    case 6000:
      return '5.5 - 6.0 kg'
    }
  }

  const [rowData, setRowData] = useState([])
  console.log(rowData, 'nhiiiiii')
  useEffect(() => {
    if (intTableData) {
      var result = intTableData.map(function(el, i) {
        var o = { ...el, weightRange: getRange(el.weight), 
          rate: el.currency==='INR' ? `₹ ${ el.price }`: `$ ${ el.price }`, 
          currency:el.currency }

        o.sNo = i + 1 + page * 15 - 15
        return o
      })
      setRowData(result)
    }
  }, [intTableData])
  useEffect(() => {
    intTableHeading.push({
      text: 'Edit',
      dataField: 'edit',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: updateShippingFormatter,
    })
    return () => {
      intTableHeading.pop()
    }
  }, [])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Intl. Shipping Charges</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              columns={intTableHeading}
              rows={rowData}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default IntProductShipping
