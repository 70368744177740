import { useState, useEffect } from 'react'

import ContentCard from '../../../../../../Components/ContentCard'

import { PublishedContentTableHeading } from '../../Constants'

import { getAllContentReq } from '../../Api'
import { useNavigate } from 'react-router-dom'

const AllContent = () => {
  const [publishedContents, setPublishedContents] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const navigate = useNavigate()

  const getContentData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getAllContentReq(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setPublishedContents(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getContentData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])


  const handleViewContentClick = (contentID) => {
    navigate('/dashboard/upload/view/' + contentID)
  }

  const handleEditMusic = (contentID) => {
    navigate('/dashboard/upload/edit/' + contentID)
  }

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getContentData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <h2 style={{ margin: '0px' }}>All Content</h2>
      </div>
      <ContentCard
        tableData={publishedContents}
        tableHeading={PublishedContentTableHeading}
        handleViewContent={handleViewContentClick}
        handleEditMusic={handleEditMusic}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />
    </>
  )
}

export default AllContent
