// import Resizer from 'react-image-file-resizer'
import OSS from 'ali-oss'

import { getSecureStsCred, regenerateSecureStsToken, uploadFileS3 } from './Api'

const getOssClient = ({
  securityToken,
  accessKeyId,
  accessKeySecret,
  ossBucketName,
  ossBucketRegion,
}) => {
  const client = new OSS({
    region: ossBucketRegion,
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket: ossBucketName,
    // Refresh the temporary access credential.
    refreshSTSToken: async() => {
      const { data } = await regenerateSecureStsToken()
      return {
        accessKeyId: data.data.accessKeyId,
        accessKeySecret: data.data.accessKeySecret,
        stsToken: data.data.securityToken,
      }
    },
  })

  return client
}

// const resizeFile = (file) =>
//   new Promise((resolve, reject) => {
//     Resizer.imageFileResizer(
//       file,
//       500,
//       500,
//       'JPEG',
//       70,
//       0,
//       (uri) => {
//         resolve(uri)
//       },
//       'file'
//     )
//   })

// export const getAudioDuration = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.onload = (e) => {
//       const audioContext = new (window.AudioContext ||
//         window.webkitAudioContext)()
//       audioContext.decodeAudioData(e.target.result, (buffer) => {
//         const duration = Math.floor(buffer.duration)
//         resolve(duration)
//       })
//     }
//     reader.onerror = (event) => {
//       reject('Error while getting audio')
//     }
//     reader.readAsArrayBuffer(file)
//   })

export const formatForReactSelect = (tagsArray) => {
  const formatedData = tagsArray.map(({ name, _id }) => ({
    label: name,
    value: _id,
    key: _id,
  }))
  return formatedData
}

export const filterTagsAndFormat = (tags = []) => {
  const tagsData = formatForReactSelect(
    tags.filter(({ objectType }) => objectType === 'TAG')
  )
  const categoryData = formatForReactSelect(
    tags.filter(({ objectType }) => objectType === 'CATEGORY')
  )
  const subCategoryData = formatForReactSelect(
    tags.filter(({ objectType }) => objectType === 'SUBCATEGORY')
  )
  return {
    tagsData,
    categoryData,
    subCategoryData,
  }
}

export const formatForRecommendationSelect = (userDataArray) => {
  const userformatedData = userDataArray.map(({ label, _id }) => ({
    label: label,
    value: label,
    key: _id,
  }))
  return userformatedData
}



export const formatMultiSelectData = (data) => {
  const formatedData = data.map(({ value }) => value)
  return formatedData
}

// AUDIO_IMAGE
export const uploadFile = (file, type, onProgress, percentageW) =>
  new Promise(async(resolve, reject) => {
    try {
      let payload = new FormData();
      payload.append('name', file.name)
      payload.append('type', type)
      payload.append('contentType', file.type)
      payload.append('file', file)
      // let payload = { name: file.name, type: type, contentType: file.type, image: await convertToBase64(file) }
      // let uploadFile = file;
      // console.log(payload);
      await uploadFileS3(payload).then(res => {
        resolve(res?.data.url);
      }).catch(err => {
        reject({
          error: true,
          errorMessage: 'Unable to upload data, try again later',
        });
      })
    } catch (error) {
      console.error('General error:', error);
      reject({
        error: true,
        errorMessage: 'Unable to upload data, try again later',
      });
    }
  })


export const formatArray = (arr) => {
  let updatedArray = []
  for (let i in arr) {
    updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] })
  }
  return updatedArray
}
