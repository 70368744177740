import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'
import { useEffect, useState } from 'react'
import { searchCategory, searchProduct } from 'Views/Dashboard/Views/Products/Api'

const CreatedPage = ({
  pageHeading = 'Create Page',
  handelInput,
  name,
  type,
  description,
  menuName,
  pageBaseUrl,
  pageImage,
  setPageImage,
  pageCategory,
  pageElement,  
  handleSubmit,
  isDisabledBtn = false,
  createBtnText = 'Create Page',

}) => {

  const [currentSearch, setCurrentSearch] = useState([])
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')


  // const getSearchData = async(text) => {
  //   try {
  //     const { data } = await searchCategory(text)
  //     data.data ? setCurrentSearch(data.data) : setCurrentSearch([])
  //   } catch (error) {
  //     setCurrentSearch([])
  //   }
  // }

  // const handleChange = (e) => {
  //   setSearch(e.target.value)
  //   setSelectedCategory({
  //     name: '',
  //     _id: '',
  //   })

  //   let searchBar = e.target.value
  //   getSearchData(searchBar)
  // }


  // useEffect(() => {
  //   search !== '' ? setShow(true) : setShow(false)
  // }, [search])

  // const hideComponent = () => {
  //   setTimeout(() => {
  //     setShow(false)
  //   }, 1000)
  // }

  // const handleTagInfo = (item) => {
  //   setSearch(item.name)
  //   setSelectedCategory({
  //     name: item.name,
  //     _id: item._id,
  //   })
  //   setShow(false)
  // }

  const selectTag = {
    marginTop: '10px',
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px',
    width: '100%',
    height: '240px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    zIndex: '9',
  }

  const selectSearchItem = {
    fontSize: '0.9rem',
    fontWeight: '500',
    lineHeight: '1.23',
    textAlign: 'left',
    color: '#505050',
    padding: '1rem 0',
    borderBottom: '1px solid #d3d3d3',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='name'>Page Name</Label>
                    <AvField
                      name='name'
                      placeholder='Enter name'
                      value={name}
                      onChange={handelInput}
                      type='text'
                      errorMessage='Name is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='name'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="type">Page Type</Label>
                    <Input
                      name="type"
                      placeholder="Select Content Type"
                      type="select"
                      className="form-control"
                      value={type}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="type"
                    >
                      <option value="" selected disabled={true}>
                        Select Page Type
                      </option>
                      <option value="CATEGORY">CATEGORY</option>
                      <option value="CUSTOM">CUSTOM</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col>
                  <FormGroup>
                    <Label htmlFor='categoryTypeSelect'>Category Type</Label>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ position: 'relative', width: '100%' }}>
                        <input
                          name='search'
                          value={search}
                          placeholder='Search Categories'
                          style={{
                            width: '100%',
                            backgroundColor: 'transparent',
                            outline: 'none',
                            border: 'none',
                            borderBottom: '1px solid black',
                            padding: '5px',
                          }}
                          onChange={handleChange}
                          onBlur={hideComponent}
                          autoComplete='off'
                        />
                        {show === true && (
                          <>
                            <div style={selectTag}>
                              {currentSearch && currentSearch.length === 0 ? (
                                <div>Not found</div>
                              ) : (
                                currentSearch &&
                                currentSearch.map((item, i) => {
                                  return (
                                    <div
                                      style={selectSearchItem}
                                      key={i}
                                      onClick={() => handleTagInfo(item)}
                                    >
                                      <div>{item ? item.name : '-'}</div>
                                    </div>
                                  )
                                })
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col> */}
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='menuName'>Menu Name</Label>
                    <AvField
                      name='menuName'
                      placeholder='Enter menuName'
                      value={menuName}
                      onChange={handelInput}
                      type='text'
                      errorMessage='menuName is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='menuName'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='description'>Page Details</Label>
                    <AvField
                      name='description'
                      placeholder='Enter description'
                      value={description}
                      onChange={handelInput}
                      type='text'
                      errorMessage='Description is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='description'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='pageBaseUrl'>Page Base URL</Label>
                    <AvField
                      name='pageBaseUrl'
                      placeholder='Enter pageBaseUrl'
                      value={pageBaseUrl}
                      onChange={handelInput}
                      type='text'
                      errorMessage='pageBaseUrl is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='pageBaseUrl'
                    />
                  </FormGroup>
                </Col>
               
              </Row>

              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor='pageCategory'>Page Category</Label>
                    <AvField
                      name='pageCategory'
                      placeholder='Enter pageCategory'
                      value={pageCategory}
                      onChange={handelInput}
                      type='text'
                      errorMessage='pageCategory is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='pageCategory'
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor='pageElement'>Page Element</Label>
                    <AvField
                      name='pageElement'
                      placeholder='Enter pageElement'
                      value={pageElement}
                      onChange={handelInput}
                      type='text'
                      errorMessage='pageElement is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='pageElement'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FileUploader
                    file={pageImage}
                    setFile={setPageImage}
                    dragText='Drop Image here'
                    heading='Select Image (In Jpeg)'
                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                  />
                </Col>
              </Row>

              <Button
                color='primary'
                className='w-100 mt-4'
                type='submit'
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CreatedPage
