import { Row, Col, Card, CardBody, Label, FormGroup, Button } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import ReactPlayer from 'react-player'

import FileUploader from '../FileUploader'

const ViewContentComp = ({
  pageHeading = 'Content Info',
  title,
  description,
  _id,
  thumbnail,
  content,
  type,
  handleEditContent,
}) => {
  const handlePdfClick = () => {
    window.open(content)
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                  <div style={{ textAlign: 'right', width: '50%' }}>
                    <Button
                      color="primary"
                      className="waves-effect waves-light mb-2"
                      onClick={() => handleEditContent(_id)}
                    >
                      Edit Content
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal">
              <Row>
                <Col xs={10}>
                  {' '}
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      disabled
                      value={title}
                      className="form-control"
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  disabled
                  value={description}
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="description">Content Type</Label>
                    <AvField
                      name="description"
                      placeholder="Enter description"
                      disabled
                      value={type}
                      className="form-control"
                      id="description"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                {type === 'AUDIO' && (
                  <Col lg={12}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Play Audio</Label>
                      <ReactPlayer
                        url={content}
                        width="100%"
                        height="50px"
                        playing={false}
                        controls={true}
                      />
                    </FormGroup>
                  </Col>
                )}

                {type === 'VIDEO' && (
                  <Col lg={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Play Video</Label>
                      <ReactPlayer
                        url={content}
                        controls={true}
                        config={{
                          file: {
                            attributes: {
                              controlsList:
                                'nodownload noplaybackrate noprogress',
                              disablePictureInPicture: 'true',
                            },
                          },
                        }}
                        width={'99%'}
                        height={'99%'}
                      />
                    </FormGroup>
                  </Col>
                )}

                {type === 'PDF' && (
                  <Col lg={12}>
                    <FormGroup className="select2-container">
                      <Button
                        className="w-100 my-3"
                        color="primary"
                        onClick={handlePdfClick}
                      >
                        View PDF
                      </Button>
                    </FormGroup>
                  </Col>
                )}

                {thumbnail && (
                  <Col lg={type === 'VIDEO' ? 6 : 12}>
                    <div style={{ marginTop: '-15px' }}>
                      <FileUploader
                        file={thumbnail}
                        dragText="Drop content image here"
                        heading="Content Thimbnail"
                        accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                        isDisabled={true}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ViewContentComp
