import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'

const CouponForm = ({
  pageHeading = 'Create Coupon',
  handelInput,
  couponType,
  couponCode,
  discountType,
  couponDiscount,
  numberOfUsers,
  expiryTime,
  handleSubmit,
  isDisabledBtn = false,
  createBtnText = 'Create Coupon',
}) => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="couponTypeSelect">Coupon Type</Label>
                    <Input
                      name="couponType"
                      placeholder="Select Content Type"
                      type="select"
                      className="form-control"
                      value={couponType}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="couponTypeSelect"
                    >
                      <option value="" disabled={true}>
                        Select Coupon Type
                      </option>
                      <option value="CART" selected>CART</option>
                      {/* <option value="PRODUCT">PRODUCT</option> */}
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col>
                  <FormGroup>
                    <Label htmlFor="categoryTypeSelect">Category Type</Label>
                    <Input
                      name="category"
                      placeholder="Select Content Type"
                      type="select"
                      className="form-control"
                      value={category}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="categoryTypeSelect"
                    >
                      <option value="" disabled={true}>
                        Select Category Type
                      </option>
                      <option value="BOOKS">BOOKS</option>
                      <option value="T_SHIRTS">T_SHIRTS</option>
                      <option value="MATS">MATS</option>
                      <option value="BAGS">BAGS</option>
                      <option value="SNACKS">SNACKS</option>
                      <option value="OTHERS">OTHERS</option>
                    </Input>
                  </FormGroup>
                </Col>                  */}
                <Col>
                  <FormGroup>
                    <Label htmlFor="couponCode">Coupon Code</Label>
                    <AvField
                      name="couponCode"
                      placeholder="Enter couponCode"
                      value={couponCode}
                      onChange={handelInput}
                      type="text"
                      errorMessage="couponCode is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="couponCode"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
               
                <Col>
                  <FormGroup>
                    <Label htmlFor="discountTypeSelect">Discount Type</Label>
                    <Input
                      name="discountType"
                      placeholder="Select Content Type"
                      type="select"
                      className="form-control"
                      value={discountType}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="discountTypeSelect"
                    >
                      <option value="" disabled={true}>
                        Select Discount Type
                      </option>
                      <option value="PERCENTAGE">PERCENTAGE</option>
                      <option value="AMOUNT">AMOUNT</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="couponDiscount">Coupon Discount( In ₹ or % )</Label>
                    <AvField
                      name="couponDiscount"
                      placeholder="Enter couponDiscount"
                      value={couponDiscount}
                      onChange={handelInput}
                      type="number"
                      errorMessage="couponDiscount is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="couponDiscount"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
               

                <Col>
                  <FormGroup>
                    <Label htmlFor="numberOfUsers">Number Of Users</Label>
                    <AvField
                      name="numberOfUsers"
                      placeholder="Enter numberOfUsers"
                      value={numberOfUsers}
                      onChange={handelInput}
                      type="text"
                      errorMessage="numberOfUsers is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="numberOfUsers"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="expiryTime">Coupon Expiry (In Days)</Label>
                    <AvField
                      name="expiryTime"
                      placeholder="Enter expiryTime"
                      value={expiryTime}
                      onChange={handelInput}
                      type="number"
                      errorMessage="expiryTime is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="expiryTime"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CouponForm
