import { Fragment } from 'react'
import { Row, Col, Container } from 'reactstrap'
import LmsLogo from 'assets/images/LmsLogo.png'

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            {/* <Col sm={6}>{new Date().getFullYear()}© NexgenIot Solutions.</Col> */}
            {/* to do: change the text style */}
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block pb-4">
                <img
                  className="lms-logo"
                  width="80px"
                  height="20px"
                  src={LmsLogo}
                  alt="LMS"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  )
}

export default Footer
