import AllProducts from '../Views/AllProducts'
import CreateProducts from '../Views/CreateProducts'
import EditProduct from '../Views/EditProduct'
import ViewProduct from '../Views/ViewProduct'
import ProductsBin from '../Views/ProductsBin'
import Shipping from '../Views/Shipping'
import ViewShipping from '../Views/ViewShipping'

const ProductsRoutes = [
  {
    Component: AllProducts,
    path: '/all',
    exact: true,
    id: 'dashboardProductsAll',
    routePath: '/dashboard/Products/all',
    name: 'All Products',
  },
  {
    Component: CreateProducts,
    path: '/create',
    exact: true,
    id: 'dashboardProductsCreate',
    routePath: '/dashboard/Products/create',
    name: 'Create Product',
  },
  {
    Component: ProductsBin,
    path: '/bin',
    exact: true,
    id: 'dashboardProductsBin',
    routePath: '/dashboard/Products/bin',
    name: 'Product Bin',
  },
  {
    Component: EditProduct,
    path: '/edit/:productID',
    exact: true,
    id: 'dashboardProductEdit',
  },
  {
    Component: ViewProduct,
    path: '/view/:productID',
    exact: true,
    id: 'dashboarProducttView',
  },
  {
    Component: Shipping,
    path: '/shipping',
    exact: true,
    id: 'dashboardProductsBin',
    routePath: '/dashboard/Products/shipping',
    name: 'Shipping',
  },
  {
    Component: ViewShipping,
    path: '/update/:shippingID',
    exact: true,
    id: 'dashboarshippingView',
  },
]

export default ProductsRoutes
