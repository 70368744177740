import { useEffect, useState } from 'react'
import { 
  Row, 
  Col,   
  Modal,
  ModalBody,
  FormGroup, 
  Label, 
  Input, 
  Button } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'

const UpdateOrderStatus = ({
  productData,
  orderId,
  modal,
  setModal,
  updateOrderStatus
}) => {
  const [ trackingId,setTrackingId ] = useState()
  const [ deliveryPartner, setDeliveryPartner ] = useState()
  const [ deliveryStatus, setDeliveryStatus ] = useState()
  const [showAlert,setShowAlert] = useState(false)

  const handleClose = () => {
    setModal(false)
  }

  const [errorID, setErrorID]=useState(0)


  const hanndleSave = ()=>{
    if(trackingId==='')
    {
      setErrorID(1)
    }
    else if(deliveryPartner ==='' ){
      console.log('not working')
      setErrorID(2)
    }else{
      updateOrderStatus(orderId,productData._id,{
        deliveryStatus:deliveryStatus,
        trackingId:trackingId,
        deliveryPartner:deliveryPartner
      }).then(()=> {
        setShowAlert(true)
      })
    }
  }
  const handlePopups = () => {
    setShowAlert(false)
    setModal(false)
  }

  useEffect(()=>{
    setDeliveryPartner(productData.deliveryPartner)
    setDeliveryStatus(productData.deliveryStatus )
    setTrackingId(productData.trackingId)

  },[ productData ])

  console.log(trackingId,deliveryStatus,deliveryPartner,productData)

  return <>
    <Modal size='lg' isOpen={modal} className='updateOrder-modal modal-dialog modal-dialog-centered'>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor='status'>Status</Label>
              <Input
                name="status"
                placeholder="Update Status"
                type="select"
                className="form-control"
                value={ deliveryStatus }
                onChange={ (e)=>{ setDeliveryStatus(e.target.value) } }
                validate={{ required: { value: true } }}
                id="order_status"
              >
                <option value="ORDER_PLACED" >
                  Order Placed
                </option>
                <option value='SHIPPED' >Shipped</option>
                <option value='OUT_FOR_DELIVERY' >Out for delivery</option>
                <option value='DELIVERED' >Deliverd</option>
                <option value='CANCELLED' >Cancelled</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor=''>Tracking ID</Label>
              <Input
                name="tarckingId"
                placeholder="Add order info"
                type="text"
                className="form-control"
                value={trackingId}
                onChange={
                  (e) => { setTrackingId(e.target.value) }}
                validate={{ required: { value: true } }}
                errorMessage = 'Tracking id is required'
                id="tarckingId"
                maxLength='100'
              />
              <small style={{ float: 'right' }} >Max length allowed is 100 characters</small>
              {errorID===1 && <small style={{ fontSize:'12px' , color:'red' }}>Fill Tracking Id</small>}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label htmlFor='merchant'>Delivery Partner</Label>
              <Input
                name="merchant"
                placeholder="Add Delivery Merchant"
                type="text"
                className="form-control"
                value={deliveryPartner}
                onChange={(e) => { setDeliveryPartner(e.target.value) }}
                validate={{ required: { value: true } }}
                id="merchant"
              />
              {errorID===2 && <small style={{ fontSize:'12px' , color:'red' }}>Fill Deliver Partner</small>}
            </FormGroup>
          </Col>
          <Row>
            <Col className='d-flex justify-content-end gap-3'>
              <Button className='px-5 mt-4' color='light' onClick={handleClose}>Close</Button>
              <Button className='px-5 mt-4' color='primary' onClick={hanndleSave} >Save</Button>
            </Col>
          </Row>
        </Row>
      </ModalBody>
      {showAlert && <SweetAlert
        success
        title="Order save successfully"
        confirmBtnText="Okay"
        onConfirm={() => handlePopups()}
      >
        Order save successfully
      </SweetAlert>}
    </Modal>
  </>
}

export default UpdateOrderStatus