import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from 'Components/Table'

const YogSattvaCard = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  handleEditYogsattva
}) => {

  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handleEditYogsattva(row._id)}
        >
          Edit
        </Button>
      </div>
    )
  }

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        const fDate = new Date(el.createdAt)
        console.log(fDate)
        var o = { ...el,date:fDate.toLocaleDateString(),status: el.paymentCaptured ? 'Paid':'Not paid' }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [ tableData ])


  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>All Yogsattvas</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  {/* <h3 className='m-0'>Published Content</h3> */}
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={10}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default YogSattvaCard
