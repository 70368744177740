import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import ViewProductComp from 'Components/ViewProductComp'

import { Loader } from 'Components'

import { getProductReq } from '../../Api'

const ViewProduct = () => {
  const { productID } = useParams()

  const naviagte = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [productThumbnail, setProductThumbnail] = useState(null)
  const [bannerImage, setBannerImage] = useState(null)
  const [productImage1, setProductImage1] = useState('')
  const [productImage2, setProductImage2] = useState('')
  const [productImage3, setProductImage3] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    discount: '',
    category: '',
    stockCount : '',
    _id: '',
  })

  const { description, name, _id, price, discount, stockCount, category } = formData

  const handleEditProduct = (productId) => {
    naviagte(`/dashboard/Products/edit/${productId}`)
  }

  const getProduct = async() => {
    try {
      setIsLoading(true)
      const { data } = await getProductReq(productID)
      setFormData(data.data)
      console.log(data.data.productImage)
      if (data.data.productThumbnail) setProductThumbnail(data.data.productThumbnail)
      if (data.data.productImage[0]) setProductImage1(data.data.productImage[0])
      if (data.data.productImage[1]) setProductImage2(data.data.productImage[1])
      if (data.data.productImage[2]) setProductImage3(data.data.productImage[2])
      if (data.data.bannerImage) setBannerImage(data.data.bannerImage)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  console.log(formData)
  useEffect(() => {
    getProduct()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isLoading ? (
        <>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h3 className='m-0'>Product Info</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          <ViewProductComp
            pageHeading='Product Info'
            name={name}
            description={description}
            price={price}
            discount={discount}
            category={category}
            stockCount={stockCount}
            productThumbnail={productThumbnail}
            productImage1={productImage1}
            productImage2={productImage2}
            productImage3={productImage3}
            bannerImage={bannerImage}
            _id={_id} 
            handleEditProduct={handleEditProduct}
          />
        </>
      )}
    </>
  )
}

export default ViewProduct
