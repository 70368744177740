import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import YogSattvaCard from 'Components/YogsattvaCard'
import { getAllAdCoursesPI, getAllBookingsList, getAllRoomsList, getAllYogsattvaAPI, getAvailableBatchDates, getAvailableBatchTime, getAvailableCourses, getAvailableRooms } from '../../Api'
import { Loader } from 'Components'
import { useParams } from 'react-router-dom'
import { BookingsHeading, RoomsTableHeading } from '../../constants'
import AdBookings from 'Components/AdBookings'
const selectUserData = [{ label: 10, value: 10 },
{ label: 20, value: 20 },
{ label: 50, value: 50 },
{ label: 100, value: 100 },
{ label: 500, value: 500 },
{ label: 1000, value: 1000 },
{ label: 2000, value: 2000 },
{ label: 5000, value: 5000 },
  // { label: 10000, value: 10000 }, 
]

const AllBookings = () => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedLimit, setSelectedLimit] = useState([])
  const [contactNo, setContactNo] = useState()
  const [roomList, setCourseList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [relatedRoom, setRelatedCourses] = useState()
  const [relatedBatches, setRelatedBatches] = useState()
  const [relatedTimings, setRelatedTimings] = useState()
  const [timingList, setTimingList] = useState()
  const [showModal, setShowModal] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    page: 1,
  })
  const { page, totalSize } = paginationValue

  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [roomOptions, setRoomOptions] = useState([])
  const [selectedRoom, setSelectedRoom] = useState()

  const navigate = useNavigate()

  const { id } = useParams()
  const handleStartDateChange = (date) => {

    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }
  const handleContactNoChange = (data) => {
    setContactNo(data.target.value)
  }
  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }

  const formatEndDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      const endOfDay = date;
      endOfDay.setHours(23, 59, 59, 999);
      return endOfDay.toISOString()//.slice(0, 10)}
    }
  }
  const getAllRooms = async (queryParams) => {

    try {
      let payload = {}
      if (startDate) {
        payload['checkInDate'] = formatDate(startDate)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['checkInDate'] = isoStringStart
      }
      if (endDate) {
        payload['checkOutDate'] = formatEndDate(endDate)
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['checkOutDate'] = isoStringEnd
      }
      if (relatedRoom) {
        payload['room'] = relatedRoom?.value
      }
      const { data } = await getAllBookingsList({ limit: queryParams?.limit ? queryParams.limit : 10, pageNo: queryParams?.page ? queryParams?.page : 1, ...payload })
      setTableData(data.data)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = (type, { page, sizePerPage }) => {
    getAllRooms({ page: page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleRomChange = (val) => {
    setSelectedRoom(val)
  }

  const closeModal = (value) => {
    setShowModal(false)
    if (value) {
      getAllRooms({ page: 1, limit: selectedLimit ? selectedLimit : 10 })
    }
  }
  const handleAction = async (data) => {
    setShowModal(true)
    let payload = { checkInDate: data?.roomBooking?.checkInDate, checkOutDate: data?.roomBooking?.checkOutDate, sharingType: data?.roomBooking?.sharingType }
    let response = await getAvailableRooms(payload)
    let roomArry = []
    response?.data?.forEach(ele => {
      roomArry.push({ label: ele.roomNo, value: ele._id })
    })
    setRoomOptions(roomArry)
  }

  const handleSelectUsersListChange = (selectedOption) => {
    setSelectedLimit(selectedOption)
  }

  const getRoomList = async () => {
    const data = await getAllRoomsList({ limit: 500, page: 1 })
    let dropdownData = [{ label: 'All', value: '' }];
    data?.data?.data?.forEach(element => {
      dropdownData.push({ value: element?._id, label: element?.roomNo })
    });
    setCourseList(dropdownData);
  }

  const handleConfirmClick = async () => {
    const limitValue = selectedLimit ? selectedLimit.value : 10
    getAllRooms({ page: 1, limit: limitValue })
  }
  const handleRelatedCoursesChange = async (selectedRoom) => {
    setRelatedCourses(selectedRoom)
    
  }

  const handleRelatedBatchTiming = async (selectedTiming) => {
    setRelatedTimings(selectedTiming)
  }

  const handleRelatedBatchChange = async (selectedCourse) => {
    setRelatedBatches(selectedCourse)
    setRelatedTimings('')
    if (selectedCourse?.value) {
      const data = await getAvailableBatchTime({ courseName: relatedRoom?.value, courseDate: selectedCourse?.value })
      let dropdownData = [{ label: 'All', value: '' }];
      data?.data?.forEach(element => {
        dropdownData.push({ value: element?.courseTimings, label: element?.courseTimings })
      });
      setTimingList(dropdownData)
    }
    else {
      setTimingList([])
    }
  }
  useEffect((selectedLimit
  ) => {
    const startOfDay = new Date(); // Create a new Date object with the current date and time
    startOfDay.setHours(0, 0, 0, 1);
    const isoStringStart = startOfDay.toISOString();
    setStartDate(isoStringStart)

    const endOfDay = new Date(); // Create a new Date object with the current date and time
    endOfDay.setHours(23, 59, 59, 999);
    const isoStringEnd = endOfDay.toISOString();
    setEndDate(isoStringEnd)
    getAllRooms({ page: 1, limit: selectedLimit ? selectedLimit : 10 })
    getRoomList()
  }, [])

  return (isLoading ? <Loader /> : <AdBookings

    tableData={tableData}
    tableHeading={BookingsHeading}
    getAllRooms={getAllRooms}
    handlePageChange={handlePageChange}
    page={page}
    sizePerPage={10}
    totalSize={totalSize}
    handleAction={handleAction}
    isLoading={isLoading}
    startDate={startDate}
    endDate={endDate}
    handleEndDateChange={handleEndDateChange}
    handleStartDateChange={handleStartDateChange}
    handleConfirmClick={handleConfirmClick}
    selectUserData={selectUserData}
    handleSelectUsersListChange={handleSelectUsersListChange}
    selectedLimit={selectedLimit}
    contactNo={contactNo}
    handleContactNoChange={handleContactNoChange}
    roomList={roomList}
    relatedRoom={relatedRoom}
    handleRelatedCoursesChange={handleRelatedCoursesChange}
    handleRelatedBatchChange={handleRelatedBatchChange}
    batchList={batchList}
    relatedBatches={relatedBatches}
    relatedTimings={relatedTimings}
    timingList={timingList}
    handleRelatedBatchTiming={handleRelatedBatchTiming}
    showModal={showModal}
    closeModal={closeModal}
    selectedRoom={selectedRoom}
    roomOptions={roomOptions}
    handleRomChange={handleRomChange}
  />)
}
export default AllBookings

