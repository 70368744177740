import EnrolledUsersCard from 'Components/EnrolledUserCard'
import { useEffect, useState } from 'react'
import { getAllEnrolledUserReq } from '../Api'
import { tagsTableHeading } from '../constants'

const EnrolledUsers = () => {
  const [dataTags, setDataTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  })
  const { currentPageTags, totalSizeTags } = paginationValueTags

  const getCategoryData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getAllEnrolledUserReq(queryParams)
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      })
      setDataTags(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCategoryData({ page: 1, limit: 10 })
  }, [])


  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    getCategoryData({ page, limit: sizePerPage })
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageCat: page,
    })
  }

  return (
    <>
      <EnrolledUsersCard
        heading="Enrolled Users"
        tableData={dataTags}
        tableHeading={tagsTableHeading}
        handleTablePageChange={handleTablePageChangeTags}
        page={currentPageTags}
        sizePerPage={10}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        allTags={true}
      />
    </>

    
  )
}

export default EnrolledUsers