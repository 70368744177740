import { useState, useEffect } from 'react'

import ProductCard from '../../../../../../Components/ProductCard'

import UnpublishedCard from 'Components/UnpublishedProducts'

import { PublishedProductTableHeading, UnPublishedProductTableHeading } from '../../Constants'

import { deleteProductReq, getPublishedProduct, getUnpublishedProduct, publishProduct, unPublishProduct } from '../../Api'

import { useNavigate } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'

const AllProduct = () => {
  const [publishedProducts, setPublishedProducts] = useState([])
  const [unPublishedProducts, setUnPublishedProducts] = useState([])
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPublishAlert, setShowPublishAlert] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [publishID, setPublishID] = useState('')
  const [unPublishID, setUnPublishID] = useState('')
  const [deleteID, setDeleteID] = useState('')
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue


  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  })
  const { unpublishedCurrentPage, unpublishedTotalSize } = unpublishedPaginationValue

  const navigate = useNavigate()

  const handlePublishProductData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getPublishedProduct(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setPublishedProducts(data.data)
      
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleUnPublishProductData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUnpublishedProduct(queryParams)
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      })
      // setPublishedProducts(UnpublishedData.data)
      setUnPublishedProducts(data.data)
      
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlePublishProductData({ page: 1, limit: 100 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // getProductData({ page: 1, limit: 10 })
    handleUnPublishProductData({ page: 1, limit: 100 })
    // eslint-disable-next-line
  }, [])


  const handleViewProduct = (productID) => {
    navigate('/dashboard/Products/view/' + productID)
  }

  const handleDeleteProduct = (productID) => {
    console.log(productID)
    setDeleteID(productID)
    setShowDeleteAlert(true)
  }

  const handleEditProduct = (productID) => {
    console.log('/dashboard/Product/edit/' + productID)
    navigate('/dashboard/Products/edit/' + productID)
  }

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishProductData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }


  const handleUnPublishProductClick = async(productID) => {
    setUnPublishID(productID)
    setShowUnPublishAlert(true)
  }

  const handlePublishProductClick = async(productID) => {
    setPublishID(productID)
    setShowPublishAlert(true)
  }

  const publishProductAction = async() => {
    await publishProduct(publishID)
    const filteredProducts = publishedProducts.filter(({ _id }) => _id !== publishID)
    setPublishedProducts(filteredProducts)
    setPublishID('')
    setShowPublishAlert(false)
    setShowPublishConfirm(true)
  }

  const unPublishProductAction = async() => {
    await unPublishProduct(unPublishID)
    const filteredProducts = unPublishedProducts.filter(({ _id }) => _id !== publishID)
    setUnPublishedProducts(filteredProducts)
    setUnPublishID('')
    setShowUnPublishAlert(false)
    setShowUnPublishConfirm(true)
  }

  const deleteProductAction = async() => {
    await deleteProductReq(deleteID)
    const filteredProducts = unPublishedProducts.filter(({ _id }) => _id !== publishID)
    setUnPublishedProducts(filteredProducts)
    setDeleteID('')
    setShowDeleteAlert(false)
    setShowDeleteConfirm(true)
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <h2 style={{ margin: '0px' }}>All Products</h2>
      </div>
      <ProductCard
        tableData={publishedProducts}
        tableHeading={PublishedProductTableHeading}
        handleViewProduct={handleViewProduct}
        handleEditProduct={handleEditProduct}
        handlePublishProduct={handlePublishProductClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />


      <UnpublishedCard 
        tableData={unPublishedProducts}
        tableHeading={UnPublishedProductTableHeading}
        handleViewProduct={handleViewProduct}
        handleEditProduct={handleEditProduct}
        handleDeleteProduct={handleDeleteProduct}
        handleUnPublishProduct={handleUnPublishProductClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        totalSize={unpublishedTotalSize}
        isLoading={isLoading}
      />


      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishProductAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowPublishAlert(false)
            setShowPublishConfirm(false)
            getPublishedProduct({ page: 1, limit: 10 })
            window.location.reload()
          }}
        >
          Product has been Unpublished
        </SweetAlert>
      ) : null}


      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={unPublishProductAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            window.location.reload()
            // getCreatedMusicData({ page: 1, limit: 10 })
          }}
        >
          Product has been published
        </SweetAlert>
      ) : null}


      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteProductAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false)
            setShowDeleteConfirm(false)
            window.location.reload()
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
    </>
  )
}

export default AllProduct
