import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'
import { useEffect, useState } from 'react'
import { getAllProductReq, searchCategory } from 'Views/Dashboard/Views/Products/Api'
import Select from 'react-select'
const CreateProduct = ({
  pageHeading = 'Create Products',
  handelInput,
  name,
  skuId,
  description,
  handleSubmit,
  isDisabledBtn = false,
  createBtnText = 'Create Product',
  weight,
  bannerImage,
  setBannerImage,
  productImage1,
  setProductImage1,
  productImage2,
  setProductImage2,
  productImage3,
  setProductImage3,
  productThumbnail,
  setProductThumbnail,
  price,
  priceInternational,
  setSelectedCategory,
  similarProducts,
  handleSimilarProducts,
  similarProductsData
}) => {

  const [currentSearch, setCurrentSearch] = useState([])
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')


  const getSearchData = async(text) => {
    try {
      const { data } = await searchCategory(text)
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([])
    } catch (error) {
      setCurrentSearch([])
    }
  }

  // Get initial data
  const getAllTagsData = async() => {
    try {
      const { data } = await getAllProductReq()
      const filteredData = data.data
      console.log(filteredData)
      // setSimilarProductsData(filteredData)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    getAllTagsData()
    // eslint-disable-next-line
      }, []);

  const handleChange = (e) => {
    setSearch(e.target.value)
    setSelectedCategory({
      name: '',
      _id: '',
    })

    let searchBar = e.target.value
    getSearchData(searchBar)
  }


  useEffect(() => {
    search !== '' ? setShow(true) : setShow(false)
  }, [search])

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false)
    }, 1000)
  }

  const handleTagInfo = (item) => {
    setSearch(item.name)
    setSelectedCategory({
      name: item.name,
      _id: item._id,
    })
    setShow(false)
  }

  const selectTag = {
    marginTop: '10px',
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px',
    width: '100%',
    height: '240px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    zIndex: '9',
  }

  const selectSearchItem = {
    fontSize: '0.9rem',
    fontWeight: '500',
    lineHeight: '1.23',
    textAlign: 'left',
    color: '#505050',
    padding: '1rem 0',
    borderBottom: '1px solid #d3d3d3',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='name'>Product Name</Label>
                    <AvField
                      name='name'
                      placeholder='Enter name'
                      value={name}
                      onChange={handelInput}
                      type='text'
                      errorMessage='Name is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='name'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='categoryTypeSelect'>Category Type</Label>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ position: 'relative', width: '100%' }}>
                        <input
                          name='search'
                          value={search}
                          placeholder='Search Categories'
                          style={{
                            width: '100%',
                            backgroundColor: 'transparent',
                            outline: 'none',
                            border: 'none',
                            borderBottom: '1px solid black',
                            padding: '5px',
                          }}
                          onChange={handleChange}
                          onBlur={hideComponent}
                          autoComplete='off'
                        />
                        {show === true && (
                          <>
                            <div style={selectTag}>
                              {currentSearch && currentSearch.length === 0 ? (
                                <div>Not found</div>
                              ) : (
                                currentSearch &&
                                currentSearch.map((item, i) => {
                                  return (
                                    <div
                                      style={selectSearchItem}
                                      key={i}
                                      onClick={() => handleTagInfo(item)}
                                    >
                                      <div>{item ? item.name : '-'}</div>
                                    </div>
                                  )
                                })
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='skuId'>Sku Id</Label>
                    <AvField
                      name='skuId'
                      placeholder='Enter skuId'
                      value={skuId}
                      onChange={handelInput}
                      type='text'
                      errorMessage='skuId is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='skuId'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='description'>Product Details</Label>
                    <AvField
                      name='description'
                      placeholder='Enter description'
                      value={description}
                      onChange={handelInput}
                      type='text'
                      errorMessage='Description is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='description'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="priceInternational">International Price</Label>
                    <Input
                      name="priceInternational"
                      placeholder="Select Price"
                      type="text"
                      className="form-control"
                      value={priceInternational}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="priceInternational"
                    >
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='price'>National Price</Label>
                    <AvField
                      name='price'
                      placeholder='Enter price'
                      value={price}
                      onChange={handelInput}
                      type='number'
                      errorMessage='price is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='price'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={bannerImage}
                    setFile={setBannerImage}
                    dragText='Drop Banner Image here'
                    heading='Select Banner Image'
                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                  />
                </Col>
                <Col>
                  <FileUploader
                    file={productThumbnail}
                    setFile={setProductThumbnail}
                    dragText='Drop Thumbnail here'
                    heading='Select Thumbnail Image'
                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={productImage1}
                    setFile={setProductImage1}
                    dragText='Drop Image here'
                    heading='Select Image 1'
                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                  />
                </Col>
                <Col>
                  <FileUploader
                    file={productImage2}
                    setFile={setProductImage2}
                    dragText='Drop Image here'
                    heading='Select Image 2'
                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                  />
                </Col>
                <Col>
                  <FileUploader
                    file={productImage3}
                    setFile={setProductImage3}
                    dragText='Drop Image here'
                    heading='Select Image 3'
                    accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='weight'>Product Weight (in grams)</Label>
                    <AvField
                      name='weight'
                      placeholder='Enter weight'
                      value={weight}
                      onChange={handelInput}
                      type='number'
                      errorMessage='weight is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='weight'
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="similarProducts">Similar Products</Label>
                    <Select
                      value={similarProducts}
                      isMulti
                      onChange={handleSimilarProducts}
                      options={similarProductsData}
                      classNamePrefix="select2-selection"
                      placeholder="Enter Similar Products"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color='primary'
                className='w-100 mt-4'
                type='submit'
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
              
              
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CreateProduct
