import TotalHealthRoutes from './Constants/routes'
import { Route, Routes, Navigate } from 'react-router-dom'

const TotalHealth = () => {
  return (
    <Routes>
      {TotalHealthRoutes.map(({ path, Component }) => (
        <Route
          path={path}
          element={<Component />}
          key={path} />
      ))}
      <Route
        path='*'
        element={
          <Navigate
            to={TotalHealthRoutes[0]?.routePath || '/dashboard'}
            replace
          />
        }
      />
    </Routes>
  )
}

export default TotalHealth
