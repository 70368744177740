import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from 'Components/Table'

const DeletedCourse = ({
  tableData = [],
  tableHeading = [],
  handleViewCourse,
  handleRestoreCourse,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
}) => {
  const navigate = useNavigate()
  const [rowData, setRowData] = useState([])

  const viewCourseFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewCourse(row._id)}
        />
      </div>
    )
  }

  const restoreFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handleRestoreCourse(row._id)}
        >
          Restore
        </Button>
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'View Course',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewCourseFormatter,
    })
    tableHeading.push({
      text: 'Restore Course',
      dataField: 'restore',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: restoreFormatter,
    })
    return () => {
      tableHeading.pop()
      tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Courses Bin</h3>
                  <div className="page-title-right">
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: '100%' }}
                      onClick={() => navigate('/dashboard/courses/all')}
                    >
                      All Courses
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default DeletedCourse
