import axios from 'axios'
import { coursesApi } from 'Constants/apiRoutes'
import { cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'


const subDomain = `${cmsBaseDomain}/v1`


export const getAllCourseReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${coursesApi}?page=${page}&limit=${limit}`)
}

export const editCourseReq = (data, courseID) => {
  return axios.patch(`${subDomain}${coursesApi}/update/${courseID}`, data)
}

export const getCourseReq = (courseID) => {
  return axios.get(`${subDomain}${coursesApi}/${courseID}`)
}

export const getPublishedCourse = ({ page, limit }) => {
  return axios.get(`${subDomain}${coursesApi}/publishedcourse?page=${page}&limit=${limit}`)
}

export const publishCourse = (courseID) => {
  return axios.put(`${subDomain}${coursesApi}/unpublish/${courseID}`)
}

export const unPublishCourse = (courseID) => {
  return axios.put(`${subDomain}${coursesApi}/publish/${courseID}`)
}

export const getUnpublishedCourse = ({ page, limit }) => {
  return axios.get(`${subDomain}${coursesApi}/unpublishedcourse?page=${page}&limit=${limit}`)
}


export const getAllCourseBinReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${coursesApi}/bin?page=${page}&limit=${limit}`)
}

export const creatCourseReq = (data) => {
  return axios.post(`${subDomain}${coursesApi}`, data)
}

export const deleteCourseReq = (courseID) => {
  return axios.delete(`${subDomain}${coursesApi}/delete/${courseID}`)
}


export const restoreCourseReq = (restoreCourseID) => {
  return axios.put(`${subDomain}${coursesApi}/restore/${restoreCourseID}`)
}

export const searchCourse = (text) => {
  return axios.get(`${subDomain}${coursesApi}/${text}`)
}

export const searchCategory = (text) => {
  return axios.get(`${subDomain}/category/searchcategory/${text}`)
}


