import { UploadRoutes } from './Constants/routes'
import { Route, Routes, Navigate } from 'react-router-dom'

const Uploads = () => {
  console.log(UploadRoutes)
  return (
    <>
      <Routes>
        {UploadRoutes.map(({ path, Component }) => (
          <Route
            path={path}
            element={<Component />}
            key={path}
          />
        ))}
        <Route path='*' element={<Navigate to={UploadRoutes[0]?.routePath || '/dashboard/'} replace />} />
      </Routes>
    </>
  )
}

export default Uploads