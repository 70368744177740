import { useEffect, useState } from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'
import { getBannerImg } from '../../Constants/api'
import BannerCard from 'Components/BannerCard'
import Pagination from 'react-js-pagination'
import './style.scss'
import { useNavigate } from 'react-router-dom'

const AllBannerImg = () => {
  const [pagination, setPagination] = useState({ 
    page:1,
    limit:10
  })
  const [ count, setCount] = useState({})
  const { page, limit } = pagination
  const [banners, setBanners] = useState([])
  const navigate =  useNavigate()
  const handleAllBannerImages = async(page, limit) => {
    try {
      const { data } = await getBannerImg({ page, limit })
      setBanners(data.data)
      setCount(data.count)
      console.log(data.data, 'all data')
      console.log(data.count, 'count')
      console.log(page, 'page')
      
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const handlePagination = (pageNumber) => {
    setPagination({ ...pagination,page:pageNumber, limit:10 })
  }

  const handleEditBanner = (bannerID) =>{
    navigate('/dashboard/banner/edit/' + bannerID)
  }



  useEffect(() => {
    handleAllBannerImages(page, limit)
  }, [ pagination ])
  return (
    <>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">All Banner Images</h3>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {banners.map((item, i) => (
        <BannerCard
          key={i}
          bannerData={item}
          handlePagination={handlePagination}
          page={page}
          limit={limit}
          count={count}
          handleEditBanner={handleEditBanner}
        />
      ))}
      <div className='pagination-container' >
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={count}
          pageRangeDisplayed={ 3 }
          onChange={ (e)=>handlePagination(e) }
        />
      </div>
    </>
  )
}

export default AllBannerImg
