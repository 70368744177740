import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Label, FormGroup, Form, Input } from 'reactstrap'

import { loginUserAction } from '../../Auth.actions'

import { useNavigate } from 'react-router'
import { isAuthorized } from 'Utils/localStorage'
import Select from 'react-select'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reduxState = useSelector((state) => state)

  console.log(reduxState ,'reduxState')

  const [loginCred, setLoginCred] = useState({
    email: '',
    password: '',
    type:''
  })
  

  useEffect(() => {
    if(isAuthorized()) 
      navigate('/dashboard')
    // eslint-disable-next-line
  }, [])



  const { email, password, type } = loginCred
  const [role , setRole] = useState('')

  const handelInput = (e) => {
    setLoginCred({
      ...loginCred,
      [e.target.name]: e.target.value,
    })
  }
  
  const handelLogin = async(e) => {
    const body={
      email: loginCred.email,
      password: loginCred.password,
      type:role?.value
    }
    e.preventDefault()
    dispatch(loginUserAction(body, navigate))
  }


  const roleType = [
    { 
      label : 'ADMIN',
      value : 'ADMIN' 
    },
    {
      label : 'COORDINATOR',
      value : 'COORDINATOR'
    }
  ]
  // toddo add validatiom to email and password

  return (
    <div className="p-2 mt-3">
      <Form className="form-horizontal" onSubmit={handelLogin}>
        <FormGroup className="auth-form-group-custom mb-4">
          <i className="ri-user-2-line auti-custom-input-icon"></i>
          <Label htmlFor="email">Email</Label>
          <Input
            name="email"
            value={email}
            onChange={handelInput}
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter Email"
          />
        </FormGroup>

        <FormGroup className="auth-form-group-custom mb-4">
          <i className="ri-lock-2-line auti-custom-input-icon"></i>
          <Label htmlFor="userpassword">Password</Label>
          <Input
            name="password"
            value={password}
            onChange={handelInput}
            type="password"
            className="form-control"
            id="userpassword"
            placeholder="Enter password"
          />
        </FormGroup>

        
        <Label htmlFor='type'>Select Your Role</Label>
        <Select
          value={role}
          isMulti = {false}
          options={roleType}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder= 'Select Your Role'
          onChange={(select)=>setRole(select)}
        />
        
        { reduxState?.auth?.error ? <>
          <p className='mt-4 text-danger'>{reduxState?.auth?.errorMsg}</p>
        </> : <></> }
        {role === '' ?<>
          <div className="mt-4 text-center">
            <Button
              className="w-md waves-effect waves-light login-btn"
              type="submit"
              disabled
            >
          Log In
            </Button>
          </div>
        </>:
          <div className="mt-4 text-center">
            <Button
              className="w-md waves-effect waves-light login-btn"
              type="submit"
            >
          Log In
            </Button>
          </div>
        }
        
      </Form>
    </div>
  )
}

export default Login
