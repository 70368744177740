export const PublishedProductTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Product Name',
    dataField: 'name',
  },
  {
    text: 'Category',
    dataField: 'categoryId.name',
  },
  {
    text: 'Product Details',
    dataField: 'description',
  }
]

export const UnPublishedProductTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Product Name',
    dataField: 'name',
  },
  {
    text: 'Category',
    dataField: 'categoryId.name',
  },
  {
    text: 'Product Details',
    dataField: 'description',
  }
]

export const binProductTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Product Name',
    dataField: 'name',
  },
  {
    text: 'Product Details',
    dataField: 'description',
  },
]

export const shippingTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text:'Weight Range',
    dataField:'weightRange',
  },
  {
    text: 'Price',
    dataField: 'rate',
  },
]

export const IntShippingTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text:'Weight Range',
    dataField:'weightRange',
  },
  {
    text: 'Price',
    dataField: 'rate',
  },
]



export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
}