import TotalHealthForm from 'Components/TotalHealthForm'
import { useState, useEffect } from 'react'
import { creatingStates } from 'Views/Dashboard/Views/Uploads/Constants'
import { editTotalHealthAPI, getTotalHealthDataById } from '../../Api'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/Helper'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useNavigate, useParams } from 'react-router-dom'

const EditTotalHealth = () => {

  const [formData, setFormData] = useState({
    title: '',
    description: ''
  })

  const { title, description } = formData
  const [TotalHealthImg, setTotalHealthImg] = useState(null)
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  const [previousData, setPreviousData] = useState({})
  const [isDataUpdated, setIsDataUpdated] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })

  const { isCreating, creatingStage } = creating
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)
  const [editedData, setEditedData] = useState({})
  const handleUploadImage = async() => {
    let typeOfImg = typeof TotalHealthImg
    if (typeOfImg !== 'string') {
      const totalImage = await uploadFile(
        TotalHealthImg,
        // TotalHealthImg?.type,
        'YOGA-HEALTH',
        setThumbnailUploadPercentage,
        30
      )
      setEditedData({ ...editedData, imageUrl: totalImage })
      if(TotalHealthImg.path !== totalImage){
        setIsDataUpdated(true)
      } else {
        setIsDataUpdated(false)
      }
    }
  }
  useEffect(() => {
    handleUploadImage()
  }, [TotalHealthImg])

  const isFieldsChanged = (curr) => {
    let prev = JSON.stringify(previousData)
    let current = JSON.stringify(curr)
    if (prev !== current) {
      setIsDataUpdated(true)
      return true
    } else if ( previousData.imageUrl !== TotalHealthImg ) {
      setIsDataUpdated(true)
    } else {
      setIsDataUpdated(false)
      return false
    }
  }



  let debounce
  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value
    })
    clearTimeout(debounce)
    debounce = setTimeout(
      isFieldsChanged({
        ...formData,
        ...editedData,
        [e.target.name]: e.target.value
      }),
      500
    )
    console.log(isDataUpdated)
  }

  const getDataById = async() => {
    try {
      const { data } = await getTotalHealthDataById(id)
      setFormData(data?.data)
      setTotalHealthImg(data?.data?.imageUrl)
      setPreviousData({
        ...data?.data,
      })
    } catch (error) {
      console.log(error)
    }
  }


  const getUpdateTotalHealth = async() => {
    console.log(TotalHealthImg)
    try {
      if (!TotalHealthImg)
        return setError({
          isError: true,
          errorMessage: 'Please select a cover image',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })

      // const totalImage = await uploadFile(
      //   TotalHealthImg,
      //   'Total-Health',
      //   setThumbnailUploadPercentage,
      //   30
      // )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,

      })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })

      await editTotalHealthAPI(
        id, editedData,
      )
      setShowUnPublishAlert(false)
      setShowUnPublishConfirm(true)
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }
    setShowUnPublishAlert(false)
    setShowUnPublishConfirm(true)

  }

  const handleSubmit = () => {
    setShowUnPublishAlert(true)
    setShowUnPublishConfirm(false)
  }

  useEffect(() => {
    getDataById()
  }, [])

  return (
    <div>
      <TotalHealthForm
        title={title}
        description={description}
        TotalHealthImg={TotalHealthImg}
        setTotalHealthImg={setTotalHealthImg}
        formData={formData}
        isDataUpdated={isDataUpdated}
        setFormData={setFormData}
        handleInput={handleInput}
        handleSubmit={handleSubmit}
      />
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={getUpdateTotalHealth}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            navigate('/dashboard/totalHealth/all')
          }}
        >
          Yogsattva has been Edited
        </SweetAlert>
      ) : null}
    </div>
  )
}

export default EditTotalHealth
