import CouponCard from 'Components/CouponCard'
import UnpublishedCoupons from 'Components/UnpublishedCoupons'
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useNavigate } from 'react-router-dom'
import { PublishedCouponTableHeading, UnPublishedCouponTableHeading } from '../../Constants'
import { getPublishedCouponReq, getUnpublishedCouponReq, publishCoupon, UnpublishCoupon } from '../../Coupons.Apis'

const AllCoupons = () => {
  const [publishedCoupons, setPublishedCoupons] = useState([])
  const [unpublishedCoupons, setUnpublishedCoupons] = useState([])
  const [showPublishAlert, setShowPublishAlert] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [showUnpublishAlert, setShowUnpublishAlert] = useState(false)
  const [showUnpublishConfirm, setShowUnpublishConfirm] = useState(false)
  const [publishID, setPublishID] = useState('')
  const [unpublishID, setUnpublishID] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  })
  const { unpublishedCurrentPage, unpublishedTotalSize } = unpublishedPaginationValue

  const navigate = useNavigate()

  const handlePublishCouponData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getPublishedCouponReq(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setPublishedCoupons(data.data)

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }


  const handleUnpublishCouponData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUnpublishedCouponReq(queryParams)
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      })
      setUnpublishedCoupons(data.data)

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handlePublishCouponData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleUnpublishCouponData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  const handleViewCoupon = (couponID) => {
    navigate('/dashboard/coupon/view/' + couponID)
  }

  const handleEditCoupon = (couponID) => {
    console.log('/dashboard/coupon/edit/' + couponID)
    navigate('/dashboard/coupon/edit/' + couponID)
  }

  const handleUnpublishCouponClick = async(couponID) => {
    setUnpublishID(couponID)
    setShowUnpublishAlert(true)
  }

  const handlePublishCouponClick = async(couponID) => {
    setPublishID(couponID)
    setShowPublishAlert(true)
  }

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishCouponData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleunpublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishCouponData({ page, limit: sizePerPage })
    setPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    })
  }

  const UnpublishCouponAction = async() => {
    await UnpublishCoupon(unpublishID)
    const filteredProducts = publishedCoupons.filter(
      ({ _id }) => _id !== publishID
    )
    setPublishedCoupons(filteredProducts)
    setUnpublishID('')
    setShowUnpublishAlert(false)
    setShowUnpublishConfirm(true)
    await getUnpublishedCouponReq({ page: 1, limit: 10 })
    await getPublishedCouponReq({ page: 1, limit: 10 })
  }

  const publishCouponAction = async() => {
    await publishCoupon(publishID)
    const filteredProducts = unpublishedCoupons.filter(
      ({ _id }) => _id !== publishID
    )
    setUnpublishedCoupons(filteredProducts)
    setPublishID('')
    setShowPublishAlert(false)
    setShowPublishConfirm(true)
    await getPublishedCouponReq({ page: 1, limit: 10 })
    await getUnpublishedCouponReq({ page: 1, limit: 10 })
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <h2 style={{ margin: '0px' }}>All Coupons</h2>
      </div>
      <CouponCard
        tableData={publishedCoupons}
        tableHeading={PublishedCouponTableHeading}
        handleViewCoupon={handleViewCoupon}
        handleEditCoupon={handleEditCoupon}
        handleTablePageChange={handlePublishedTablePageChange}
        handleUnpublishCoupon={handleUnpublishCouponClick}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      <UnpublishedCoupons
        tableData={unpublishedCoupons}
        tableHeading={UnPublishedCouponTableHeading}
        handleViewCoupon={handleViewCoupon}
        handleEditCoupon={handleEditCoupon}
        handleTablePageChange={handleunpublishedTablePageChange}
        handlePublishCoupon={handlePublishCouponClick}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        totalSize={unpublishedTotalSize}
        isLoading={isLoading}
      />

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={publishCouponAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowPublishAlert(false)
            setShowPublishConfirm(false)
            getPublishedCouponReq({ page: 1, limit: 10 })
            window.location.reload()
          }}
        >
          Coupon has been Published
        </SweetAlert>
      ) : null}


`{showUnpublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={UnpublishCouponAction}
          onCancel={() => setShowUnpublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnpublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnpublishAlert(false)
            setShowUnpublishConfirm(false)
            getUnpublishedCouponReq({ page: 1, limit: 10 })
            window.location.reload()
          }}
        >
          Coupon has been Unpublished
        </SweetAlert>
      ) : null}
    </>
  )
}

export default AllCoupons
