import './index.scss'
import { useState } from 'react'
import CopyLogo from '../../assets/images/copyIcon.png'
import TickMark from '../../assets/images/ticked.png'
import Delete from '../../assets/images/delete.png'
import {
  Col, Row,
} from 'reactstrap'
import { deleteImages } from 'Views/Dashboard/Views/Images/api'
import SweetAlert from 'react-bootstrap-sweetalert'


const ImageCard = ({ item,key,imageId }) => {
  const [copyUrl,setCopyUrl] = useState('COPY URL' )
  const [deleteConfirm,setDeleteConfirm] = useState(false)
  const [deleteAlert,setDeleteAlert] = useState(false)
  const [copyIcon, setCopyIcon] = useState(0)
  
  const copyText = ( string )=> {

    navigator.clipboard.writeText(string)
    setCopyUrl('COPIED')
    setCopyIcon(1)
    
    setTimeout(() => { 
      setCopyUrl('COPY URL')
      setCopyIcon(0)
      
    },2000)
  
  }
  
  const deleteImageHandler = async() => {
    try {
      await deleteImages(imageId)
      setDeleteAlert(false)
      setDeleteConfirm(true)
    } catch (error) {
      console.log('error')
    }
  }

  return (
    
    <>             
      
      <Col xs={3} key={ key } className='imgCard' >
        <Row>
          <img className='upImages' src={item}/>
        </Row>
       
        <Row>
          <Col className='copyUrl' onClick={()=>copyText(item)} >
            {copyUrl}
            { copyIcon===0?<img className='cLogo' src={CopyLogo} alt = '...' />
              :
              <img className='cLogo' src={TickMark} alt = '...' /> }
          </Col>
          <Col className='deleteImage' onClick={()=>setDeleteAlert(true)}>
            <img className='cLogo' src={Delete} alt='Delete' />
          </Col>
        </Row>
      </Col>

      {deleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          reverseButtons={true}
          onConfirm={deleteImageHandler}
          onCancel={() => setDeleteAlert(false)}
        ></SweetAlert>
      ) : null}  

      {deleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setDeleteConfirm(false)
            window.location.reload()
          }}
        >
          Image deleted
        </SweetAlert>
      ) : null}           
    
    </>
    
  )
}

export default ImageCard
