import AllCourses from '../Views/AllCourses'
import CreateCourse from '../Views/CreateCourse'
import EditCourse from '../Views/EditCourse'
import CoursesBin from '../Views/CoursesBin'

export const CoursesRoutes = [
  {
    Component: AllCourses,
    path: '/all',
    exact: true,
    id: 'dashboardCoursesAll',
    routePath: '/dashboard/courses/all',
    name: 'All Courses',
  },
  {
    Component: CreateCourse,
    path: '/create',
    exact: true,
    id: 'dashboardCourseCreate',
    routePath: '/dashboard/courses/create',
    name: 'Add Course',
  },
  {
    Component: CoursesBin,
    path: '/bin',
    exact: true,
    id: 'dashboardCourseBin',
    routePath: '/dashboard/courses/bin',
    name: 'Course Bin',
  },
  {
    Component: EditCourse,
    path: '/edit/:courseID',
    exact: true,
    id: 'dashboardCourseEdit',
  },
]
