import AllYogsattvas from '../Views/AllYogsattvas'
import UpdateYogsattva from '../Views/UpdateYogsattva'
import EditUpdateYogsattva from '../Views/EditUpdateYogsattva'

const YogsattvaRoutes = [
  {
    Component:AllYogsattvas,
    path: '/all',
    exact: true,
    id : 'dashboardYogsattvaAll',
    routePath: '/dashboard/yogsattva/all',
    name: 'All Yogsattvas',
  },
  {
    Component: UpdateYogsattva,
    path: '/create',
    exact: true,
    id: 'dashboardYogsattvaCreate',
    routePath: '/dashboard/yogsattva/create',
    name: 'Create Yogsattva',
  },
  {
    Component: EditUpdateYogsattva,
    path: '/edit/:id',
    exact: true,
    id: 'dashboardYogsattvaEdit',
    routePath: '/dashboard/yogsattva/edit',
  }
]

export default YogsattvaRoutes