import { useState } from 'react'
import UserForm from 'Components/UserForm'
import { createAllUser } from '../../User.Api'
import { useNavigate } from 'react-router'
import SweetAlert from 'react-bootstrap-sweetalert'

const CreateUser = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName:'',
    // description: '',
    // metaData: '',
    phoneNumber:'',
    role: '',
    email: '',
    usename:'',
    password: '',
  })

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const {
    firstName,
    lastName,
    // description: '',
    // metaData: '',
    phoneNumber,
    role,
    email,
    usename,
    password,
  } = formData

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleAlert = () => {
    return setShowDeleteConfirm(true)
  }

  const handleSubmit = async() => {
    const res = await createAllUser(formData)
    if (res.success) {
      navigate('/all')
    }


  }
  return (
    <>
      <UserForm
        // name={name}
        firstName={firstName}
        lastName={lastName}
        // description={description}
        // metaData={metaData}
        phoneNumber={phoneNumber}
        email={email}
        password={password}
        role={role}
        usename={usename}
        handelInput={handelInput}
        handleSubmit={handleAlert}
      />
      {showDeleteConfirm && (
        <SweetAlert
          success
          title="User Created"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            handleSubmit()
            setShowDeleteConfirm(false)
          }}
        >
          User Added
        </SweetAlert>
      )}
    </>
  )
}

export default CreateUser
