import axios from 'axios'
import { adCampaignBaseDomain, cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'
const cmsBaseDomainn = `${adCampaignBaseDomain}v1`

export const getAllRoomsList = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/rooms/list`, payload)
}
export const getAllAvailableRoomsList = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/room-bookings/available-beds/list`, payload)
}
export const getAllBookingsList = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/room-bookings/list`, payload)
}
export const getAllIntercatedUsers = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/customers/list`, payload)
}
export const getIntercatedCourses = () => {
  return axios.get(`${cmsBaseDomainn}/admin/customers/courses/list`)
}
export const saveRoom = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/rooms`, payload)
}
export const getAllAdCoursesPI = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/payments/list`, payload)
}
export const getAvailableCourses = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/course-subscriptions/courses/list`, payload)
}
export const getAvailableBatchDates = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/course-subscriptions/course/batch-dates/list`, payload)
}
export const getAvailableBatchTime = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/course-subscriptions/course/batch-timings/list`, payload)
}
export const getAvailableRooms = (payload) => {
  return axios.post(`${cmsBaseDomainn}/admin/room-bookings/available/list`, payload)
}
export const allocateRoom = (payload, id) => {
  return axios.put(`${cmsBaseDomainn}/admin/room-bookings/status/${id}`, payload)
}
export const createYogsttvaAPI = (payload) => {
  return axios.post(`${cmsBaseDomainn}/yogaSatva/`, payload)
}

export const getYogasattvaDataById = (id) => {
  return axios.get(`${cmsBaseDomainn}/yogaSatva/${id}`)
}

export const updateYogsattvaAPI = (id, payload) => {
  return axios.patch(`${cmsBaseDomainn}/yogaSatva/update/${id}`, payload)
}