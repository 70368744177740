import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Form,
  Input,
} from 'reactstrap'
import ReactPlayer from 'react-player'

import FileUploader from '../FileUploader'
import { fileAcceptType } from './EditContentComp.Constant'

const EditContentComp = ({
  pageHeading = 'Edit Content',
  title,
  description,
  thumbnail,
  content,
  setThumbnail,
  type,
  handelInput,
  isDisabledBtn,
  editBtnText,
  setContent,
  handleSubmit,
}) => {
  const handlePdfClick = () => {
    if (typeof content === 'string') window.open(content)
    else window.open(content.preview)
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <Form className='form-horizontal' onSubmit={handleSubmit}>
              <Row>
                <Col xs={10}>
                  <FormGroup>
                    <Label htmlFor='title'>Title</Label>
                    <Input
                      name='title'
                      placeholder='Enter title'
                      onChange={handelInput}
                      value={title}
                      className='form-control'
                      id='title'
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor='description'>Description</Label>
                <Input
                  name='description'
                  placeholder='Enter description'
                  onChange={handelInput}
                  value={description}
                  className='form-control'
                  id='description'
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                />
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor='description'>Content Type</Label>
                    <Input
                      name='type'
                      placeholder='Enter type'
                      disabled
                      value={type}
                      className='form-control'
                      id='description'
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div style={{ marginTop: '-15px' }}>
                    <FileUploader
                      file={content}
                      setFile={setContent}
                      dragText='Drop Content Here'
                      heading='Content'
                      accept={fileAcceptType[type]}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ marginTop: '-15px' }}>
                    <FileUploader
                      file={thumbnail}
                      setFile={setThumbnail}
                      dragText='Drop content image here'
                      heading='Content Thimbnail'
                      accept='image/png, image/gif, image/jpeg, image/jpg, image/webp'
                      isImage
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                {type === 'AUDIO' && (
                  <Col lg={12}>
                    <FormGroup className='select2-container'>
                      <Label className='control-label'>Play Audio</Label>
                      <ReactPlayer
                        url={
                          typeof content === 'string'
                            ? content
                            : content.preview
                        }
                        width='100%'
                        height='50px'
                        playing={false}
                        controls={true}
                      />
                    </FormGroup>
                  </Col>
                )}

                {type === 'VIDEO' && (
                  <Col lg={6}>
                    <FormGroup className='select2-container'>
                      <Label className='control-label'>Play Video</Label>
                      <ReactPlayer
                        url={
                          typeof content === 'string'
                            ? content
                            : content.preview
                        }
                        controls={true}
                        config={{
                          file: {
                            attributes: {
                              controlsList:
                                'nodownload noplaybackrate noprogress',
                              disablePictureInPicture: 'true',
                            },
                          },
                        }}
                        width={'99%'}
                        height={'99%'}
                      />
                    </FormGroup>
                  </Col>
                )}

                {type === 'PDF' && (
                  <Col lg={12}>
                    <FormGroup className='select2-container'>
                      <Button
                        className='w-100 my-3'
                        color='primary'
                        onClick={handlePdfClick}
                      >
                        View PDF
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>

              <Button
                color='primary'
                className='w-100 mt-4'
                type='submit'
                disabled={isDisabledBtn}
              >
                {editBtnText}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default EditContentComp
