import axios from 'axios'

import baseDomain, {
  contentApi,
  secureStsCredApi,
  secureStsVerifyApi,
  // searchContentAPI
} from '../../../../Constants/apiRoutes'

export const getAllContentReq = ({ page, limit }) => {
  return axios.get(`${baseDomain}${contentApi}?page=${page}&limit=${limit}`)
}

export const editContentReq = (data, contentID) => {
  return axios.put(`${baseDomain}${contentApi}/${contentID}`, data)
}

// export const searchContent = (text) => {
//   return axios.get(`${baseDomain}${contentApi}${searchContentAPI}/${text}`)
// }

export const getContentReq = (contentID) => {
  return axios.get(`${baseDomain}${contentApi}/${contentID}`)
}

export const creatContentReq = (data) => {
  return axios.post(`${baseDomain}${contentApi}`, data)
}

export const getSecureStsCred = (params) => {
  console.log(params,'ss')
  return axios.get(`${baseDomain}${secureStsCredApi}`, {
    params,
  })
}

export const regenerateSecureStsToken = () => {
  return axios.get(`${baseDomain}${secureStsVerifyApi}`)
}

export const uploadFileS3 = (payload) => {
  return axios.post(`${baseDomain}/ali/upload`, payload)
}
