import BannerImgForm from 'Components/BannerImgForm'
import { useEffect, useState } from 'react'
import { creatingStates } from 'Views/Dashboard/Views/Uploads/Constants'
import { uploadBannerImg } from '../../Constants/api'
import UploadBannerStatusBar from 'Components/UploadBannerStatusBar'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/upload.Helper'
import SweetAlert from 'react-bootstrap-sweetalert'
// import { factory } from 'typescript'

const CreateBannerImg = () => {
  const [ bannerImg, setBannerImg] = useState(null)
  const [ productOptions, setProductOptions] = useState([])
  const [ categoryOptions, setCategoryOptions] = useState([])
  const [ categoryDropDown , setCategoryDropDown] = useState(null)
  const [ empty ,setEmpty] =  useState(0)
  const [ alert, setAlert] = useState(false)
  const [ orderAlert, setOrderAlert] = useState(false)
  const [ formData, setFormData] = useState({
    name : 'banner',
    orderNumber:'',
    type:'',
    productId:'',
    categoryId:'',
  })
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: 1,
  })
  const { isCreating, creatingStage } = creating
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)

  const handleEmpty = () =>{

    if(bannerImg === null) {
      setEmpty(1)
    }
    else if (formData.type === '') {
      setEmpty(3)
    } else {
      setEmpty(0)
      setAlert(true)
    }
  }

  const handleSubmit = async() => {
    try {
      if (!bannerImg)
      { setError({
        isError: true,
        errorMessage: 'Please select a cover image',
        errorType: 'warning',
      })
      
      setAlert(false)
      setCreating({
        isCreating: true,
        creatingStage: 1,
      })}
      else {
        setOrderAlert(true)
        setCreating({
          isCreating: false,
          creatingStage: 2,
        })
      } 

      setAlert(false)
      setOrderAlert(false)
    
      const thumbnailUrl = await uploadFile(
        bannerImg,
        'BANNER-IMAGE',
        setThumbnailUploadPercentage,
        100
      )
      await uploadBannerImg(
        {
          ...formData,
          imageLink:thumbnailUrl
        }
      )
      setCreating({
        isCreating: true,
        creatingStage: 2,
      })
     
    } catch (error) {
      setOrderAlert(true)
      // setCreating({
      //   isCreating: false,
      //   creatingStage: 1,
      // })
      // setThumbnailUploadPercentage(0)
      // setContentUploadPercentage(0)
      // setError({
      //   isError: true,
      //   errorMessage: 'Unable to create Image please try again later',
      //   errorType: 'danger',
      // })
    }
  }

  const reset = () => {
  
    setBannerImg(null)
    setError({
      isError: false,
      errorMessage: '',
      errorType: 'warning',
    })
    setFormData({
      name : 'banner',
      orderNumber:'',
      type:'',
      productId:'',
      categoryId:'',
    })
    setCreating({
      isCreating: false,
      creatingStage: 1,
    })
   
  }

  // useEffect(()=>{handleEmpty()},[])
  return (
    <>
      {isCreating ? <UploadBannerStatusBar
        currentStep={creatingStage}
        thumbnailUploadPercentage={thumbnailuploadPercentage}
        contentUploadPercentage={contentUploadPercentage}
        reset={reset}
        processingWord={'Banner Image'}
      /> :
        <BannerImgForm
          bannerImg = {bannerImg}
          setBannerImg = {setBannerImg}
          productOptions={productOptions}
          setProductOptions={setProductOptions}
          categoryOptions={categoryOptions}
          setCategoryOptions={setCategoryOptions}
          categoryDropDown={categoryDropDown}
          setCategoryDropDown={setCategoryDropDown}
          empty = {empty}
          setEmpty={setEmpty}
          formData={formData}
          setFormData={setFormData}
          setAlert={ setAlert }
          handleSubmit={handleSubmit}
          handleEmpty={handleEmpty}
        />}
      {alert ? (
        <SweetAlert
          title="Upload Banner Image"
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Create"
          reverseButtons={true}
          onConfirm={handleSubmit}
          onCancel={()=>{ setAlert(false) }}
        />
      ) : null}
      {orderAlert ? (<SweetAlert
        title="Order Number Already Exist"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="OK"
        reverseButtons={true}
        onConfirm={()=>{
          handleSubmit
          setOrderAlert(false)
        }}
        onCancel={()=>{ setOrderAlert(false) }}
      />):(null)}
    </>


  )
}

export default CreateBannerImg

