
// import OSS from 'ali-oss'
// import { getSecureStsCred, regenerateSecureStsToken } from 'Views/Dashboard/Views/Uploads/Api'

import { uploadFileS3 } from "./Api";

// const getOssClient = ({
//   securityToken,
//   accessKeyId,
//   accessKeySecret,
//   ossBucketName,
//   ossBucketRegion,
// }) => {
//   const client = new OSS({
//     region: ossBucketRegion,
//     accessKeyId,
//     accessKeySecret,
//     stsToken: securityToken,
//     bucket: ossBucketName,
//     // Refresh the temporary access credential.
//     refreshSTSToken: async() => {
//       const { data } = await regenerateSecureStsToken()
//       return {
//         accessKeyId: data.data.accessKeyId,
//         accessKeySecret: data.data.accessKeySecret,
//         stsToken: data.data.securityToken,
//       }
//     },
//   })

//   return client
// }

// export const formatMultiSelectData = (data) => {
//   const formatedData = data.map(({ value }) => value)
//   return formatedData
// }

// AUDIO_IMAGE
export const uploadFile = (file, type, onProgress, percentageW) =>
  new Promise(async(resolve, reject) => {
    try {
      let payload = new FormData();
      payload.append('name', file.name)
      payload.append('type', type)
      payload.append('contentType', file.type)
      payload.append('file', file)
      // let payload = { name: file.name, type: type, contentType: file.type, image: await convertToBase64(file) }
      // let uploadFile = file;
      // console.log(payload);
      await uploadFileS3(payload).then(res => {
        resolve(res?.data.url);
      }).catch(err => {
        reject({
          error: true,
          errorMessage: 'Unable to upload data, try again later',
        });
      })
    } catch (error) {
      console.error('General error:', error);
      reject({
        error: true,
        errorMessage: 'Unable to upload data, try again later',
      });
    }
    // try {
    //   let uploadFile = file

    //   const { data } = await getSecureStsCred({
    //     name: uploadFile.name,
    //     type,
    //   })
    //   console.log(data)

    //   const client = getOssClient(data.data)
    //   console.log(client)
    //   // const { url } = await client.put(data.data.fileLocation, uploadFile); // example for simple upload

    //   const { name, bucket } = await client.multipartUpload(
    //     data.data.fileLocation,
    //     uploadFile,
    //     {
    //       progress: (p) => {
    //         onProgress(p * percentageW)
    //       },
    //       partSize: 1024 * 1024 * 1, // this will create a 1MB chunk of file
    //       parallel: 3,
    //     }
    //   )
    //   resolve(
    //     `https://${bucket}.${data.data.ossBucketRegion}.amazonaws.com/${name}`
    //   )
    // } catch (error) {
    //   reject({
    //     error: true,
    //     errorMessage: 'Unable to upload data try again later',
    //   })
    // }
  })

export const formatArray = (arr) => {
  let updatedArray = []
  for (let i in arr) {
    updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] })
  }
  return updatedArray
}
