import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import Select from 'react-select'
import SweetAlert from 'react-bootstrap-sweetalert'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { courseList } from '../../constants/utils'
import { editMetaData, getMetaData } from '../../api'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

const EditMetaData = () => {
  const navigate = useNavigate()
  const [idName, setIdName] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDesc, setMetaDesc] = useState('')
  // const [mataId, setMetaId] = useState('')
  const [metaKeyword, setMetaKeyword] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [relatedCourses, setRelatedCourses] = useState([])
  const [showEditAlert, setShowEditAlert] = useState(false)
  const [blogOptions, setBlogOptions] = useState([])
  const { id } = useParams()
  const [relatedPosts, setRelatedPosts] = useState([])

  const date = new Date()

  const metaParseAlgo = (data = '') => {
    let headers = {
      title: '',
      links: [],
      metaData: [],
      script: '',
    }
    data = data.replace(/\\n/g, '')
    data = data.split('\n')
    data.forEach((el) => {
      if (el.includes('<meta') || el.includes('<link')) {
        let obj = {}

        let regExp = /(\S+)="[^"]*/g
        let regexMatches = el.match(regExp)

        regexMatches.map((el) => {
          let partition = el.split('="')
          obj[partition[0]] = partition[1].replace(/"/g, '')
        })

        if (el.includes('<meta')) headers.metaData.push(obj)
        if (el.includes('<link')) headers.links.push(obj)
      } else if (el.includes('<title'))
        headers.title = el.replace('<title>', '').replace('</title>', '')
      else if (el.includes('<script')) headers.script = el
    })
    return {
      title: headers.title.trim(),
      description: headers.metaData.find((item) => {
        return item.name === 'description'
      }),
      keywords: headers.metaData.find((item) => {
        return item.name === 'keywords'
      }),
    }
  }
  const getOption = (url) => {
    let value = url.split('https://theyogainstitute.org/')[1]
    let label = value.charAt(0).toUpperCase() + value.slice(1)
    return { value: value, label: label }
  }
  const getBlogUrls = async() => {
    const { data } = await axios.get(
      `${cmsBaseDomain}v1/misc/urlsarray`
    )
    let blogOptions = data.data.map((item) => getOption(item))
    setBlogOptions([...blogOptions])
  }

  const getMeta = async() => {
    const { data } = await getMetaData(id)
    setIdName(data.data._id)
    setCreatedAt(data.data.createdAt)
    const parsedData = metaParseAlgo(data?.data?.meta)
    setMetaDesc(parsedData.description.content)
    setMetaTitle(parsedData.title.trim())
    setMetaKeyword(parsedData.keywords.content)
    setRelatedCourses(
      data.data.relatedCourses.map((item) => {
        return courseList.find((el) => el.value === item)
      })
    )
    setRelatedPosts(  data.data.relatedPosts.map((item) => {
      return {
        label:item.charAt(0).toUpperCase() + item.slice(1),
        value:item
      }
    }))
  }
  console.log(relatedPosts,'post')
  useEffect(() => {
    getMeta()
    getBlogUrls()
  }, [])


  const handleSubmit = async() => {
    try {
      await editMetaData(idName, {
        meta: `  <title>${metaTitle}</title>
       <meta name="description" content="${metaDesc}" />
       <meta name="keywords" content="${metaKeyword}"/>
       <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
       <link rel="canonical" href="https://theyogainstitute.org/${id}" />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="article" />
       <meta property="og:title" content="${metaTitle}" />
       <meta property="og:description" content="${metaDesc} " />
       <meta property="og:url" content="https://theyogainstitute.org/${id}" />
       <meta property="og:site_name" content="The Yoga Institute" />
       <meta property="article:publisher" content="https://www.facebook.com/theyogainstitutemumbai/" />
       <meta property="article:published_time" content=${createdAt} />
       <meta property="article:modified_time" content="${date.toISOString()}" />
       <meta property="og:image" content="https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Home/tyi.png" />
         <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="628" />
       <meta name="twitter:label1" content="Est. reading time">
         <meta name="twitter:data1" content="10 minutes">
       `,
        relatedCourses: relatedCourses.map(item=>item.value),
        objectType:'COURSE',
        relatedPosts:relatedPosts.map(item=>item.value)
      })
      navigate('/dashboard/meta-data/all')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Edit Meta Data</h3>
                  </div>
                </Col>
              </Row>
              <AvForm className="form-horizontal">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="pathName">Page</Label>

                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select page"
                        value={courseList.find((item) => item.value === id)}
                        options={courseList}
                        isDisabled={true}
                        validate={{ required: { value: true } }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="metaTitle">Title</Label>
                      <AvField
                        name="metaTitle"
                        placeholder="Enter Meta Title"
                        value={metaTitle}
                        onChange={(e) => {
                          setMetaTitle(e.target.value)
                        }}
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="metaTitle"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="metaDesc">Descritpion</Label>
                      <AvField
                        name="metaDesc"
                        placeholder="Enter Meta Descritpion"
                        value={metaDesc}
                        onChange={(e) => {
                          setMetaDesc(e.target.value)
                        }}
                        type="text"
                        errorMessage="Descritpion is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="metaDesc"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="metaKeyword">Keywords</Label>
                      <AvField
                        name="metaKeyword"
                        placeholder="Enter Meta Keywords"
                        value={metaKeyword}
                        onChange={(e) => {
                          setMetaKeyword(e.target.value)
                        }}
                        type="text"
                        errorMessage="Keywords is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="metaKeyword"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="relatedCourses">
                        {' '}
                        Alternative Courses(upto 3){' '}
                      </Label>
                      <Select
                        isMulti={true}
                        // name="Related Courses"
                        value={relatedCourses}
                        options={courseList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Related Courses"
                        onChange={(e) => {
                          setRelatedCourses(e)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="relatedPosts">
                        {' '}
                        Related Blogs (upto 3){' '}
                      </Label>
                      <Select
                        isMulti={true}
                        // name="Related Courses"
                        value={relatedPosts}
                        options={blogOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Related Blogs"
                        onChange={(e) => {
                          
                          setRelatedPosts(e)
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  disabled={false}
                  onClick={() => {
                    setShowEditAlert(true)
                  }}
                >
                  Edit Meta Data
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {showEditAlert ? (
        <SweetAlert
          title="Are You Sure?"
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Edit"
          reverseButtons={true}
          onConfirm={handleSubmit}
          onCancel={() => {
            setShowEditAlert(false)
          }}
        ></SweetAlert>
      ) : null}
    </>
  )
}

export default EditMetaData
