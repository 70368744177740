import { Route, Routes, Navigate } from 'react-router-dom'
import BannerRoutes from './Constants/routes'

const Banner = () =>{
  return(
    <Routes>
      {BannerRoutes.map(({ path, Component })=>(
        <Route 
          path={path} 
          element={<Component />}
          key={path}/>
      ))}
      <Route
        path='*'
        element={
          <Navigate
            to={BannerRoutes[0]?.routePath || '/dashboard'}
            replace
          />
        }
      />
    </Routes>
  )
}

export default Banner