import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button, FormGroup } from 'reactstrap'
import Table from 'Components/Table'
import { CSVLink } from 'react-csv'
import Select from 'react-select'

const AllOrdersCard = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  updateOrderHandler,
  totalSize,
  handleAllExportToCSV,
  selectUserData,
  handleSelectUsersListChange,
  selectedLimit,
  isLoading
}) => {

  const [rowData, setRowData] = useState([])

  const updateOrderFormatter = (cell, row) => {
    return <Button color='primary' onClick={() => { updateOrderHandler(row.orderId) }} >View/Update</Button>
  }

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        const fDate = new Date(el.createdAt)
        console.log(fDate)
        var o = { ...el, date: fDate.toLocaleDateString(), status: el.paymentCaptured ? 'Paid' : 'Not paid' }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      console.log(rowData, 'susfuufuyf')
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  useEffect(() => {
    tableHeading.push({
      text: 'View/Update',
      dataField: 'edit',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: updateOrderFormatter,
    })
    return () => {
      tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row style={{ display: 'flex' }}>
              <Col xs={8}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>All orders</h3>
                  <div
                    className='page-title-right d-flex mr-5'
                    style={{ gap: '30px' }}
                  >
                    <Col xs={8} className='users-col'>
                      <FormGroup>
                        <Select
                          isMulti={false}
                          // name="Related Courses"
                          value={selectedLimit}
                          options={selectUserData}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select No. of Users"
                          onChange={(e) => handleSelectUsersListChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={8} className='users-col'>
                      <FormGroup>
                        <CSVLink
                          data={handleAllExportToCSV()}

                          filename={'selected_users.csv'}
                          className="export-csv-btn"
                        // onClick={handleExportToCSVClick}
                        >
                          <button className='export-btn'>Export to CSV</button>
                        </CSVLink>
                        {/* <Label htmlFor="relatedCourses"> Select No. of Users</Label> */}

                      </FormGroup>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  {/* <h3 className='m-0'>Published Content</h3> */}
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                    {/* <Button
              color='primary'
              className='waves-effect waves-light'
              style={{ width: '100%' }}
              onClick={() => history.push('/dashboard/upload/bin')}
            >
              Content Bin
            </Button>
            <Button
              color='primary'
              className='waves-effect waves-light'
              style={{ width: '100%' }}
              onClick={() => history.push('/dashboard/upload/create')}
            >
              Add Content
            </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AllOrdersCard
