import Users from '../Views/Users'
import Course from '../Views/Courses'
import Uploads from '../Views/Uploads'
import Products from '../Views/Products'
import Coupons from '../Views/Coupons'
import Pages from '../Views/Pages'
import Tags from '../Views/Tags'
import Blogs from '../Views/Blogs'
import Yogasattva from '../Views/Yogsattva'
import TotalHealth from '../Views/TotalHealth'
import Careers from '../Views/Careers'
import TagsRoutes from '../Views/Tags/Constants/routes'
import { UploadRoutes } from '../Views/Uploads/Constants/routes'
import ProductsRoutes from '../Views/Products/Constants/routes'
import PagesRoutes from '../Views/Pages/Constatnts/routes'
import UsersRoutes from '../Views/Users/Constants/routes'
import { CouponsRoutes } from '../Views/Coupons/Constants/routes'
import { CoursesRoutes } from '../Views/Courses/Constants/routes'
import BlogRoutes from '../Views/Blogs/Constants/routes'
import Orders from '../Views/Orders'
import OrderRoutes from '../Views/Orders/Constants/routes'
import MetaData from '../Views/Meta'
import { MetaDataRoutes } from '../Views/Meta/constants/routes'
import Images from '../Views/Images'
import ImageRoutes from '../Views/Images/constants/routes'
// import CareersRoutes from '../Views/Careers/Constants/routes'
import Banner from '../Banner'
import BannerRoutes from '../Banner/Constants/routes'
import YogsattvaRoutes from '../Views/Yogsattva/Constants/routes'
import TotalHealthRoutes from '../Views/TotalHealth/Constants/routes'
import { EnrollFormRoutes } from '../Views/Enroll-Form/Constants/routes'
import Enrollment from '../Views/Enroll-Form'
import AdCourses from '../Views/AdCourses'
import AdCourseRoutes from '../Views/AdCourses/Constants/routes'

export const DashboardRoutes = [
  {
    Component: Users,
    path: '/users/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/users',
    name: 'Users',
    icon: 'ri-file-user-fill',
    subRoutes: UsersRoutes,
  },
  {
    Component: AdCourses,
    path: '/ad-course/*',
    exact: false,
    id: 'dashboardAdCourses',
    routePath: '/dashboard/ad-course',
    name: 'AdCampaign',
    icon: ' ri-pages-fill',
    subRoutes: AdCourseRoutes,
  },
  {
    Component: Course,
    path: '/courses/*',
    exact: false,
    id: 'dashboardCourse',
    routePath: '/dashboard/courses',
    name: 'Courses',
    icon: ' ri-bookmark-2-fill',
    subRoutes: CoursesRoutes,
  },
  {
    Component: Tags,
    path: '/tags/*',
    exact: false,
    id: 'dashboardTag',
    routePath: '/dashboard/tags',
    name: 'Tags & Categories',
    icon: 'ri-price-tag-3-fill',
    subRoutes: TagsRoutes,
  },
  {
    Component: Products,
    path: '/products/*',
    exact: false,
    id: 'dashboardCourse',
    routePath: '/dashboard/products',
    name: 'Products',
    icon: 'ri-user-settings-fill',
    subRoutes: ProductsRoutes,
  },
  {
    Component: Banner,
    path: '/banner/*',
    exact: false,
    id: 'dashboardBanner',
    routePath: '/dashboard/banner',
    name: 'Banner',
    icon: 'ri-user-settings-fill',
    subRoutes: BannerRoutes,

  },
  {
    Component: Coupons,
    path: '/coupon/*',
    exact: false,
    id: 'dashboardCoupon',
    routePath: '/dashboard/coupon',
    name: 'Coupons',
    icon: 'ri-user-settings-fill',
    subRoutes: CouponsRoutes,
  },
  {
    Component: Pages,
    path: '/pages/*',
    exact: false,
    id: 'dashboardPages',
    routePath: '/dashboard/pages',
    name: 'Pages',
    icon: 'ri-pages-fill',
    subRoutes: PagesRoutes,
  },
  {
    Component: MetaData,
    path: '/meta-data/*',
    exact: false,
    id: 'dashboardMeta',
    routePath: '/dashboard/meta-data',
    name: 'Meta Data',
    icon: 'ri-pages-fill',
    subRoutes: MetaDataRoutes,
  },

  {
    Component: Uploads,
    path: '/upload/*',
    exact: false,
    id: 'dashboardUpload',
    routePath: '/dashboard/Upload',
    name: 'Content',
    icon: ' ri-file-upload-fill',
    subRoutes: UploadRoutes,
  },
  {
    Component: Blogs,
    path: '/blogs/*',
    exact: false,
    id: 'dashboardBlogs',
    routePath: '/dashboard/Blogs',
    name: 'Blogs',
    icon: ' ri-pages-fill',
    subRoutes: BlogRoutes,
  },
  {
    Component: Orders,
    path: '/orders/*',
    exact: false,
    id: 'dashboardOrders',
    routePath: '/dashboard/orders',
    name: 'Orders',
    icon: ' ri-pages-fill',
    subRoutes: OrderRoutes,
  },
  {
    Component: Images,
    path: '/images/*',
    exact: false,
    id: 'dashboardImages',
    routePath: '/dashboard/images',
    name: 'Images',
    icon: ' ri-pages-fill',
    subRoutes: ImageRoutes,
  },
  {
    Component: Yogasattva,
    path: '/yogsattva/*',
    exact: false,
    id: 'dashboardYogsattva',
    routePath: '/dashboard/Yogsattva',
    name: 'Yogsattva',
    icon: ' ri-pages-fill',
    subRoutes: YogsattvaRoutes,
  },
  {
    Component: TotalHealth,
    path: '/totalHealth/*',
    exact: false,
    id: 'dashboardTotalHealth',
    routePath: '/dashboard/TotalHealth',
    name: 'Yoga Total Health',
    icon: ' ri-pages-fill',
    subRoutes: TotalHealthRoutes
  },
  {
    Component: Enrollment,
    path: '/enroll-Form/*',
    exact: false,
    id: 'dashboardEnrollForm',
    routePath: '/dashboard/enroll-form',
    name: 'Enrolled Users',
    icon: 'ri-pages-fill',
    subRoutes: EnrollFormRoutes
  }
  // {
  //   Component: Careers,
  //   path: '/Carriers/*',
  //   exact: false,
  //   id: 'dashboardCarriers',
  //   routePath: '/dashboard/carriers',
  //   name: 'Careers',
  //   icon: ' ri-pages-fill',
  //   subRoutes: CareersRoutes,
  // }
  ,
 
]
