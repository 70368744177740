import { useEffect, useState } from 'react'
import { OrdersTableHeading } from '../../Constants'
import { fetchAllOrders } from '../../Api'
import { useNavigate } from 'react-router-dom'
import AllOrdersCard from 'Components/AllOrdersCard'
import { Loader } from 'Components'

const selectUserData = [ 
  { label: 10, value: 10 }, 
  { label: 20, value: 20 }, 
  { label: 50, value: 50 }, 
  { label: 100, value: 100 }, 
  { label: 500, value: 500 },
  { label: 1000, value: 1000 },
  { label: 2000, value: 2000 },
  { label: 5000, value: 5000 },
  // { label: 10000, value: 10000 }, 
]

const AllOrders = () => {

  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  })
  const [selectUserOption, setSelectUserOption] = useState([])
  const [selectedLimit, setSelectedLimit] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [pageCount, setPageCount] = useState({})
  const [tableData, setTableData] = useState([])

  const { currentPageTags, totalSizeTags } = paginationValueTags


  const navigate = useNavigate()
  const getAllOrders = async(queryParams) => {
    try{

      setIsLoading(true)
      const { data } = await fetchAllOrders(queryParams)
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      })
      // setPageCount(data?.data?.count)
      setTableData(data?.data?.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const handleTablePageChangeTags = (type, { page, selectedCourse }) => {
    getAllOrders({ page, limit: selectedCourse ? selectedCourse : 20 })
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageCat: page,
    })
  }

  const handleSelectUsersListChange = async(selectedOption) => {
    setSelectedLimit(selectedOption)
    setPaginationValueTags({
      ...paginationValueTags,
      totalSizeTags: selectedLimit,
    })
    await getAllOrders({ page: 1, limit: selectedOption.value })
  }
  
  const updateOrderHandler = (rowID) => {
    console.log(rowID)
    navigate(`/dashboard/orders/update/${ rowID }`)
  }

  const handleAllExportToCSV = () => {

    const formatDate = (dateString) => {
      if (!dateString) return ''
      const date = new Date(dateString)
      if (isNaN(date)) return ''
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }

    const csvDataAll = tableData.map((item) => ({
      Name: item?.userId?.firstName,
      Email:item?.userId?.email,
      Status:item?.paymentCaptured ? 'Paid' : 'Not Paid',
      OrderId:item?.orderId,
      OrderDate: formatDate(item?.createdAt),
      // Phone:`=""${item.personalDetails.phone}""`,
      // Gender:item.personalDetails.gender,
      // Country:item.personalDetails.country,
      // City:item.personalDetails.city,
      // Nationality:item.personalDetails.nationality,
      // CourseMode:item.courseDetails.mode,
      // CourseName:item.courseDetails.courseName,

    })
    )

    return csvDataAll
  }

  console.log(tableData, 'tabledata')

  useEffect((selectedLimit) => {
    getAllOrders({ page: 1, limit: selectedLimit ? selectedLimit : 20 })
  }, [])



  return (<AllOrdersCard
  
    tableData = { tableData }
    tableHeading = { OrdersTableHeading }
    handleTablePageChange={handleTablePageChangeTags}
    page = { currentPageTags }
    sizePerPage = { 20 }
    totalSize={totalSizeTags}
    updateOrderHandler = { updateOrderHandler }
    handleAllExportToCSV={handleAllExportToCSV}
    handleSelectUsersListChange={handleSelectUsersListChange}
    selectUserData={selectUserData}
    selectedLimit={selectedLimit}
    isLoading={isLoading}

  />)
}
export default AllOrders

