import axios from 'axios'
import { tagApi, categoryApi } from '../../../Constants/apiRoutes'


const subDomain ='https://ecom-prod-be.theyogainstitute.org/v1'


export const createTagReq = (data) => {
  return axios.post(`${subDomain}${tagApi}`, data)
}

export const createCategoryReq = (data) => {
  return axios.post(`${subDomain}${categoryApi}`, data)
}

export const getTagByIdReq = (categoryID) => {
  return axios.get(`${subDomain}${categoryApi}/${categoryID}`)
}
