export const EnrollUsersTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'personalDetails.name',
  },
  {
    text: 'Email',
    dataField: 'personalDetails.emailId',
  },
  {
    text: 'Phone',
    dataField: 'personalDetails.phone',
  },
  // {
  //   text: 'Gender',
  //   dataField: 'personalDetails.gender',
  // },
  {
    text: 'Country',
    dataField: 'personalDetails.country',
  },
  {
    text: 'City',
    dataField: 'personalDetails.city',
  },
  // {
  //   text: 'Nationality',
  //   dataField: 'personalDetails.nationality',
  // },
  {
    text: 'Course Mode',
    dataField: 'courseDetails.mode',
  },
  {
    text: 'Course Name',
    dataField: 'courseDetails.courseName',
  },
  {
    text: 'Date/Time',
    dataField:'courseDetails.date',
  },
  {
    text: 'Payment',
    dataField: 'isPaid',
  },
  {
    text: 'Payment Date',
    dataField: 'createdAt',
  },

]









// export const PublishedCourseTableHeading = [
//   {
//     text: 'S.No.',
//     dataField: 'sNo',
    
//   },
//   {
//     text: 'Course Name',
//     dataField: 'title',
//   },
//   {
//     text: 'Is Free',
//     dataField: 'isFree',
//   },
//   {
//     text: 'Course Details',
//     dataField: 'description',
//   }
// ]