import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useState } from 'react'

const UserForm = ({
  pageHeading = 'Create User',
  handelInput,
  // name,
  // metaData,
  // description,
  firstName,
  lastName,
  username,
  phoneNumber,
  role,
  email,
  password,
  handleSubmit,
  isDisabledBtn = false,
  createBtnText = 'Create User',
  handleClose,
  isEditForm = false,
}) => {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              {!isEditForm && (
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              )}
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="firstName">Name</Label>
                    <AvField
                      name="firstName"
                      placeholder="Enter name"
                      value={firstName}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Name is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="name"
                    />
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="role">Role Type</Label>
                    <Input
                      name="role"
                      placeholder="Select Content Type"
                      type="select"
                      className="form-control"
                      value={role}
                      onChange={handelInput}
                      validate={{ required: { value: true } }}
                      id="role"
                    >
                      <option value="" disabled={true} selected>
                        Select Role
                      </option>
                      <option value="TEACHER">Teacher</option>
                      <option value="COORDINATOR">Coordinator</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              {/* <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handelInput}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="description"
                />
              </FormGroup> */}

              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="metaData">Meta Data</Label>
                    <AvField
                      name="metaData"
                      placeholder="Enter metaData"
                      // value={metaData}
                      // onChange={handelInput}
                      type="text"
                      errorMessage="metaData is required"
                      className="form-control"
                      // validate={{ required: { value: true } }}
                      id="metaData"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <AvField
                      name="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handelInput}
                      type="email"
                      errorMessage="Email is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="email"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <AvField
                      name="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={handelInput}
                      type="password"
                      errorMessage="password is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="password"
                    />
                  </FormGroup>
                </Col>
              </Row>

              {!isEditForm ? (
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  disabled={isDisabledBtn}
                >
                  {createBtnText}
                </Button>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gridGap: '1rem',
                  }}
                >
                  <Button
                    type="button"
                    onClick={handleClose}
                    color="light"
                    className="waves-effect"
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={isEditing || isNotEdited}
                  >
                    Edit User
                  </Button>
                </div>
              )}
              
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default UserForm
