import { useEffect, useState } from 'react'
import { updateShipping } from '../../Api'
import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap'

const ViewShipping = ({ modal, setModal, modalData }) => {
  const [weight, setWeight] = useState()
  const [price, setPrice] = useState('')
  const [error, setError] = useState(0)
  const [currency, setCurrency] = useState('INR')

  const handleShipping = async() => {
    if (price === '') {
      setError(1)
      return
    }
    await updateShipping(modalData._id, weight, price,currency)
    window.location.reload()
  }

  useEffect(() => {
    setWeight(modalData.weight)
    setPrice(modalData.price)
    setCurrency(modalData.currency)
  }, [modalData])

  console.log(modalData.weight, 'weight')
  console.log(price, 'price')

  const handleCloseModal = () => {
    setModal(false)
  }
  console.log(modalData, 'justgoaway')
  return (
    <Modal
      size="lg"
      className="updateOrder-modal modal-dialog modal-dialog-centered"
      isOpen={modal}
    >
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor="status">Weight Range (in kg)</Label>
              <Input
                name="weight"
                placeholder="Select your weight range"
                type="select"
                className="form-control"
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value)
                }}
                validate={{ required: { value: true } }}
                id="weight_range"
              >
                <option value="500">0.0 to 0.5 kg</option>
                <option value="1000">0.5 to 1.0 kg</option>
                <option value="1500">1.0 to 1.5 kg</option>
                <option value="2000">1.5 to 2.0 kg</option>
                <option value="2500">2.0 to 2.5 kg</option>
                <option value="3000">2.5 to 3.0 kg</option>
                <option value="3500">3.0 to 3.5 kg</option>
                <option value="4000">3.5 to 4.0 kg</option>
                <option value="4500">4.0 to 4.5 kg</option>
                <option value="5000">4.5 to 5.0 kg</option>
                <option value="5500">5.0 to 5.5 kg</option>
                <option value="6000">5.5 to 6.0 kg</option>
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label htmlFor="">Price</Label>
              <Input
                name="price"
                placeholder="Add your price"
                type="number"
                className="form-control"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value)
                }}
                validate={{ required: { value: true } }}
                errorMessage="Price is required"
                id="price"
              />

              {error === 1 && (
                <small style={{ fontSize: '12px', color: 'red' }}>
                  Please add price
                </small>
              )}
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label htmlFor="status">Currency</Label>
              <Input
                name="currency"
                placeholder="Select Currency"
                type="select"
                className="form-control"
                value={currency}
                onChange={(e) => {
                  setCurrency(e.target.value)
                }}
                validate={{ required: { value: true } }}
                id="currency"
              >
                <option value="INR">INR</option>
                <option value="USD">USD</option>

              </Input>
            </FormGroup>
          </Col>

          <Row>
            <Col className="d-flex justify-content-end gap-3">
              <Button
                className="px-5 mt-4"
                color="danger"
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <Button
                className="px-5 mt-4"
                color="primary"
                onClick={handleShipping}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ViewShipping
