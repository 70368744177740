import AllOrders from '../Views/AllOrders'
import UpdateOrder from '../Views/UpdateOrder'

const OrderRoutes = [
  {
    Component: AllOrders,
    path: '/all',
    exact: true,
    id: 'dashboardOrdersAll',
    routePath: '/dashboard/orders/all',
    name: 'All Orders',
  },
  {
    Component: UpdateOrder,
    path: '/update/:orderId',
    exact: true,
    id: 'dashboardOrdersUpdate',
  }
]

export default OrderRoutes