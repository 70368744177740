import DeletedCategory from 'Components/DeletedCategory'
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useNavigate } from 'react-router-dom'
import { getAllCategoryBinReq, restoreCategoryReq } from '../../Api'
import { tagsBinTableHeading } from '../../Constants'

const TagsBin = () => {
  const [category, setCategory] = useState([])
  const [showRestoreAlert, setShowRestoreAlert] = useState(false)
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false)
  const [restoreCategoryID, setRestoreCategoryID] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  })
  const { currentPage, totalSize } = paginationValue

  const navigate = useNavigate()

  const getCategoryBinData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getAllCategoryBinReq(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setCategory(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getCategoryBinData({ currentPage: 1, totalSize: 10 })
    // eslint-disable-next-line
  }, [])

  const handleViewCategoryClick = (CategoryID) => {
    navigate('/dashboard/Tags/view/' + CategoryID)
  }

  const handleRestoreProductClick = (CategoryID) => {
    setRestoreCategoryID(CategoryID)
    setShowRestoreAlert(true)
  }

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getCategoryBinData({ page, totalSize: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const restoreCategoryAction = async() => {
    await restoreCategoryReq(restoreCategoryID)
    const filteredProdutcs = category.filter(({ _id }) => _id !== restoreCategoryID)
    setCategory(filteredProdutcs)
    setRestoreCategoryID('')
    setShowRestoreAlert(false)
    setShowRestoreConfirm(true)
  }
  return (
    <>
      <DeletedCategory
        tableData={category}
        tableHeading={tagsBinTableHeading}
        handleViewCategory={handleViewCategoryClick}
        handleRestoreCategory={handleRestoreProductClick}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore Category!"
          reverseButtons={true}
          onConfirm={restoreCategoryAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Category Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false)
            setShowRestoreConfirm(false)
          }}
        >
          Category has been Restored.
        </SweetAlert>
      ) : null}
    </>
  )
}

export default TagsBin
