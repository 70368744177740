import { Route, Routes, Navigate } from 'react-router-dom'

import PagesRoutes from './Constatnts/routes'

const Pages = () => {
  return (
    <>
      <Routes>
        {PagesRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
        <Route
          path="*"
          element={
            <Navigate to={PagesRoutes[0]?.routePath || '/dashboard'} replace />
          }
        />
      </Routes>
    </>
  )
}

export default Pages
