import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from '../Table'

const CourseCard = ({
  heading = '',
  openModel,
  modelBtnText = '',
  tableData = [],
  tableHeading = [],
  handleEditCourse,
  handleViewCourse,
  handlePublishCourse,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  allTags,
}) => {
  const navigate = useNavigate()
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewCourse(row._id)}
        />
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditCourse(row._id)}
        />
      </div>
    )
  }

  const viewProductFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handlePublishCourse(row._id)}
        >
          UnPublish
        </Button>
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewContentFormatter,
    })
    tableHeading.push({
      text: 'Un-Publish Product',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewProductFormatter,
    })
    return () => {
      tableHeading.pop()
      tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{heading}</h3>
                  {allTags === true && (
                    <div
                      className='page-title-right'
                      style={{ display: 'flex', width: '30%', gap: '10px' }}
                    >
                      <Button
                        color='danger'
                        className='waves-effect waves-light'
                        style={{ width: '100%' }}
                        onClick={() => navigate('/dashboard/Tags/bin')}
                      >
                        Courses Bin
                      </Button>
                      <Button
                        color='primary'
                        className='waves-effect waves-light'
                        style={{ width: '100%' }}
                        onClick={openModel}
                      >
                        {modelBtnText}
                      </Button>
                    </div>
                  )}
                  {allTags === false && (
                    <div className='page-title-right'>
                      <Button
                        color='primary'
                        className='waves-effect waves-light'
                        style={{ width: '100%' }}
                        onClick={() => navigate('/dashboard/courses/all')}
                      >
                        All Courses
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  ) 
} 

export default CourseCard 
