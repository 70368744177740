import EditBannerForm from 'Components/BannerEditForm'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { editBannerImgByID, getBannerImgByID } from '../../Constants/api'
import { creatingStates } from 'Views/Dashboard/Views/Uploads/Constants'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/upload.Helper'
import UploadBannerStatusBar from 'Components/UploadBannerStatusBar'
import SweetAlert from 'react-bootstrap-sweetalert'

const EditBannerImg = () => {
  
  const { bannerID } = useParams()
  const [ bannerImg, setBannerImg] = useState(null)
  const [ productOptions, setProductOptions] = useState([])
  const [ categoryOptions, setCategoryOptions] = useState([])
  const [ categoryDropDown , setCategoryDropDown] = useState(null)
  const [ empty ,setEmpty] =  useState(0)
  const [ alert, setAlert] = useState(false)
  const [ orderAlert, setOrderAlert] = useState(false)
  const [ imageLink, setImageLink] = useState('')
  const [productName, setProductName] = useState([])
  const [categoryName, setCategoryName] = useState([])
  const [ formData, setFormData] = useState({
    name : 'banner',
    orderNumber:'',
    type:'',
    productId:'',
    categoryId:'',
    _id:''
  })
  
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: 1,
  })
  const { isCreating, creatingStage } = creating
  const navigate = useNavigate()
  const { name,orderNumber,type,productId,categoryId,_id } = formData
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)


  const getBanner = async()=>{
    try{ 
      const { data } =  await getBannerImgByID(bannerID)
      setFormData(data.data)
      if(data.data.orderNumber) setFormData({ ...data.data ,orderNumber:data.data.orderNumber })
      if(data.data.type) setFormData({ ...data.data,type:data.data.type })
      if(data.data.imageLink) setImageLink(data.data.imageLink )
      if(data.data.productId) setProductName(data.data.productId)
      if(data.data.categoryId) setCategoryName (data.data.categoryId)
       
      console.log(data.data,'edit')
    } catch (err) 
    {console.log(err)}
    console.log(orderNumber,'order')
  }

  const handleEditBanner = async() =>{
    try{
      let bannerUrl = null
      if (imageLink && typeof imageLink !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        })
        bannerUrl = await uploadFile(
          imageLink,
          'IMAGE',
          setThumbnailUploadPercentage,
          100
        )
      } else {bannerUrl=imageLink}
      setAlert(false)
      setThumbnailUploadPercentage(100)
      
      await editBannerImgByID(
        {
          ...formData,
          imageLink:bannerUrl
        },
        _id
      )
      setCreating({
        isCreating: true,
        creatingStage: 2,
      })
    } catch(err) {
      setOrderAlert(true)
    }
    
  }

  const handleCreateBanner = () =>{
    navigate('/dashboard/banner/all')
  }



  useEffect(()=>{
    console.log('edit banner')
    getBanner()
  },[])


  return (
    <>
      {isCreating ?( 
        <UploadBannerStatusBar
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={handleCreateBanner}
          processingWord={'Banner Image'}
        /> ) : (
        <EditBannerForm
          bannerImg = {bannerImg}
          setBannerImg = {setBannerImg}
          productOptions={productOptions}
          setProductOptions={setProductOptions}
          categoryOptions={categoryOptions}
          setCategoryOptions={setCategoryOptions}
          categoryDropDown={categoryDropDown}
          setCategoryDropDown={setCategoryDropDown}
          empty = {empty}
          setEmpty={setEmpty}
          formData={formData}
          setFormData={setFormData}
          setAlert={ setAlert }
          handleEditBanner={handleEditBanner}
          orderNumber={orderNumber}
          type={type}
          productId={productId}
          categoryId={categoryId}
          imageLink={imageLink}
          setImageLink={setImageLink}
          productName={productName}
          categoryName={categoryName}
        />
      )}
      {alert ? (
        <SweetAlert
          title="Edit Banner Image"
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Edit"
          reverseButtons={true}
          onConfirm={handleEditBanner}
          onCancel={()=>{setAlert(false)}}
        />
      ) : null}

      {
        orderAlert ? (
          <SweetAlert
            title="Order Number Already Exist"
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            reverseButtons={true}
            onConfirm={()=>{
              handleEditBanner,
              setOrderAlert(false)
            }}
            onCancel={()=>setOrderAlert(false)}
          />
        ) : (null)
      }
    </>
  )
}

export default EditBannerImg