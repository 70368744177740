import { authBaseDomain } from "Views/Dashboard/Constants/baseUrl"

const baseDomain = authBaseDomain

const stage = '/v2'

export const loginAPI = '/authdoor/login' // POST	

export const getMe = '/user/me' // GET Protected

export const refreshTokenAPI = '/authdoor/refresh'

// eslint-disable-next-line
export default `${baseDomain}${stage}`
