import TagsCard from 'Components/TagCard'
import { useEffect, useState } from 'react'
import { deleteTagReq, getAllCategoryReq } from '../../Api'
import { tagsTableHeading } from '../../Constants'
import CreateTag from 'Components/Modal/CreateTag'
import SweetAlert from 'react-bootstrap-sweetalert'

const AllTags = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [dataTags, setDataTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deleteID, setDeleteID] = useState('')
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  })
  const { currentPageTags, totalSizeTags } = paginationValueTags

  const getCategoryData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getAllCategoryReq(queryParams)
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      })
      setDataTags(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCategoryData({ page: 1, limit: 10 })
  }, [])

  const handleDeleteTagClick = (tagID) => {
    console.log(tagID)
    setDeleteID(tagID)
    setShowDeleteAlert(true)
  }

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen)

  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    getCategoryData({ page, limit: sizePerPage })
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageCat: page,
    })
  }

  const deleteTagAction = async() => {
    await deleteTagReq(deleteID)
    const filteredProducts = dataTags.filter(({ _id }) => _id !== deleteID)
    setDataTags(filteredProducts)
    setDeleteID('')
    setShowDeleteAlert(false)
    setShowDeleteConfirm(true)
  }

  // Handle Models
  const openCreateTagModel = () => {
    toggleCreateModel()
  }
  return (
    <>
      <TagsCard
        heading="Category & Tags"
        openModel={openCreateTagModel}
        modelBtnText="Add Category"
        tableData={dataTags}
        tableHeading={tagsTableHeading}
        // handleEditTag={handleEditTagClick}
        handleDeleteTag={handleDeleteTagClick}
        handleTablePageChange={handleTablePageChangeTags}
        page={currentPageTags}
        sizePerPage={10}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        allTags={true}
      />

      <CreateTag
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        // currentTag={currentTag}
        // populateTag={populateCreatedTag}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteTagAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false)
            setShowDeleteConfirm(false)
          }}
        >
          Category has been deleted
        </SweetAlert>
      ) : null}
    </>

    
  )
}

export default AllTags
