import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from '../../../../../../../Components/Table'

const OrderCard = ({
  tableData = [],
  tableHeading = [],
  handleViewBlog,
  handleEditBlog,
  handleUpdateOrder,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  currency
}) => {
  const [rowData, setRowData] = useState([])


  const viewProductFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <img src={ row.productThumbnail } />
      </div>
    )
  }

  const updateOrder = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handleUpdateOrder(row)}
        >
          Update/View
        </Button>
      </div>
    )
  }

  useEffect(() => {
    tableHeading.splice(1, 0, viewProductFormatter)
    tableHeading.push({
      text: 'Update/View Status',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: updateOrder,
    })
    return () => {
      tableHeading.splice(1, 1)
      tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = { ...el, 
          price: currency==='INR' ? `₹ ${ el.price }`:`$ ${ el.price }`, 
          image: <img width={'60px'} src={ el.productThumbnail } alt={ el.name } /> }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])
  console.log(rowData,'publish')
  return     <Row>
    <Col lg={12}>
      <Card>
        <CardBody>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                {/* <h3 className='m-0'>Published Content</h3> */}
                <div
                  className="page-title-right"
                  style={{ display: 'flex', width: '20%', gap: '10px' }}
                >
                  {/* <Button
                  color='primary'
                  className='waves-effect waves-light'
                  style={{ width: '100%' }}
                  onClick={() => history.push('/dashboard/upload/bin')}
                >
                  Content Bin
                </Button>
                <Button
                  color='primary'
                  className='waves-effect waves-light'
                  style={{ width: '100%' }}
                  onClick={() => history.push('/dashboard/upload/create')}
                >
                  Add Content
                </Button> */}
                </div>
              </div>
            </Col>
          </Row>
          <Table
            rows={rowData}
            columns={tableHeading}
            onTableChange={handleTablePageChange}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
}

export default OrderCard
