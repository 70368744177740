import ProductShipping from 'Components/ProductShipping'
import { useEffect, useState } from 'react'
import { shippingTableHeading } from '../../Constants'
import { getShipping } from '../../Api'
import ViewShipping from '../ViewShipping'
import CreateShipping from 'Components/CreateShipping'
import IntProductShipping from 'Components/IntProductShipping'
import { IntShippingTableHeading } from '../../Constants'
import { Row,Col,Button } from 'reactstrap'

const Shipping = () => {
  const [modal, setModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  // const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [intTableData, setIntTableData] = useState([])
  const [modalData, setModalData] = useState({})
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
  })

  const getShippingData = async() => {
    const { data } = await getShipping()
    setTableData(data?.data?.filter((item) => item.currency === 'INR'))
    setIntTableData(data?.data?.filter((item) => item.currency !== 'INR'))
  }

  const { page, limit } = pagination

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    setPagination({ page: page, limit: sizePerPage })
  }

  const handleUpdateShippingClick = (row) => {
    setModal(true)
    setModalData(row)
    console.log(row, 'comehere')
  }

  const handleCreateShippingClick = () => {
    setCreateModal(true)
  }

  useEffect(() => {
    getShippingData()
  }, [])

  return (
    <div>
      <Row>
        <Col><h2>Shipping Prices</h2></Col>
        <Col>
          <Button style={{ float:'right' }} color="primary" onClick={() => handleCreateShippingClick()}>
                  Create New Shipping
          </Button>
        </Col>
      </Row>
      <ProductShipping
        handleUpdateShipping={handleUpdateShippingClick}
        tableHeading={shippingTableHeading}
        tableData={tableData}
        totalSize={10}
        sizePerPage={10}
        page={page}
        limit={limit}
        handleTablePageChange={handleTablePageChange}
        handleCreateShipping={handleCreateShippingClick}
      />
      <IntProductShipping
        handleUpdateShipping={handleUpdateShippingClick}
        intTableHeading={IntShippingTableHeading}
        intTableData={intTableData}
        totalSize={10}
        sizePerPage={10}
        page={page}
        limit={limit}
        handleTablePageChange={handleTablePageChange}
        handleCreateShipping={handleCreateShippingClick}
      />
      <ViewShipping modalData={modalData} modal={modal} setModal={setModal} />
      <CreateShipping
        createModal={createModal}
        setCreateModal={setCreateModal}
      />
    </div>
  )
}

export default Shipping
