import { useEffect } from 'react'
import { Col, Row, Card, CardBody, Button } from 'reactstrap'

const BannerCard = ({
  bannerData,
  handleEditBanner,
}) => {

  return (
    <Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row><img src={ bannerData.imageLink } /></Row>
              <Row >
                <Col className='m-2 d-flex gap-5'>
                  <Row>Type : { bannerData.type }</Row>
                  <Row>Order no. : {bannerData.orderNumber}</Row>
                  {/* <Row>{bannerData._id}</Row> */}
                </Col>
                <Col style={{ display:'flex',justifyContent:'end' }} >
                  <i
                    className='ri-file-edit-fill text-info h4 cursor-pointer mr-2' 
                    onClick={()=>handleEditBanner(bannerData._id)}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Row>
  )
}

export default BannerCard
