import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Loader } from 'Components'
import EditProductComp from 'Components/EditProductComp'
import UploadStatusBar from 'Components/UploadStatusBar'

import { uploadFile } from '../../../Uploads/upload.Helper'
import { creatingStates } from '../../Constants'
import { editCouponReq, getCouponReq } from '../../Coupons.Apis'
import EditCouponComp from 'Components/EditCouponComp'

const EditCoupon = () => {
  const { couponID } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [formData, setFormData] = useState({
    couponType: '',
    couponCode: '',
    discountType: '',
    couponDiscount: '',
    numberOfUsers: '',
    expiryTime: '',
    _id: ''
  })

  const {
    couponType,
    couponCode,
    discountType,
    couponDiscount,
    numberOfUsers,
    expiryTime,
    _id
  } = formData

  const handelInput = (e) => {
    setIsDisabledBtn(false)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const getCoupon = async() => {
    try {
      setIsLoading(true)
      const { data } = await getCouponReq(couponID)
      setFormData(data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCoupon()
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await editCouponReq(
        {
          couponType,
          couponCode,
          discountType,
          couponDiscount,
          numberOfUsers,
          expiryTime,
        },
        couponID
      )
    } catch (error) {
      console.log(error)
    }
  }

  // const handleViewProduct = () => {
  //   navigate('/dashboard/Products/view/' + productID)
  // }

  // const handleCreateProduct = () => {
  //   navigate('/dashboard/coupon/create')
  // }

  return (
    <>
      {isLoading ? (
        <>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h3 className="m-0">Edit Product</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          <EditCouponComp
            pageHeading="Edit Coupon"
            couponType={couponType}
            couponCode={couponCode}
            discountType={discountType}
            couponDiscount={couponDiscount}
            numberOfUsers={numberOfUsers}
            expiryTime={expiryTime}
            _id={_id} 
            handelInput={handelInput}
            editBtnText={'Edit Product'}
            isDisabledBtn={isDisabledBtn}
            handleSubmit={handleSubmit}
          /> 
        </>
      )}
    </>
  )
}

export default EditCoupon
