export const OrdersTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Order id',
    dataField: 'orderId',
  },
  {
    text:'Order date',
    dataField:'date',
  },

  {
    text: 'Status',
    dataField: 'status',
  },

]

export const updateOrderTableHeading = [
  { text:'S.No.', dataField:'sNo' },
  { text:'Product Image',dataField:'image' },
  { text:'Product',dataField:'name' },
  { text:'Quantity', dataField:'quantity' },
  { text:'Price', dataField:'price' }
]