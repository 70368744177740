import YogsattvaRoutes from './Constants/routes'
import { Route, Routes, Navigate } from 'react-router-dom'

const Yogasattva = () => {
  return (
    <>
      <Routes>
        {YogsattvaRoutes.map(({ path, Component }) => (
          <Route
            path={path}
            element={<Component />}
            key={path}
          />
        ))}
        <Route path='*' element={<Navigate to={YogsattvaRoutes[0]?.routePath || '/dashboard/'} replace />} />
      </Routes>
    </>
  )
}

export default Yogasattva