import { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert
} from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import UserForm from 'Components/UserForm'
import { useNavigate } from 'react-router'
import { editUser } from '../../User.Api'

const EditUser = ({ isOpen, setIsOpen, initialValues, fetchData }) => {
  const navigate = useNavigate()
  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: 'success',
    alertText: 'Edited Sucessfully',
  })
  const { isAlert, alertType, alertText } = alert

  const [formData, setFormData] = useState({
    firstName: initialValues.firstName,
    // description: '',
    // metaData: '',
    role: initialValues.role,
    email: initialValues.email,
    password: '',
  })

  const {
    firstName,
    // description,
    // metaData,
    email,
    password,
    role,
  } = formData

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleSubmit = async() => {
    console.log('formData', formData)
    console.log(initialValues.userId,'usrID')
    const res = await editUser(initialValues.userId, formData)
    console.log('res', res)
    if(res.success) {
      setAlert({
        isAlert: true,
        alertType: 'success',
        alertText: 'Edited Sucessfully',
      })
      setTimeout(() => {
        toggleModal()
        fetchData()
      }, 1000)
    } else {
      setAlert({
        isAlert: true,
        alertType: 'danger',
        alertText: 'Unable to Edit',
      })
    }
  }

  const handleClose = () => {
    setAlert({
      isAlert: false,
      alertType: 'success',
      alertText: 'Edited Sucessfully',
    })
    toggleModal()
  }

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <ModalHeader>Edit User</ModalHeader>
      <ModalBody>
        <UserForm
          firstName={firstName}
          // description={description}
          // metaData={metaData}
          email={email}
          password={password}
          role={role}
          handelInput={handelInput}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          isEditForm={true}
        />
        {isAlert && (
          <Alert color={alertType} role="alert">
            {alertText}
          </Alert>
        )}
      </ModalBody>
    </Modal>
  )
  
}

export default EditUser