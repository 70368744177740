import { useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  Button,
} from 'reactstrap'

import { AvForm, AvField } from 'availity-reactstrap-validation'


const SectionForm = () => {
  const [formValues, setFormValues] = useState([{ name: '', email: '' }])

  let handleChange = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i][e.target.name] = e.target.value 
    setFormValues(newFormValues)
  }

  let addFormFields = () => {
    setFormValues([...formValues, { name: '', email: '' }]) 
  } 

  let removeFormFields = (i) => {
    let newFormValues = [...formValues]
    newFormValues.splice(i, 1)
    setFormValues(newFormValues)
  } 

  return (
    <>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <i
                    color="primary"
                    className="ri-add-line text-info h4 cursor-pointer mr-2"
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    onClick={() => addFormFields()}
                  />
                </Row>
                {formValues.map((element, index) => (
                  <>
                    <UncontrolledAccordion
                      className="mb-3"
                      defaultOpen={index + 1}
                    >
                      <AccordionItem>
                        <AccordionHeader targetId={index + 1}>
                          <Row className="w-100">
                            <Col xs={11}>Section {index + 1}</Col>

                            <Col xs={1} style={{ textAlign: 'end' }}>
                              {index ? (
                                <i
                                  color="danger"
                                  className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
                                  style={{ width: '100%' }}
                                  onClick={() => removeFormFields(index)}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </AccordionHeader>
                        <AccordionItem accordionId={index + 1}>
                          <Row
                            className="px-3 py-2"
                            key={index}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Col ls={4}>
                              <FormGroup>
                                <Label htmlFor="contentTypeSelect">
                                  Teacher List
                                </Label>
                                <Input
                                  name="UserRecommendationContentType"
                                  placeholder="Select Content Type"
                                  type="select"
                                  className="form-control"
                                  value={[]}
                                  onChange={(e) => handleChange(index, e)}
                                  validate={{ required: { value: true } }}
                                  id="contentTypeSelect"
                                >
                                  <option value="" disabled={true}>
                                    Select Teacher
                                  </option>
                                  <option value="pdf">Empty List</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col ls={4}>
                              <FormGroup>
                                <Label htmlFor="contentTypeSelect">
                                  Content List
                                </Label>
                                <Input
                                  name="UserRecommendationContentType"
                                  placeholder="Select Content Type"
                                  type="select"
                                  className="form-control"
                                  value={[]}
                                  onChange={(e) => handleChange(index, e)}
                                  validate={{ required: { value: true } }}
                                  id="contentTypeSelect"
                                >
                                  <option value="" disabled={true}>
                                    Select Content
                                  </option>
                                  <option value="pdf">Empty List</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row
                            className="px-3 py-2"
                            key={index}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Col ls={5}>
                              <FormGroup>
                                <Label htmlFor="title">Section Title</Label>
                                <AvField
                                  name="title"
                                  placeholder="Enter title"
                                  type="text"
                                  className="form-control"
                                  // validate={{ required: { value: true } }}
                                  id="title"
                                />
                              </FormGroup>
                            </Col>
                            <Col ls={5}>
                              <FormGroup>
                                <Label htmlFor="title">Section Description</Label>
                                <AvField
                                  name="description"
                                  placeholder="Enter description"
                                  type="text"
                                  className="form-control"
                                  // validate={{ required: { value: true } }}
                                  id="description"
                                />
                              </FormGroup>
                            </Col>
                            <Col ls={1} className='text-center'>
                              <Button color="success">Create</Button>
                            </Col>
                          </Row>
                        </AccordionItem>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SectionForm
