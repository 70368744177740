import EditBlog from '../Views/EditBlog'
import AllBlogs from '../Views/AllBlogs'
import CreateBlogs from '../Views/CreateBlog'
import BlogsBin from '../Views/BlogsBin'
import ViewBlog from '../Views/ViewBlog'

const BlogRoutes = [
  {
    Component: AllBlogs,
    path: '/all',
    exact: true,
    id: 'dashboardBlogsAll',
    routePath: '/dashboard/Blogs/all',
    name: 'All Blogs',
  },
  {
    Component: CreateBlogs,
    path: '/create',
    exact: true,
    id: 'dashboardBlogsCreate',
    routePath: '/dashboard/Blogs/create',
    name: 'Create Blogs',
  },
  {
    Component: BlogsBin,
    path: '/bin',
    exact: true,
    id: 'dashboardBlogsBin',
    routePath: '/dashboard/Blogs/bin',
    name: 'Blogs Bin',
  },
  {
    Component: EditBlog,
    path:'/edit/:blogId',
    exact:true,
    id: 'dashboardBlogsEdit',
  },
  {
    Component: ViewBlog,
    path:'/view/:blogId',
    exact:true,
    id: 'dashboardBlogsView',
  },
]

export default BlogRoutes
