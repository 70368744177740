import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  
} from 'reactstrap'
import Select from 'react-select'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'
import axios from 'axios'
import { useEffect } from 'react'
import { ecomBaseDomain } from 'Views/Dashboard/Constants/baseUrl'


const BannerImgForm = ({
  bannerImg,
  setBannerImg,
  productOptions,
  setProductOptions,
  categoryOptions,
  setCategoryOptions,
  categoryDropDown,
  setCategoryDropDown,
  orderNumber,
  empty,
  formData,
  setFormData,
  setAlert,
  handleEmpty
}) =>{

  const getProductData = async()=>{
    const { data } = await axios.get(`${ecomBaseDomain}v1/product/publishedproduct`)
    setProductOptions(data.data.map(item=>{
      return {
        label:item.name,
        value:item._id
      }
    }))
    
  }

  const getCategoryData = async() =>{
    const { data } = await axios.get('https://ecom-prod-be.theyogainstitute.org/v1/category')
    setCategoryOptions(data.data.map(item => {
      return {
        label:item.name,
        value:item._id
      }
    }))
    console.log(data.data,'category')
  }

  useEffect(()=>{
    getProductData(),
    getCategoryData()
  },[])


  const bannerType = [
    { 
      label : 'Product',
      value : 'PRODUCT' 
    },
    {
      label : 'Category',
      value : 'CATEGORY'
    }
  ]

  return(
    <Row>
      <Col lg= {12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Upload Banner Image</h3> 
                </div>
              </Col>
            </Row>
            <AvForm>
              <Row>
                <Col>
                  <FileUploader
                    file={bannerImg}
                    setFile={setBannerImg}
                    dragText="Drop Banner Image here"
                    heading="Select Banner Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    imgRatioRangeLow={0.15}
                    imgRatioRangeHigh={0.23}
                    errMsg='Please upload 6:1 ratio image'
                    isImage={true}
                  />
                </Col>
                {empty === 1 && (
                  <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between text-danger">
                      <small className="m-0">*Please upload image</small>
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between ">
                    <small className="m-0">*Please upload 6:1 ratio image</small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="orderNumber">Banner Image Order Number</Label>
                    <AvField
                      name="orderNumber"
                      placeholder="Enter banner image order num"
                      type="number"
                      className="form-control"
                      value={orderNumber}
                      onChange={(e)=>{
                        setFormData({ ...formData, orderNumber: e.target.value })
                      }}
                      validate={{ required: { value: true } }}
                      errorMessage="order num is required"
                      id="orderNumber"
                    />

             
                  </FormGroup>
                </Col>
                {empty === 2 && (
                  <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between text-danger ">
                      <small className="m-0">*Please enter order number</small>
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='relatedCourses'> Select Type of Banner Image</Label>
                    <Select
                      isMulti = {false}
                      options={bannerType}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder= 'Select type of banner image'
                      onChange={(e)=>{
                        setFormData({ ...formData,type: e.value })
                        setCategoryDropDown(e.value)
                      }}
                    />
                  </FormGroup>
                </Col>
                {empty === 3 && (
                  <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between text-danger ">
                      <small className="m-0">*Please select type</small>
                    </div>
                  </Col>
                )}
              </Row>
              {categoryDropDown === null ? null : categoryDropDown==='CATEGORY' ? 
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='relatedCourses'> Select Category</Label>
                      <Select
                        isMulti = {false}
                        options={categoryOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder= 'Select category'
                        onChange={(e)=>{
                          setFormData({ ...formData,categoryId:e.value })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row> : <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='relatedCourses'> Select Product </Label>
                      <Select
                        isMulti = {false}
                        options={productOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder= 'Select product'
                        onChange={(e)=>{
                          setFormData({ ...formData,productId:e.value })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row> }
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={false}
                onClick={()=>{
                  // setAlert(true)
                  handleEmpty()
                }}
              >
                  Upload Banner Image
              </Button>
           
            </AvForm>
          </CardBody>
        </Card>
      </Col>
     
    </Row>
    
  )
}

export default BannerImgForm