import { useEffect, useState } from 'react'
import { YogsattvaTableHeading } from '../../constants'
import { useNavigate } from 'react-router-dom'
import YogSattvaCard from 'Components/YogsattvaCard'
import { getAllYogsattvaAPI } from '../../Api'
import { Loader } from 'Components'
import { useParams } from 'react-router-dom'

const AllYogsattvas = () => {

  const [paginationValue, setPaginationValue] = useState({
    page: 1,
  })
  const { page, totalSize } = paginationValue

  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState([])

  const navigate = useNavigate()

  const { id } = useParams()

  const getAllYogsattvaData = async(queryParams) => {
    try {
      const { data } = await getAllYogsattvaAPI(queryParams)
      setTableData(data.data)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = (type, { page, sizePerPage }) => {
    getAllYogsattvaData({ page: page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }
  const handleEditYogsattva = async(id) => {
    navigate(`/dashboard/yogsattva/edit/${id}`)
  }

  useEffect(() => {
    getAllYogsattvaData({ page: 1, limit: 10 })
  }, [])

  return (isLoading ? <Loader /> : <YogSattvaCard

    tableData={tableData}
    tableHeading={YogsattvaTableHeading}
    getAllYogsattvaData={getAllYogsattvaData}
    handlePageChange={handlePageChange}
    page={page}
    sizePerPage={10}
    totalSize={totalSize}
    handleEditYogsattva={handleEditYogsattva}
    isLoading={isLoading}

  />)
}
export default AllYogsattvas

