
import ImageCard from 'Components/ImageCard'
import ImageUploads from '../Uploads'
import { useState , useEffect } from 'react'
import { getAllImages } from '../../api'
import { Col,Row,Card,CardBody } from 'reactstrap'

const AllImages = ({ showAllImagesModal }) => {
  const [uploadImage,setUploadImage] = useState([])

  const [ isLoading, setIsLoading ] = useState(false)
  const [imageDetails, setImageDetails ] = useState([])
  const handelAllImages = async() => {
    try {
      setIsLoading(true)
      const{ data } = await getAllImages()
      console.log(data.data.map(item=>item.image).flat(),'images')
      const imagesWithIds = data.data.map(item => ({
        image: item.image,
        _id: item._id 
      })).flat()
      setImageDetails(imagesWithIds) 
      setIsLoading(false)
    } catch(error){
      setIsLoading(false)
    
    }
  }

  useEffect( () =>{
    handelAllImages()
  },[] )
  if(imageDetails.length===0) {
    return(<h1>no data</h1> )
  }
  return (
    <>
      <Row > 
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">All images</h3>
                  </div>
                </Col>
              </Row>    
              <Row>              
                { imageDetails?.map((item,index) =>{  
                  return(<ImageCard item = {item.image} key = {index} imageId = {item._id}/>)
                }) }
              </Row>  

            </CardBody>
         
          </Card>
       
        </Col>
      </Row>
      
     
    </>
  )
}

export default AllImages
