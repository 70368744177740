import { Fragment } from 'react'
import SimpleBar from 'simplebar-react'

import SidebarContent from './Components/SidebarContent'

const Sidebar = () => {
  return (
    <Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {/* {this.props.type !== "condensed" ? ( */}
          <SimpleBar style={{ maxHeight: '100%' }}>
            <SidebarContent />
          </SimpleBar>
          {/* ) : <SidebarContent />} */}
        </div>
      </div>
    </Fragment>
  )
}

export default Sidebar
