export const PublishedCouponTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Coupon Type',
    dataField: 'couponType',
  },
  {
    text: 'Coupon Code',
    dataField: 'couponCode',
  },
  {
    text: 'No of Users',
    dataField: 'numberOfUsers',
  }
]

export const UnPublishedCouponTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Coupon Type',
    dataField: 'couponType',
  },
  {
    text: 'Coupon Code',
    dataField: 'couponCode',
  },
  {
    text: 'No of Users',
    dataField: 'numberOfUsers',
  }
]