import axios from 'axios'
import { categoryApi } from 'Constants/apiRoutes'


const subDomain = 'https://ecom-prod-be.theyogainstitute.org/v1'

export const getAllCategoryReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${categoryApi}?page=${page}&limit=${limit}`)
}

export const getAllCategoryBinReq = ({ page, limit }) => {
  return axios.get(`${subDomain}${categoryApi}/bin?page=${page}&limit=${limit}`)
}

export const restoreCategoryReq = (categoryId) => {
  return axios.put(`${subDomain}${categoryApi}/${categoryId}`)
}

export const deleteTagReq = (tagID) => {
  return axios.delete(`${subDomain}${categoryApi}/${tagID}`)
}