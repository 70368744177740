import { Fragment, useState } from 'react'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'


// @ts-ignore
import stockUser from '../../assets/images/stockUser.png'

export const ProfileMenu = ({
  userName,
  email,
  handleLogout,
  // handleAdmin
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)
  const handleLogoutClick = () => handleLogout()
  // const handleAdminClick = () => handleAdmin()
  return (
    <Fragment>
      <Dropdown
        isOpen={isMenuOpen}
        toggle={toggleMenu}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user mr-1"
            src={stockUser}
            alt="Header Avatar"
          />
          <span
            className="d-none d-xl-inline-block ml-1 text-transform"
            style={{ color: 'black' }}
          >
            {userName || email}
          </span>
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className="text-danger" onClick={handleLogoutClick}>
            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Logout
          </DropdownItem>
          {/* <DropdownItem className="text-primary" onClick={handleAdminClick}>
            Admin
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  )
}
