export const tagsTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Category Name',
    dataField: 'name',
  },
  {
    text: 'Description',
    dataField: 'description',
  },
]

export const tagsBinTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Category Name',
    dataField: 'name',
  },
  {
    text: 'Description',
    dataField: 'description',
  },
]

export const applicationTableHeading = [
  {
    text: 'S.No',
    dataField:'SNo'
  },
  {
    text: 'Name',
    dataField:'name'
  },
  {
    text: 'Email',
    dataField:'email'
  },
  {
    text: 'Date',
    dataField:'date'
  },
]