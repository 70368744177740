import { useState } from 'react'

import CourseForm from 'Components/CourseForm'
import { creatCourseReq } from '../../Courses.Api'


export const CreateCourse = () => {
  const [error] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error


  const [formData, setFormData] = useState({
    title: '',
    description: '',
    isFree: false,
    section:[]
  })

  const {
    title,
    description,
    isFree,
    section
  }          =         formData

  // Handle form IP
  const handelInput = (e) => {
    if (e.target.name === 'isFree')
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      })
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async(e) => {
    e.preventDefault()

    await creatCourseReq(
      {
        title,
        description,
        isFree,
        section,
      }
    )
  }
  return (
    <div>
      <CourseForm
        title={title}
        isFree={isFree}
        handelInput={handelInput}
        description={description}
        isError={isError}
        errorMessage={errorMessage}
        errorType={errorType}
        section={section}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default CreateCourse
