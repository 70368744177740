export const PublishedPageTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Name',
    dataField: 'name',
  },
  {
    text: 'Page Type',
    dataField: 'type',
  },
  {
    text: 'Description',
    dataField: 'description',
  }
]

export const UnPublishedPageTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Name',
    dataField: 'name',
  },
  {
    text: 'Page Type',
    dataField: 'type',
  },
  {
    text: 'Description',
    dataField: 'description',
  }
]
