import { useEffect, useState } from 'react'
import './style.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useNavigate, useParams } from 'react-router-dom'
import UpdateOrderStatus from './UpdateOrderStatus'
import { updateOrderTableHeading } from '../../Constants'
import { Row, Col, Card, CardBody, Table, FormGroup, Label, Input, Button } from 'reactstrap'
import { fetchProductById, getOerderbyId, updateOrderStatus } from '../../Api'
import OrderCard from './OrderCard'

const UpdateOrder = () => {
  const { orderId } = useParams()
  const navigate = useNavigate()

  const [orderData, setOrderData] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [date, setDate] = useState()
  const [paymentId, setPaymentId] = useState()
  const [isCreated, setIsCreated] = useState()
  const [address, setAddress] = useState({})
  const [contact, setContact] = useState({})
  const [modal, setModal] = useState(false)
  const [ shippingAmount,setShippingAmount ] = useState()
  const [ productData,setProductData ] = useState({})
  const [ currency,setCurrency ] = useState('INR')
  // const [checkstatus, setCheckStatus] = useState({
  //   tarckingId: '',
  //   status:'',
  //   isPriority: false,
  //   merchant: '',
  // })



  const getOrderItems = async(products) => {
    const arr = []
    for await (let item of products) {
      try {
        const { data } = await fetchProductById(item.productId)
        arr.push({ ...data.data, 
          quantity: item.quantity, 
          deliveryPartner: item.deliveryPartner, 
          deliveryStatus:item.deliveryStatus, 
          trackingId: item.trackingId  })
      } catch (err) {
        console.log(err)
      }
    }
    setOrderData(arr)
  }

  const getOrderData = async() => {
    const { data } = await getOerderbyId(orderId)
    setAddress(data.data.addressId)
    setContact({ mail:data.data.addressId.userId?.email,phone:data.data.addressId.userId?.phoneNumber })
    setPaymentId(data.data._id)
    const date = new Date(data.data.createdAt)
    setDate(date.toLocaleDateString())
    getOrderItems( data.data.items )
    setTotalAmount(data.data.cartId.totalPrice)
    setShippingAmount(data.data.cartId.shippingAmount)
    setCurrency(data.data.cartId.currency)
  }


  const handleUpdateOrder = (row)=>{
    setProductData(row)
    setModal(prev=>!prev)
  }


  useEffect(() => {
    getOrderData()
  }, [])


  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className='page-title-box d-flex align-items-center justify-content-between'>
                    <h3 className='m-0'>Update Order</h3>
                    <div
                      className='page-title-right'
                      style={{ display: 'flex', width: '20%', gap: '10px' }}
                    ></div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Row
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    <Col>Order Id: {orderId}</Col>
                    <Col>Created On: {date}</Col>
                  </Row>
                  <Row
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    <Col style={{ display:'flex' }} ><div>Delivery Address:</div> 
                      <div>{address?.name}
                        <br />{address?.houseNo}<br />
                        {address?.street},{address?.city},{address?.country} - {address?.pincode}<br />
                    Landmark:{address?.landmark}</div> </Col>
                    <Col>User Contact: mail-{contact?.mail},&ensp;ph-{contact?.phone}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '0',
                  marginTop: '30px',
                }}
              >
                <Col>
                  <OrderCard
                    tableData = { orderData }
                    tableHeading = { updateOrderTableHeading }
                    //  handleViewBlog,
                    //  handleEditBlog,
                    handleUpdateOrder={ handleUpdateOrder }
                    handleTablePageChange = { ()=>{ console.log('sss') } }
                    page = {1}
                    sizePerPage = { orderData?.length }
                    totalSize ={ orderData?.length }
                    currency={ currency }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <h6 style={{ textAlign: 'right' }} >
                      Shipping Amount: { currency==='INR' ?  `₹ ${shippingAmount}`:`$ ${shippingAmount}`}</h6>
                    <h5 style={{ textAlign: 'right' }}>
                      Total Amount: { currency==='INR' ?  `₹ ${totalAmount}`:`$ ${totalAmount}`}
                    </h5>
                  </Row>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='status'>Status</Label>
                    <Input
                      name="status"
                      placeholder="Update Status"
                      type="select"
                      className="form-control"
                      value={orderStatus}
                      onChange={(e) => { setOrderStatus(e.target.value) }}
                      validate={{ required: { value: true } }}
                      id="order_status"
                    >
                      <option value="ORDER_PLACED" >
                        Order Placed
                      </option>
                      <option value='SHIPPED' >Shipped</option>
                      <option value='OUT_FOR_DELIVERY' >Out for delivery</option>
                      <option value='DELIVERED' >Deliverd</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor=''>Tracking ID</Label>
                    <Input
                      name="tarckingId"
                      placeholder="Add order info"
                      type="text"
                      className="form-control"
                      value={trackingId}
                      onChange={
                        (e) => { setTrackingId(e.target.value) }}
                      validate={{ required: { value: true } }}
                      id="tarckingId"
                      maxLength='100'
                    />
                    <small style={{ float: 'right' }} >Max length allowed is 100 characters</small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='merchant'>Delivery Partner</Label>
                    <Input
                      name="merchant"
                      placeholder="Add Delivery Merchant"
                      type="text"
                      className="form-control"
                      value={merchant}
                      onChange={(e) => { setMerchant(e.target.value) }}
                      validate={{ required: { value: true } }}
                      id="merchant"
                    />
                  </FormGroup>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <UpdateOrderStatus 
                    modal={modal}
                    productData={ productData }
                    updateOrderStatus={ updateOrderStatus }
                    orderId={ paymentId }
                    setModal={setModal}
                  />
                </Col>
              </Row>
              <Row>
                <Button color='primary' onClick={()=>{setIsCreated(true)}} >Update Order Status</Button>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isCreated === true && <SweetAlert
        success
        title="Updated!"
        confirmBtnText="Go to all orders"
        onConfirm={() => { navigate('/dashboard/orders/all') }}
      >
        Order Updated Successfully
      </SweetAlert>}
      {isCreated === false && <SweetAlert
        error
        title="Failed!"
        cancelBtnText="Retry!"
        onCancel={() => { navigate(`/dashboard/orders/update/${orderId}`) }}
      >
        Failed to Update!
      </SweetAlert>}
    </>
  )
}

export default UpdateOrder
