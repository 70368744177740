import axios from 'axios'
import { cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'
const cmsBaseDomainn = `${cmsBaseDomain}v1`

export const getAllYogsattvaAPI = () => {
  return axios.get(`${cmsBaseDomainn}/yogaSatva/getall/`)
}

export const createYogsttvaAPI = (payload) => {
  return axios.post(`${cmsBaseDomainn}/yogaSatva/`, payload)
}

export const getYogasattvaDataById = (id) => {
  return axios.get(`${cmsBaseDomainn}/yogaSatva/${id}`)
}

export const updateYogsattvaAPI = (id, payload) => {
  return axios.patch(`${cmsBaseDomainn}/yogaSatva/update/${id}`, payload)
}