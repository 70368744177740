import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import ViewContentComp from 'Components/ViewContentComp'

import { Loader } from 'Components'

import { getContentReq } from '../../Api'

const ViewContent = () => {
  const { contentID } = useParams()

  const naviagte = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [thumbnail, setThumbnail] = useState(null)
  const [content, setContent] = useState(null)
  const [formData, setFormData] = useState({
    description: '',
    title: '',
    type: '',
    _id: '',
  })

  const { description, title, _id, type } = formData

  const handleEditContent = (contentId) => {
    naviagte(`/dashboard/upload/edit/${contentId}`)
  }

  const getContent = async() => {
    try {
      setIsLoading(true)
      const { data } = await getContentReq(contentID)
      setFormData(data.data)
      if (data.data.contentThumbnail) setThumbnail(data.data.contentThumbnail)
      if (data.data.contentUrl) setContent(data.data.contentUrl)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getContent()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isLoading ? (
        <>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h3 className='m-0'>Content Info</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          <ViewContentComp
            pageHeading='Content Info'
            title={title}
            description={description}
            thumbnail={thumbnail}
            content={content}
            type={type}
            _id={_id}
            handleEditContent={handleEditContent}
          />
        </>
      )}
    </>
  )
}

export default ViewContent
