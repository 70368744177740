import axios from 'axios'

const cmsBaseDomain = 'https://cms-prod-be.theyogainstitute.org/v1'

export const getPublishedBlog = ( { page,limit } )=>{
  return axios.get(`${ cmsBaseDomain }/post/publishedpost?page=${page}&limit=${limit}`)
  
}

export const createBlogReq = (payload)=>{
  return axios.post(`${ cmsBaseDomain }/post`,payload)
}

export const getUnpublishedBlog =({ page,limit })=>{
  return axios.get(`${cmsBaseDomain}/post/unpublishedpost?page=${page}&limit=${limit}`)
}


export const blogBin = ( page, limit )=>{
  return axios.get(`${cmsBaseDomain}/post/bin/?page=${page}&limit=${limit}`)
}

export const publishBlog= (blogID )=>{
  return axios.put(`${ cmsBaseDomain }/post/publish/${blogID}`)
}

export const unpublishBlog= (blogID )=>{
  return axios.put(`${ cmsBaseDomain }/post/conceal/${blogID}`)
}

export const getBlogReq = ( blogId )=>{
  return axios.get(`${ cmsBaseDomain }/post/admin/${ blogId }`)
}

export const editBlogReq = ( payload,blogId )=>{
  return axios.put(`${ cmsBaseDomain }/post/update/${ blogId }`,payload)
}

export const deleteBlog = (blogId)=>{
  console.log(blogId,'jjjjj')
  return axios.delete(`${ cmsBaseDomain }/post/delete/${ blogId }`)
}

export const restoreBlogreq = (blogRestore)=>{
  return axios.put(`${cmsBaseDomain}/post/restore/${ blogRestore }`)
}

export const getAllTags = ()=>{
  return axios.get(`${cmsBaseDomain}/tag/alltags`) 
}

export const createTag  = (payload)=>{
  return axios.post(`${cmsBaseDomain}/tag` , payload)
}

export const searchBlogsPublished = ( best )=>{
  return axios.get(`${cmsBaseDomain}/post/searchPost/${best}`)
}
export const searchBlogsunPublished = (page,search)=>{
  return axios.get(`${cmsBaseDomain}/post/searchunPublishedPost/?title=${search}&page=${page}&limit=${10}`)
}
