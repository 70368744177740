import { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUploader from 'Components/FileUploader'
import { Loader } from 'Components/Loader'
import './style.scss'

const EditYogasattvaComp = ({
  pageHeading = 'Edit Yogsattva',
  title,
  yogsattvaImg,
  setYogsattvaImg,
  month,
  year,
  isLoading,
  isDataUpdated,
  yogsattvaPDF,
  setYogsattvaPDF,
  BtnText = 'Edit Yogsattva',
  handleInput,
  handleSubmit,
  editedData
  ,
}) => {

  const AllMonths = [
    { value: '01', label: 'Jan' },
    { value: '02', label: 'Feb' },
    { value: '03', label: 'Mar' },
    { value: '04', label: 'Apr' },
    { value: '05', label: 'May' },
    { value: '06', label: 'Jun' },
    { value: '07', label: 'Jul' },
    { value: '08', label: 'Aug' },
    { value: '09', label: 'Sep' },
    { value: '10', label: 'Oct' },
    { value: '11', label: 'Nov' },
    { value: '12', label: 'Dec' }
  ]

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal">
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="firstName">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handleInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={yogsattvaImg}
                    setFile={setYogsattvaImg}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="firstName">Month</Label>
                    <Input
                      name="month"
                      placeholder="Select Month"
                      value={month}
                      onChange={handleInput}
                      type="select"
                      errorMessage="Select Month"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="month"
                    >
                      <option value="">Select Month</option>
                      {AllMonths.map((month) => {
                        return (
                          <option key={month.label} value={month.label}>
                            {month.label}
                          </option>
                        )
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="firstName">Year</Label>
                    <AvField
                      name="year"
                      placeholder="Enter year"
                      value={year}
                      onChange={handleInput}
                      type="number"
                      defaultValue={year}
                      errorMessage="Year is required"
                      className="form-year"
                      validate={{ required: { value: true } }}
                      id="year"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {isLoading ? <Loader /> : <FileUploader
                    file={yogsattvaPDF}
                    setFile={setYogsattvaPDF}
                    dragText="Drop PDF here"
                    heading="Select PDF"
                    accept=".pdf"
                  />}
                </Col>
              </Row>
            </AvForm>
            <Button
              color="light"
              className='disabled-btn'
              type="submit"
              disabled={!isDataUpdated ? true : false}
              onClick={handleSubmit}
            >
              {BtnText}
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default EditYogasattvaComp
