import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Loader } from 'Components'
import EditProductComp from 'Components/EditProductComp'
import UploadStatusBar from 'Components/UploadStatusBar'

import { uploadFile } from '../../../Uploads/upload.Helper'
import { creatingStates } from '../../Constants'
import { getProductReq, editProductReq } from '../../Api'

const EditProduct = () => {
  const { productID } = useParams()
  const navigate = useNavigate()
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const { isCreating, creatingStage } = creating

  const [thumbnailUploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)

  const [isLoading, setIsLoading] = useState(true)
  const [productThumbnail, setProductThumbnail] = useState(null)
  const [productImage1, setProductImage1] = useState('')
  const [productImage2, setProductImage2] = useState('')
  const [productImage3, setProductImage3] = useState('')
  const [bannerImage, setBannerImage] = useState(null)
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    skuId:'',
    description: '',
    price: '',
    priceInternational: '',
    weight:'',
    categoryId:'',
    _id: '',
  })

  const handelInput = (e) => {
    setIsDisabledBtn(false)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const { name, skuId, description, price, priceInternational,
    weight, _id, categoryId } = formData

  const getProduct = async() => {
    try {
      setIsLoading(true)
      const { data } = await getProductReq(productID)
      setFormData(data.data)
      if (data.data.productThumbnail) setProductThumbnail(data.data.productThumbnail)
      if (data.data.productImage[0]) setProductImage1(data.data.productImage[0])
      if (data.data.productImage[1]) setProductImage2(data.data.productImage[1])
      if (data.data.productImage[2]) setProductImage3(data.data.productImage[2])
      if (data.data.bannerImage) setBannerImage(data.data.bannerImage)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProduct()
    // eslint-disable-next-line
  }, [])

  const handleContentSelect1 = (cnt) => {
    setIsDisabledBtn(false)
    setProductImage1(cnt)
  }
  const handleContentSelect2 = (cnt) => {
    setIsDisabledBtn(false)
    setProductImage2(cnt)
  }
  const handleContentSelect3 = (cnt) => {
    setIsDisabledBtn(false)
    setProductImage3(cnt)
  }

  const handleThumbnailSelect = (img) => {
    setIsDisabledBtn(false)
    setProductThumbnail(img)
  }

  const handleBannerSelect = (img) => {
    setIsDisabledBtn(false)
    setBannerImage(img)
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      if (!productThumbnail || !(productImage1||productImage2||productImage3) || !bannerImage)
        return setError({
          isError: true,
          errorMessage: 'Please select thumbnail and Audio',
          errorType: 'warning',
        })
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
  
      const bannerUrl =  typeof(bannerImage) === 'string' ? bannerImage : await uploadFile(
        bannerImage,
        'BANNER',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
        
      })
      
      const thumbnailUrl = typeof(productThumbnail) ==='string' ? productThumbnail : await uploadFile(
        productThumbnail,
        'THUMBNAIL',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
        
      })

      const productUrl1 =  typeof(productImage1) === 'string' ? productImage1 : await uploadFile(
        productImage1,
        'IMAGE',
        setContentUploadPercentage,
        50
      )
      const productUrl2 =  typeof(productImage2) === 'string' ? productImage2 : await uploadFile(
        productImage2,
        'IMAGE',
        setContentUploadPercentage,
        50
      )
      const productUrl3 =  typeof(productImage3) === 'string' ? productImage3 : await uploadFile(
        productImage3,
        'IMAGE',
        setContentUploadPercentage,
        50
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
  
      
      await editProductReq(
        {
          name,
          skuId,
          description,
          price,
          priceInternational,
          weight,
          categoryId:categoryId._id,
          productImage: [ productUrl1,productUrl2,productUrl3 ],
          bannerImage: bannerUrl,
          productThumbnail: thumbnailUrl,
        }, productID
      )
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }

  }

  // const handleViewProduct = () => {
  //   navigate('/dashboard/Products/view/' + productID)
  // }

  const handleCreateProduct = () => {
    navigate('/dashboard/Products/create')
  }

  return (
    <>
      {isLoading ? (
        <>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h3 className='m-0'>Edit Product</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          {isCreating ? (
            <UploadStatusBar
              currentStep={creatingStage}
              contentUploadPercentage={contentUploadPercentage}
              thumbnailUploadPercentage={thumbnailUploadPercentage}
              processingWord='Updating'
              processedWord='Updated'
              reset={handleCreateProduct}
            />
          ) : (
            <EditProductComp
              pageHeading='Edit Product'
              name={name}
              skuId={skuId}
              description={description}
              price={price}
              priceInternational={priceInternational}
              weight={ weight }
              productImage1={productImage1}
              setProductImage1={handleContentSelect1}
              productImage2={productImage2}
              setProductImage2={handleContentSelect2}
              productImage3={productImage3}
              setProductImage3={handleContentSelect3}
              productThumbnail={productThumbnail}
              bannerImage={bannerImage}
              setProductThumbnail={handleThumbnailSelect}
              setBannerImage={handleBannerSelect}
              handelInput={handelInput}
              showSelectAudio={false}
              editBtnText={'Edit Product'}
              isDisabledBtn={isDisabledBtn}
              _id={_id}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  )
}

export default EditProduct
