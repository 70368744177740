import AllAdCourses from '../Views/AllAdCourses'
import AllBookings from '../Views/AllBookings'
import AllRooms from '../Views/AllRooms'
import AvailableRooms from '../Views/AvailableRooms'
import InteractedUsers from '../Views/InteractedUsers'
// import UpdateYogsattva from '../Views/UpdateYogsattva'
// import EditUpdateYogsattva from '../Views/EditUpdateYogsattva'

const AdCourseRoutes = [
  {
    Component: AllAdCourses,
    path: '/all',
    exact: true,
    id: 'dashboardAdCoursesAll',
    routePath: '/dashboard/ad-course/all',
    name: 'Campaign Enrolled Users',
  },
  {
    Component: AllBookings,
    path: '/bookings',
    exact: true,
    id: 'dashboardRoomAll',
    routePath: '/dashboard/ad-course/bookings',
    name: 'Bookings',
  },
  {
    Component: AvailableRooms,
    path: '/availability',
    exact: true,
    id: 'dashboardRoomAll',
    routePath: '/dashboard/ad-course/availability',
    name: 'Availability',
  },

  {
    Component: InteractedUsers,
    path: '/interacted-users',
    exact: true,
    id: 'dashboardRoomAll',
    routePath: '/dashboard/ad-course/interacted-users',
    name: 'Interacted Users',
  },
  {
    Component: AllRooms,
    path: '/rooms',
    exact: true,
    id: 'dashboardRoomAll',
    routePath: '/dashboard/ad-course/rooms',
    name: 'Rooms',
  },
  
]

export default AdCourseRoutes