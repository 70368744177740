import { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from '../Table'
import SearchBlogs from '../../assets/images/searchBlogs.png'
import { searchBlogsPublished } from 'Views/Dashboard/Views/Blogs/Api'

const BlogsCard = ({
  tableData = [],
  tableHeading = [],
  handleViewBlog,
  handleEditBlog,
  handlePublishBlog,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  setPublishedBlogs,
  
}) => {
  const searchQuery = useRef()

  const [rowData, setRowData] = useState([])
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewBlog(row._id)}
        />
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditBlog(row._id)}
        />
      </div>
    )
  }

  const viewBlogFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handlePublishBlog(row._id)}
        >
          UnPublish
        </Button>
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewContentFormatter,
    })
    tableHeading.push({
      text: 'Un-Publish Blog',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewBlogFormatter,
    })
    return () => {
      tableHeading.pop()
      tableHeading.pop()
    }
    
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i){
        console.log(el, 'value')
        var o = {
          ...el,
          name: el.title,
          description: el.excerpt.replace(/<\/?p>/g, ''),
        }
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  // const handleKeyPress=({ keyCode }, text)=>{
  //   if(keyCode==13){
  //     handleSearch( text )
  //   }
  // }
  // const handleSearch=async( text )=>{
  //   const { data :{ data =[] } } = await searchBlogsPublished(
  //     page,
  //     text
  //   )
  //   setPublishedBlogs(data)
  // }
  console.log(rowData, 'publish')
  

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Published Blogs</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                  {/* <div
                    className="searchBlogs"
                    style={{
                      display: 'flex',
                      border: '1px solid #454545',
                      borderRadius: '20px',
                      padding: '7px',
                    }}
                  >
                    <input
                      ref={searchQuery}
                      type="text"
                      placeholder="Search Blogs"
                      onKeyDown={handleKeyPress}
                      style={{ border: 'none', outlineColor: 'transparent' , borderRadius: '20px' }}
                    />
                    <button
                      style={{
                        border: 'none transparent',
                        outline:'none',
                        borderRadius: '25px',
                      }}
                      onClick={handleSearch}
                    >
                      <img
                        src={SearchBlogs}
                        alt="text"
                        style={{ width: 20, height: 20 }}
                      />
                    </button>
                  </div> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {/* <h3 className='m-0'>Published Content</h3> */}
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                    {/* <Button
                  color='primary'
                  className='waves-effect waves-light'
                  style={{ width: '100%' }}
                  onClick={() => history.push('/dashboard/upload/bin')}
                >
                  Content Bin
                </Button>
                <Button
                  color='primary'
                  className='waves-effect waves-light'
                  style={{ width: '100%' }}
                  onClick={() => history.push('/dashboard/upload/create')}
                >
                  Add Content
                </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BlogsCard
