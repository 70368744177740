import axios from 'axios'

import baseDomain, { loginAPI, getMe  } from 'Constants/authServerRoutes'
import { authServerClientId } from 'Constants/appSettings'

export const loginUserAPI = (data) => {
  return axios.post(`${baseDomain}${loginAPI}`, data)
}

export const fetchUserDataAPI = () => {
  return axios.get(`${baseDomain}${getMe}`)
}

