import YogasattvaComp from 'Components/yogsattvaComp'
import { useState } from 'react'
import { createYogsttvaAPI } from '../../Api'
import SweetAlert from 'react-bootstrap-sweetalert'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/Helper'
import { useNavigate } from 'react-router-dom'
import { creatingStates } from '../../constants'

const UpdateYogsattva = () => {


  const year = new Date().getFullYear()

  const [formData, setFormData] = useState({
    title: '',
    yogsattvaImg: '',
    month: '',
    year:'',
    yogsattvaPDF: ''
  })

  const { title, month } = formData

  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  const [yogsattvaImg, setYogsattvaImg] = useState(null)
  const [yogsattvaPDF, setYogsattvaPDF] = useState(null)
  const navigate = useNavigate()

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })

  const { isCreating, creatingStage } = creating
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)


  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const getCreateYogsattva = async() => {
    try {
      if (!yogsattvaImg)
        return setError({
          isError: true,
          errorMessage: 'Please select a cover image',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })

      const thumbnailUrl = await uploadFile(
        yogsattvaImg,
        'Yogsattva-COVER',
        setThumbnailUploadPercentage,
        30
      )

      const PdfUrl = await uploadFile(
        yogsattvaPDF,
        'Yogsattva-COVER',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,

      })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })


      await createYogsttvaAPI(
        {
          title: title,
          year: year,
          month: month,
          imageUrl: thumbnailUrl,
          pdfUrl: PdfUrl
        }
      )
      setShowUnPublishAlert(false)
      setShowUnPublishConfirm(true)
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }
  }

  const handleSubmit = () => {
    setShowUnPublishAlert(true)
    setShowUnPublishConfirm(false)
  }

  return (
    <div>
      <YogasattvaComp
        title={title}
        month={month}
        year={year}
        yogsattvaImg={yogsattvaImg}
        yogsattvaPDF={yogsattvaPDF}
        setYogsattvaImg={setYogsattvaImg}
        setYogsattvaPDF={setYogsattvaPDF}
        handleInput={handleInput}
        handleSubmit={handleSubmit}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={getCreateYogsattva}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            navigate('/dashboard/yogsattva/all')
          }}
        >
          Yogsattva has been Created
        </SweetAlert>
      ) : null}
    </div>

  )
}

export default UpdateYogsattva
