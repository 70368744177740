import { Route, Routes, Navigate } from 'react-router-dom'
import { MetaDataRoutes } from './constants/routes'
const MetaData = ()=>{
  return (
    <>
      <Routes>
        {MetaDataRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
        <Route
          path="*"
          element={
            <Navigate to={MetaDataRoutes[0]?.routePath || '/dashboard'} replace />
          }
        />
      </Routes>
    </>
  )
}

export default MetaData