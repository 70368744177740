import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import Table from '../Table'

const UnpublishedCourses = ({
  tableData = [],
  tableHeading = [],
  handleEditCourse,
  handleViewCourse,
  handleDeleteCourse,
  handleUnPublishCourse,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
}) => {
  const navigate = useNavigate()
  const [rowData, setRowData] = useState([])

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewCourse(row._id)}
        />
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditCourse(row._id)}
        />
        <i
          className="ri-delete-bin-2-fill text-danger h4 cursor-pointer"
          onClick={() => handleDeleteCourse(row._id)}
        />
      </div>
    )
  }

  const publishFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handleUnPublishCourse(row._id)}
        >
          Publish
        </Button>
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'actions',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: actionsFormatter,
    })
    tableHeading.push({
      text: 'Publish Product',
      dataField: 'publish',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: publishFormatter,
    })
    return () => {
      tableHeading.pop()
      tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">UnPublished course</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                    <Button
                      color="danger"
                      className="waves-effect waves-light"
                      style={{ width: '100%' }}
                      onClick={() => navigate('/dashboard/courses/bin')}
                    >
                      Courses Bin
                    </Button>
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: '100%' }}
                      onClick={() => navigate('/dashboard/courses/create')}
                    >
                      Add Courses
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default UnpublishedCourses
