import { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { PublishedBlogTableHeading, UnPublishedBlogTableHeading, searchedBlogTableHeading } from '../../Constants'
import { useNavigate } from 'react-router-dom'
import {
  getPublishedBlog,
  getUnpublishedBlog,
  publishBlog,
  deleteBlog,
  unpublishBlog,
} from '../../Api'
import { Button } from 'reactstrap'
import BlogsCard from 'Components/BlogCard'
import UnPublishBlogs from 'Components/UnpublishBlogs'
import { searchBlogsPublished, searchBlogsunPublished } from '../../Api'

const AllBlogs = () => {
  const [publishedBlogs, setPublishedBlogs] = useState([])
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const [currentSearch, setCurrentSearch] = useState([])
  const [unPublishedBlogs, setUnPublishedBlogs] = useState([])
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPublishAlert, setShowPublishAlert] = useState(false)
  const [showPublishConfirm, setShowPublishConfirm] = useState(false)
  const [publishID, setPublishID] = useState('')
  const [unPublishID, setUnPublishID] = useState('')
  const [deleteID, setDeleteID] = useState('')
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  const [ showPublishedSearch,setShowPublishedSearch ] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  })
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue

  const navigate = useNavigate()

  const handlePublishBlogData = async(queryParams) => {
    try {
      setIsLoading(true)

      const { data } = await getPublishedBlog(queryParams)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setPublishedBlogs(data.data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  
  const handleUnPublishBlogData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUnpublishedBlog(queryParams)
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      })
      setUnPublishedBlogs(data.data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlePublishBlogData({ page: 1, limit: 10 })
    handleUnPublishBlogData({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  const handleViewBlog = (BlogID) => {
    navigate('/dashboard/Blogs/view/' + BlogID)
  }

  const handleDeleteBlog = (BlogID) => {
    setDeleteID(BlogID)
    setShowDeleteAlert(true)
  }

  const handleEditBlog = (slug) => {
    navigate('/dashboard/Blogs/edit/' + slug)
  }

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    handlePublishBlogData({ page, limit: sizePerPage })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
      
    })
  }


  const handleSearch=async( text )=>{
    console.log(text)
    const { data } = await searchBlogsPublished(  
      text
    )
    setCurrentSearch(data.data)
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
    let searchBar = e.target.value
    handleSearch(searchBar)
  }

  const hideComponent = () => {
    // setTimeout(() => {
    //   setShow(false)
    //   setSearch('')
    // }, 1000)
  }
  const handleUnpublishedTablePageChange = (type,{ page, sizePerPage })=>{
    handleUnPublishBlogData({ page, limit: sizePerPage })
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    })
  }

  const handleUnPublishBlogClick = async(BlogID) => {
    setUnPublishID(BlogID)
    setShowUnPublishAlert(true)
  }

  const handlePublishBlogClick = async(BlogID) => {
    setPublishID(BlogID)
    setShowPublishAlert(true)
  }

  const publishBlogAction = async() => {
    await unpublishBlog(publishID)
    const filteredBlogs = publishedBlogs.filter(({ _id }) => _id !== publishID)
    setPublishedBlogs(filteredBlogs)
    setPublishID('')
    setShowPublishAlert(false)
    setShowPublishConfirm(true)
  }

  const unPublishBlogAction = async() => {
    await publishBlog(unPublishID)
    const filteredBlogs = unPublishedBlogs.filter(
      ({ _id }) => _id !== unPublishID
    )
    setUnPublishedBlogs(filteredBlogs)
    setUnPublishID('')
    setShowUnPublishAlert(false)
    setShowUnPublishConfirm(true)
  }

  const deleteBlogAction = async() => {
    await deleteBlog(deleteID)
    const filteredBlogs = unPublishedBlogs.filter(({ _id }) => _id !== deleteID)
    setUnPublishedBlogs(filteredBlogs)
    setDeleteID('')
    setShowDeleteAlert(false)
    setShowDeleteConfirm(true)
  }

  const selectBlog = {
    marginTop: '10px',
    position: 'absolute',
    top:'25px',
    right:'0px',
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px',
    width: '30%',
    height: '240px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    zIndex: '9',
  }

  const select_search_item = {//eslint-disable-line
    fontSize: '0.9rem',
    fontWeight: '500',
    lineHeight: '1.23',
    textAlign: 'left',
    color: '#505050',
    padding: '1rem 0',
    borderBottom: '1px solid #d3d3d3',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  }

  const selector1 = {
    padding: '7.5px',
    boxSizing: 'border-box',
    backgroundColor: '#1cbb8c',
    borderRadius: '50%',
    marginRight: '1rem',
  }

  const selector2 = {
    padding: '7.5px',
    boxSizing: 'border-box',
    backgroundColor: '#fcb92c',
    borderRadius: '50%',
    marginRight: '1rem',
  }

  const selector3 = {
    padding: '7.5px',
    boxSizing: 'border-box',
    backgroundColor: 'red',
    borderRadius: '50%',
    marginRight: '1rem',
  }

  useEffect(() => {
    search !== '' ? setShow(true) : setShow(false)
  }, [search])

  console.log(currentSearch,'ss')

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
          position:'relative'
        }}
      >
        <h2 style={{ margin: '0px' }}>All Blogs</h2>
        <input
          name='search'
          value={search}
          placeholder='Search blogs'
          style={{
            width: '300px',
            backgroundColor: 'transparent',
            outline: 'none',
            border: '1px solid aqua',
            borderRadius:'10px',
            padding: '5px',
          }}
          onChange={handleChange}
          onBlur={() => hideComponent()}
          autoComplete='off'
        />
        {show === true && (
          <>
            <div style={selectBlog}>
              {currentSearch && currentSearch.length === 0 ? (
                <div>Not found</div>
              ) : (
                currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item} //eslint-disable-line
                        key={i}
                        onClick={() => handleEditBlog(item._id)}
                      >
                        {item &&
                          item.isDisabled === false &&
                          item.isPublished === true && (
                          <div style={selector1}></div>
                        )}
                        {item &&
                          item.isDisabled === true &&
                          item.isPublished === false && (
                          <div style={selector2}></div>
                        )}
                        {item &&
                          item.isDisabled === true &&
                          item.isPublished === false && (
                          <div style={selector3}></div>
                        )}
                        <div>{item ? item.title : '-'}</div>
                      </div>
                    )
                  })
              )}
            </div>
          </>
        )}
      </div>
      
      {<BlogsCard
        tableData={publishedBlogs}
        tableHeading={PublishedBlogTableHeading}
        handleViewBlog={handleViewBlog}
        handleEditBlog={handleEditBlog}
        handlePublishBlog={handlePublishBlogClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        setPublishedBlogs={setPublishedBlogs}
        
      />}
     

      <UnPublishBlogs
        tableData={unPublishedBlogs}
        tableHeading={UnPublishedBlogTableHeading}
        handleViewBlogs={handleViewBlog}
        handleEditBlogs={handleEditBlog}
        handleDeleteBlogs={handleDeleteBlog}
        handleUnpublishBlogs={handleUnPublishBlogClick}
        handleTablePageChange={handleUnpublishedTablePageChange}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        unpublishedTotalSize={unpublishedTotalSize}
        isLoading={isLoading}
        setUnPublishedBlogs={setUnPublishedBlogs}
      />

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishBlogAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowPublishAlert(false)
            setShowPublishConfirm(false)
            window.location.reload()
          }}
        >
          Blog has been Unpublished
        </SweetAlert>
      ) : null}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={unPublishBlogAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            window.location.reload()
            // getCreatedMusicData({ page: 1, limit: 10 })
          }}
        >
          Blog has been published
        </SweetAlert>
      ) : null}

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteBlogAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false)
            setShowDeleteConfirm(false)
            window.location.reload()
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
    </>
  )
}

export default AllBlogs
