import axios from 'axios'
import { cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'
const cmsBaseDomainn = `${cmsBaseDomain}v1`

export const getTotalHealthDataById = (id) => {
  return axios.get(`${cmsBaseDomainn}/yogatotalhealth/${id}`)
}

export const getTotalHealthData = () => {
  return axios.get(`${cmsBaseDomainn}/yogatotalhealth`)
}

export const editTotalHealthAPI = (id, payload) => {
  return axios.patch(`${cmsBaseDomainn}/yogaTotalHealth/${id}`, payload)
}