import axios from 'axios'
import { userRoleApiUrl } from 'Constants/apiRoutes'
import { authBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

const devDomain = authBaseDomain


export const getAllUsersByRole = ({ page, limit, role }) => {

  // return axios.get(`https://lms-dev-be.theyogainstituteonline.org/v1${userRoleApiUrl}`, { params })
  return axios.get(
    `${devDomain}/v2/user?page=${page}&limit=${limit}&role=${role}`
  )
}
export const createAllUser = (data) => {
  return axios.post(
    `${devDomain}/v2/admin`,
    data
  )
}

export const deleteuserById = (id) => {
  return axios.patch(
    `${devDomain}/v2/admin/disable/${id}`
  )
}

export const enableuserById = (id) => {
  return axios.patch(
    `${devDomain}/v2/admin/enable/${id}`
  )
}

export const editUser = (id, data) => {
  return axios.put(
    `${devDomain}/v2/admin/${id}`,
    data
  )
}
