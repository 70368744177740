import { authActions } from './Auth.actions'

import { noError, initialState } from './Auth.defaultStates'


export const authReducer = (
  state=initialState,
  action
) => {
  switch (action.type) {
  // Login User
  case authActions.LOGIN_USER:
    return {
      ...state,
      isLoading: true,
      user: {},
      token: '',
      isLoggedIn: false,
      error: false,
      errorMsg : ''
    }

  case authActions.LOGIN_USER_FAILURE:
    return {
      ...state,
      isLoading: false,
      user: {},
      token: '',
      isLoggedIn: false,
      error: true,
      errorMsg : action.payload
    }
  case authActions.LOGIN_USER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      user: {},
      token: '',
      isLoggedIn: true,
      error: false,
      errorMsg : ''
    }
  case authActions.LOGOUT_USER:
    return {
      ...state,
      isLoading: false,
      user: {},
      token: '',
      isLoggedIn: false,
      error: false,
      errorMsg : ''
    } 
  default:
    return { ...state }
  }
}
