export const YogsattvaTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title',
  },
  {
    text: 'Month',
    dataField: 'month',
  },
  {
    text: 'Year',
    dataField: 'year',
  },
  // {
  //   text:'image URL',
  //   dataField:'imageUrl',
  // },
  // {
  //   text: 'Page URL',
  //   dataField: 'pdfUrl',
  // },
]

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
}