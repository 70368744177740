import AdCourseRoutes from './Constants/routes'
import { Route, Routes, Navigate } from 'react-router-dom'

const AdCourses = () => {
  return (
    <>
      <Routes>
        {AdCourseRoutes.map(({ path, Component }) => (
          <Route
            path={path}
            element={<Component />}
            key={path}
          />
        ))}
        <Route path='*' element={<Navigate to={AdCourseRoutes[0]?.routePath || '/dashboard/'} replace />} />
      </Routes>
    </>
  )
}

export default AdCourses