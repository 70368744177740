import CreateBlog from 'Components/BlogForm'
import UploadStatusBar from 'Components/UploadStatusBar'
import UploadBlogsStatus from 'Components/UploadBlogsStatus'
import { useEffect, useState } from 'react'
import { creatingStates } from 'Views/Dashboard/Views/Uploads/Constants'
import { uploadFile } from 'Views/Dashboard/Views/Uploads/upload.Helper'
import { createBlogReq } from '../../Api'
import { Navigate, useNavigate } from 'react-router-dom'

const CreateBlogs = () => {
  const [coverImage, setCoverImage] = useState(null)
  const [tags, setTags] = useState([])
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  })
  const [selectedCategory, setSelectedCategory] = useState({
    name: '',
    _id: '',
  })
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
    errorType: 'warning',
  })
  const { isError, errorMessage, errorType } = error
  const { isCreating, creatingStage } = creating
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0)
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0)
  const [content,setContent] = useState(null)
  const [meta,setMeta] = useState(null)
  const [tagsId,setTagsId] = useState([])
  const [ metaTitle,setMetaTitle] = useState(null)
  const [ keyword, setKeyword] = useState(null)
  const [formData, setFormData] = useState({
    title: '',
    metaDescription: '',
    categoryId:'',
    slug:'',
    altTag :'',
  })
  const { title, metaDescription,categoryId, slug, keywords, altTag } = formData
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })

  } 
  // Create Blog
  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      if (!coverImage)
        return setError({
          isError: true,
          errorMessage: 'Please select a cover image',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
    
      const thumbnailUrl = await uploadFile(
        coverImage,
        'BLOG-COVER',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
        
      })

      // const productUrl = await uploadFile(
      //   productImage,
      //   'IMAGE',
      //   setContentUploadPercentage,
      //   50
      // )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
  
      
      await createBlogReq(
        {
          title,
          excerpt:metaDescription,
          content,
          categoryId: selectedCategory._id,
          coverImage: thumbnailUrl,
          tags:tagsId,
          slug,
          meta: meta,
          altTag,
        }
      )
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }

  }

  const reset = () => {
    setCoverImage(null)
    //setProductImage(null)
    setError({
      isError: false,
      errorMessage: '',
      errorType: 'warning',
    })
    setFormData({
      title: '',
      description: '',
    })
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    })
  }


  return (
    <div>
      {isCreating ? (
        <UploadBlogsStatus
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={reset}
        />
      ) : (
        <CreateBlog
          handelInput={handelInput}
          title={title}
          metaDescription={metaDescription}
          // category={categoryid}
          content={ content }
          coverImage={coverImage}
          setCoverImage={setCoverImage}
          //productImage={productImage}
          // setProductImage={setProductImage}
          metaTitle={ metaTitle }
          setMetaTitle={ setMetaTitle }
          keywords={ keyword }
          setKeyword = {setKeyword}
          slug={slug}
          setMeta={ setMeta }
          tags={tags}
          tagsId={tagsId}
          setTagsId={setTagsId}
          setTags={ setTags }
          setContent={setContent}
          handleSubmit={handleSubmit}
          isError={isError}
          errorMessage={errorMessage}
          errorType={errorType}
          altTag={altTag}
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </div>
  )
}

export default CreateBlogs
